import { FormControl, Grid, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { GeoTaskItem } from './GeoTaskItem';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { AdditionalParam } from '@/dto/taskmap/Dto';
import { useGeoQuery } from '@/hooks/GeoQueryHook';
import { useMap } from '@/hooks/MapHook';
import { geoResponseToGeoTaskList } from '../utils';

export const AdditionalParamType: any = {
  TASK_NUMBER: '№ заявки',
  PU_NUMBER: '№ ПУ',
  MAC: 'MAC',
  IMEI: 'IMEI',
};

export const SearchMapManagerTab = (props: any) => {
  const {
    currentGeoResponseWithAdditionalParam,
    executeWithAdditionaParam,
    currentAdditionalParam,
    setCurrentAdditionalParam,
    setCurrentGeoResponseWithAdditionalParam,
  } = useGeoQuery();
  const { register, handleSubmit, setValue } = useForm<AdditionalParam>();
  const [additionalType, setAdditionalType] = useState('TASK_NUMBER');
  const { selectedGeoPointList, setSelectedGeoPointList } = useMap();

  useEffect(() => {
    if (currentAdditionalParam) {
      setValue('value', currentAdditionalParam.value);
      setAdditionalType(currentAdditionalParam.type);
    } else {
      setValue('value', '');
      setAdditionalType('TASK_NUMBER');
    }
  }, [currentAdditionalParam]);

  const onHandleSearch = async (data: AdditionalParam) => {
    clean();
    executeWithAdditionaParam(data);
  };

  const handleChangeType = (event: any) => {
    setValue('type', event.target.value);
    setAdditionalType(event.target.value);
  };

  const clean = () => {
    setCurrentAdditionalParam(null);
    setCurrentGeoResponseWithAdditionalParam(null);
    if (selectedGeoPointList) {
      setSelectedGeoPointList([]);
    }
  };

  return (
    <>
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
        spacing={2}
      >
        <form>
          <Grid
            container
            justifyContent='start'
            alignItems='flex-end'
            spacing={1}
          >
            <Grid
              item
              xs={4}
            >
              <FormControl
                variant='standard'
                sx={{ width: '100%' }}
              >
                <Select
                  id='additional-type-select'
                  {...register('type')}
                  defaultValue={'TASK_NUMBER'}
                  value={additionalType}
                  onChange={handleChangeType}
                  label='Age'
                >
                  {Object.keys(AdditionalParamType).map((t) => (
                    <MenuItem
                      key={t}
                      value={t}
                    >
                      {AdditionalParamType[t]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                key='additional-param'
                label=''
                variant='standard'
                {...register('value')}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={1}
            >
              <SearchIcon
                sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
                onClick={handleSubmit(onHandleSearch)}
              ></SearchIcon>
            </Grid>
            <Grid
              item
              xs={1}
            >
              <DisabledByDefaultIcon
                sx={{ color: '#FF8787', fontSize: 22, cursor: 'pointer' }}
                onClick={clean}
              ></DisabledByDefaultIcon>
            </Grid>
          </Grid>
        </form>
        {currentGeoResponseWithAdditionalParam &&
          geoResponseToGeoTaskList(currentGeoResponseWithAdditionalParam).map((t, i) => (
            <GeoTaskItem
              key={t.taskId}
              geoTask={t}
              index={i}
            />
          ))}
      </Stack>
    </>
  );
};
