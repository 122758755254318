import React, { FC, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, TextField } from '@/components/fixUI';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

interface DateFieldProps {
  label: string;
  disabled?: boolean;
  defaultValue?: Date;
  hookFormString?: string;
  inputFormat?: string;
}

const roundDate = (val: Date | undefined): Date => {
  return new Date(moment(val).valueOf() - (moment(val).valueOf() % 86400000));
};

const DateField: FC<DateFieldProps> = ({
  disabled = false,
  defaultValue,
  label,
  hookFormString,
  inputFormat = 'dd.MM.yyyy',
}) => {
  const { setValue } = useFormContext();
  const [dateValue, setDateValue] = useState<Date | undefined>(defaultValue);

  useEffect(() => {
    // hookFormString && setValue(hookFormString, moment(defaultValue).valueOf());
    hookFormString &&
      setValue(
        hookFormString,
        defaultValue ? moment(roundDate(defaultValue)).valueOf() : defaultValue
      );
  }, []);

  const onHandleChangeDateValue = (newValue: Date | null) => {
    if (newValue) {
      setDateValue(newValue);
      hookFormString && setValue(hookFormString, moment(roundDate(newValue)).valueOf());
    }
  };

  const datePickerValue = defaultValue
    ? moment(roundDate(defaultValue)).valueOf()
    : defaultValue ?? null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        disabled={disabled}
        label={label}
        inputFormat={inputFormat}
        value={dateValue ?? datePickerValue}
        onChange={onHandleChangeDateValue}
        renderInput={(params) => (
          <TextField
            sx={{ width: '100%' }}
            {...params}
            variant='standard'
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateField;
