import React from 'react';
import { TaskEditor } from '@/components/sections/TaskEditor/TaskEditor';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';

const TaskEditorPage = () => {
  useAccess({
    access: [AccessEnum.TasksBrowse, AccessEnum.DeliveryPointScreen],
  });
  return <TaskEditor />;
};
export default TaskEditorPage;
