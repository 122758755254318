import { forwardRef } from 'react';
import { useEffect } from 'react';
import { YMaps, Map as MMap } from 'react-yandex-maps';
import { useGeoQuery } from '@/hooks/GeoQueryHook';
import { useMap } from '@/hooks/MapHook';
import { usePnr } from '@/hooks/PnrHook';
import { CircleLayout } from '@/components/maps/pnrMap/component/layout/CircleLayout';
import { PlacemarkLayout } from '@/components/maps/pnrMap/component/layout/PlacemarkLayout';
import { ScopePlacemarkLayout } from '@/components/maps/pnrMap/component/layout/ScopePlacemartLayout';
import { PnrTrransformerStationPlacemark } from '@/components/maps/pnrMap/component/placemark/PnrTrransformerStationPlacemark';

const TaskMapInner = forwardRef(() => {
  const { isClusterMode, execute, currentTaskFilterParam } = useGeoQuery();
  const { pnrTransformerStationItems } = usePnr();
  const {
    selectedGeoPointList,
    setYMapsRef,
    setMapRef,
    mapRef,
    setGeoBounds,
    currentGeoBounds,
    setMapZoom,
  } = useMap();

  useEffect(() => {
    if (selectedGeoPointList.length == 0) {
      execute(currentGeoBounds, currentTaskFilterParam);
    }
  }, [currentGeoBounds]);

  const onMapBoundsChange = (e: any) => {
    if (e.get('oldBounds') != e.get('newBounds')) {
      setGeoBounds(mapRef?.getBounds());
    }
    setMapZoom(e.get('newZoom'));
  };

  const handleMapClick = () => {
    mapRef.balloon.close();
  };

  const onDblClick = (event: any) => {
    event.preventDefault();
  };

  return (
    <YMaps
      query={{
        ns: 'use-load-option',
        load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,geoObject.addon.hint',
        coordorder: 'longlat',
      }}
    >
      <MMap
        onLoad={(ymaps) => setYMapsRef(ymaps)}
        instanceRef={(ref) => ref && setMapRef(ref)}
        modules={['geoQuery']}
        defaultState={{
          center: [37.57, 55.75],
          zoom: 5,
          controls: ['zoomControl', 'fullscreenControl'],
        }}
        width={'100%'}
        height={'75vh'}
        options={{ autoFitToViewport: 'always', minZoom: 2 }}
        defaultOptions={{ minZoom: 2 }}
        onClick={handleMapClick}
        onBoundsChange={onMapBoundsChange}
        onDblClick={onDblClick}
      >
        <>
          {!isClusterMode ? (
            <>
              <CircleLayout />
              <PlacemarkLayout />
            </>
          ) : (
            <ScopePlacemarkLayout />
          )}
        </>
        {pnrTransformerStationItems?.length > 0 &&
          pnrTransformerStationItems.map((t) => (
            <PnrTrransformerStationPlacemark
              key={t.functionalLocationId}
              pnrTrransformerStation={t}
            ></PnrTrransformerStationPlacemark>
          ))}
      </MMap>
    </YMaps>
  );
});

TaskMapInner.displayName = 'TaskMapInner';
export default TaskMapInner;
