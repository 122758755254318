import React from 'react';
import { AsumbTaskStatisticsForCheckChartType } from '@/types/Types';
import EmbeddedStatisticsBar from '../Embedded/EmbeddedStatisticsBar';
import EmbeddedStatisticsPie from '../Embedded/EmbeddedStatisticsPie';
import { Paper, Stack } from '@mui/material';
import '../../../styles/checkStatisticsStyle.css';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { getDataFromFilter } from '../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../button/DetailedInfo/utils';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import { getLabels, getData, getColors } from './utils';

interface CheckStatisticsProps {
  data: AsumbTaskStatisticsForCheckChartType;
  header: string;
  load: boolean;
  updateFunction: (filter: TaskFilterParam) => void;
}

const FILTERS_LABELS = {
  contracts: 'Контракт',
};

const CheckStatistics: React.FC<CheckStatisticsProps> = ({
  data,
  header,
  load,
  updateFunction,
}) => {
  const max = 100000;
  let all = 0;
  for (let i = 0; i < data?.all?.datasets?.length; i++) {
    for (let j = 0; j < data?.all?.datasets[i]?.data?.length; j++) {
      all = all + Number(data?.all?.datasets[i]?.data[j]);
    }
  }
  const allLabels = data.all.labels?.map((label) => (
    <th key={Math.floor(Math.random() * max)}>
      <p className={'checkStatisticsBar'}>{String(label) + ' : '}</p>
    </th>
  ));
  const allData = data?.all?.datasets?.map((data) => (
    <th key={Math.floor(Math.random() * max)}>
      <p className={'checkStatisticsBar'}>{String(data.data[0])}</p>
    </th>
  ));

  const meterChangeLabels: string[] = getLabels(data?.meterChange);
  const meterChangeData: string[] = getData(data?.meterChange);
  const meterChangeColors: string[] = getColors(data?.meterChange);
  const tkoLabels: string[] = getLabels(data?.tko);
  const tkoData: string[] = getData(data?.tko);
  const tkoColors: string[] = getColors(data?.tko);

  const contractListResponse = useAllContractQuery();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = React.useState(0);

  const filtParam: TaskFilterParam = {};
  const onSubmitSearch = () => {
    if (selectedContractList.length) {
      filtParam.contractIds = getDataFromFilter(selectedContractList, 'value');
      filtParam.contractIdsArg = contractListArg;
    }
    updateFunction(filtParam);
  };

  const onResedClick = () => setSelectedContractList([]);

  const detailedInfo = generateDetailedInfo([selectedContractList, FILTERS_LABELS.contracts]);

  return (
    <Paper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>
      <Stack
        direction='row'
        alignItems='flex-end'
        position='relative'
      >
        <MultiSelect
          label={FILTERS_LABELS.contracts}
          options={contractListResponse}
          value={selectedContractList}
          argValue={contractListArg}
          onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
          onArgChange={(arg: number) => setContractListArg(arg)}
        />
        <FilterButtons
          info={detailedInfo}
          onSearchClick={onSubmitSearch}
          onCloseClick={onResedClick}
        />
      </Stack>
      {load ? (
        <LoadSpinner />
      ) : (
        <table>
          <tbody>
            <tr>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p className={'checkStatisticsBar'}>Всего : </p>
                      </td>
                      <td>
                        <p className={'checkStatisticsBar'}>{all}</p>
                      </td>
                    </tr>
                    {allLabels?.map((label, index) => (
                      <tr key={label.key}>
                        {label}
                        {allData[index]}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td colSpan={2}>
                <p className={'pieChartHeader'}>{data.tko.datasets[0]?.label}</p>
              </td>
              <td colSpan={2}>
                <p className={'pieChartHeader'}>{data.meterChange.datasets[0]?.label}</p>
              </td>
            </tr>
            <tr>
              <td>
                <div className={'barChartAll'}>
                  <EmbeddedStatisticsBar data={data.all} />
                </div>
              </td>

              <td>
                <div className={'TKOPie'}>
                  <EmbeddedStatisticsPie data={data.tko} />
                </div>
              </td>
              <td>
                <table>
                  <tbody>
                    {tkoData?.map((data, index) => (
                      <tr key={Math.floor(Math.random() * max)}>
                        <td>
                          <div
                            className={'chartLegendColorRectangle'}
                            style={{ backgroundColor: String(tkoColors[index]) }}
                          >
                            <p className={'taskStatisticsCountText'}>{data}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className={'chartLegendText'}>{tkoLabels[index]}</p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td>
                <div className={'MeterChangePie'}>
                  <EmbeddedStatisticsPie data={data.meterChange} />
                </div>
              </td>
              <td>
                <table>
                  <tbody>
                    {meterChangeData?.map((data, index) => (
                      <tr key={Math.floor(Math.random() * max)}>
                        <td className={'chartLegendItem'}>
                          <div
                            className={'chartLegendColorRectangle'}
                            style={{ backgroundColor: String(meterChangeColors[index]) }}
                          >
                            <p className={'taskStatisticsCountText'}>{data}</p>
                          </div>
                        </td>
                        <td>
                          <p className={'chartLegendText'}>{meterChangeLabels[index]}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </Paper>
  );
};
export default CheckStatistics;
