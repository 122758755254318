import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import React from 'react';
import { TaskMap } from '@/components/features/pnr/TaskMap/TaskMap';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const TaskMapPage = () => {
  useAccess({
    access: [AccessEnum.Map, AccessEnum.MapUspd],
  });
  useMetaTitle('Карта заявок');
  return <TaskMap />;
};

export default TaskMapPage;
