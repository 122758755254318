import { GeoPoint } from '@/components/maps/GeoPoint';
import { GeoBounds } from '@/dto/taskmap/Dto';
import * as geolib from 'geolib';
import { mapBoundsToGeoBounds } from '@/utils/heplers';
import { createStore } from '../utils/createStore';
import { toGeolibFormat } from '@/components/maps/zoningMap/utils/GeoUtils';

interface MapStore {
  ymapsRef: any | undefined;
  mapRef: any | undefined;
  clusterRef: any | undefined;
  currentGeoBounds: GeoBounds;
  boundsStack: [];
  geoCircleItems: Map<GeoPoint, GeoCircle>;
  selectedGeoPointList: GeoPoint[];
  mapZoom: number;
  setMapRef: (ref: any | undefined) => void;
  setYMapsRef: (ref: any | undefined) => void;
  setClusterRef: (ref: any | undefined) => void;
  getMapCenter: () => number[];
  setGeoBounds: (mapBounds: number[][]) => void;
  setMapZoom: (value: number) => void;
  mapToBounds: (bounds: number[][]) => void;
  mapToPrevBounds: () => void;
  setSelectedGeoPointList: (geoPointList: GeoPoint[]) => void;
  getMapObjectCount: () => number;

  geoCircleChanged: number;
  addGeoCircle: (target: GeoPoint, coords: number[], radius: number | 0) => void;
  removeGeoCircle: (target: GeoPoint) => void;
  removeAllGeoCircle: () => void;
  addCoveredList: (target: GeoPoint, coveredList: string[]) => void;
  getAllGeoCircle: () => GeoCircle[];
  getGeoCirctlByGeoTask: (target: GeoPoint) => GeoCircle | undefined;
  setGeoCircleRef: (target: GeoPoint, ref: any) => void;
  setGeoCircleRadius: (target: GeoPoint, radius: number) => void;
  searchCircleCovered: () => void;
}

export type GeoCircle = {
  target: GeoPoint;
  coveredList: any[];
  coords: number[];
  radius: number;
  ref: any;
};

export const useMap = createStore<MapStore>(
  (set: any, get: any) => ({
    ymapsRef: undefined,
    mapRef: undefined,
    clusterRef: undefined,
    currentGeoBounds: {} as GeoBounds,
    boundsStack: [],
    geoCircleItems: new Map<GeoPoint, GeoCircle>(),
    mapZoom: 0,
    geoCircleChanged: 0,
    selectedGeoPointList: [],

    setMapRef: (ref: any | undefined) => {
      const currentRef = get().mapRef;
      if (!currentRef && ref) {
        ref?.current?.removeComponent(ref.getComponentById('zoom.DoubleClick'));
        get().setGeoBounds(ref?.getBounds());
        set({ mapRef: ref });
      }
    },

    setYMapsRef: (ref: any | undefined) => {
      set({ ymapsRef: ref });
    },

    setClusterRef: (ref: any | undefined) => {
      const currentRef = get().clusterRef;
      if ((!currentRef && ref) || currentRef != ref) {
        set({ clusterRef: ref });
      }
    },

    getMapCenter(): number[] {
      return get().mapRef.getCenter();
    },

    setGeoBounds: (mapBounds: number[][]) => {
      if (mapBounds) {
        set({ currentGeoBounds: mapBoundsToGeoBounds(mapBounds) });
      }
    },

    setMapZoom: (value: number) => {
      set({ mapZoom: value });
    },

    setSelectedGeoPointList: (geoPointList: GeoPoint[]) => {
      if (geoPointList.length > 0) {
        const coordsList: number[][] = geoPointList.map((t) => t.getCoords());
        const points: any = toGeolibFormat(coordsList);
        const bounds = geolib.getBounds(points);
        const mapBounds: number[][] = [
          [bounds.minLat, bounds.minLng],
          [bounds.maxLat, bounds.maxLng],
        ];
        get().mapToBounds(mapBounds);
      } else {
        get().mapToPrevBounds();
      }
      set({ selectedGeoPointList: geoPointList });
    },

    getMapObjectCount: () => {
      const mapRef = get().mapRef;
      const clusterRef = get().clusterRef;
      if (clusterRef) {
        return clusterRef.getGeoObjects().length;
      } else if (mapRef) {
        return mapRef.geoObjects.getLength();
      }
      return 0;
    },

    addGeoCircle: (target: GeoPoint, coords: number[], radius: number | 0) => {
      const items = get().geoCircleItems;
      let item: GeoCircle = items.get(target);
      if (item) {
        item.radius = radius;
        item.coords = coords;
      } else {
        item = { target: target, ref: null, coveredList: [], coords: coords, radius: radius };
      }
      items.set(target, item);
      set({ geoCircleItems: items });
      set({ geoCircleChanged: get().geoCircleChanged + 1 });
    },

    removeGeoCircle: (target: GeoPoint) => {
      get().geoCircleItems.delete(target);
    },

    removeAllGeoCircle: () => {
      set({ geoCircleItems: new Map() });
    },

    addCoveredList: (target: GeoPoint, coveredList: string[]) => {
      const items = get().geoCircleItems;
      const item: GeoCircle = items.get(target);
      if (item) {
        item.coveredList = coveredList;
      }
    },

    getAllGeoCircle: () => {
      return Array.from(get().geoCircleItems.values());
    },

    getGeoCirctlByGeoTask: (target: any) => {
      return get().geoCircleItems.get(target);
    },

    setGeoCircleRef: (target: GeoPoint, ref: any) => {
      const items = get().geoCircleItems;
      const item: GeoCircle = items.get(target);
      if (item) {
        item.ref = ref;
      }
    },

    setGeoCircleRadius: (target: GeoPoint, radius: number) => {
      const items = get().geoCircleItems;
      const item: GeoCircle = items.get(target);
      if (item) {
        item.radius = radius;
        items.set(target, item);
        set({ geoCircleItems: items, geoCircleChanged: get().geoCircleChanged + 1 });
      }
    },

    searchCircleCovered: () => {
      const geoObjects = get().clusterRef?.getGeoObjects() || [];
      if (geoObjects.length > 0) {
        const geoQueryResult = get().ymapsRef?.geoQuery(geoObjects);
        const geoCircelItems = get().geoCircleItems;
        if (geoCircelItems?.values()) {
          const geoCircleList: GeoCircle[] = geoCircelItems?.values();
          geoCircleList
            .filter((t) => t.ref)
            .forEach((t) => {
              const result = geoQueryResult?.searchInside(t.ref);
              const taskIdList = result?._objects?.map(
                (t: any) => t?.properties?._data?.geoTask?.taskId
              );
              const items = get().geoCircleItems;
              const item: GeoCircle = items.get(t.target);
              if (item) {
                item.coveredList = taskIdList;
                items.set(t.target, item);
                set({ geoCircleItems: items });
              }
            });
        }
      }
    },

    mapToBounds: (bounds: number[][]) => {
      const mapRef = get().mapRef;
      if (mapRef && bounds) {
        const prevBounds = mapRef.getBounds();
        const boundsStack = get().boundsStack;
        boundsStack.push(prevBounds);
        mapRef.setBounds(bounds, { checkZoomRange: true, zoomMargin: 9 });
        set({ prevBounds: boundsStack });
      }
    },

    mapToPrevBounds: () => {
      const mapRef = get().mapRef;
      const boundsStack = get().boundsStack;
      const bounds = boundsStack.pop();
      if (mapRef && bounds) {
        mapRef.setBounds(bounds);

        set({ prevBounds: boundsStack });
      }
    },
  }),
  'Map'
);
