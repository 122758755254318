import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

export const MailNotificationsWrapper = styled('div')({
  padding: 32,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  position: 'relative',
});
export const VerticalLineWrapper = styled('div')({
  top: 32,
  bottom: 32,
  left: '50%',
  width: 1,
  position: 'absolute',
  background: color('mailLine'),
});

export const RowNumber = styled('div')({
  cursor: 'pointer',
  width: '10%',
});
export const ButtonWrapper = styled('div')({
  position: 'absolute',
  top: 32,
  left: 32,
  color: color('muiBlue'),
  cursor: 'pointer',
});
