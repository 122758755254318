import { Paper } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import CallCenterComponent from '@/components/callcenter/CallCenterComponent';
import { useAccess } from '@/hooks/useAccess';
import '../../styles/callcenterstyle.css';
import { AccessEnum } from '@/types/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const CallCenterPage = (props: any) => {
  useAccess({
    access: [AccessEnum.CallCenterIncoming, AccessEnum.CallCenterResolved],
  });
  const { taskitemid } = useParams();
  useMetaTitle(`Колл-центр`);

  if (taskitemid) {
    return (
      <Paper className='main-container'>
        <CallCenterComponent taskItemId={taskitemid} />
      </Paper>
    );
  } else {
    return (
      <Paper className='main-container'>
        <CallCenterComponent />
      </Paper>
    );
  }
};
export default CallCenterPage;
