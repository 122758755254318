import { Button, Typography } from '@mui/material';
import { MapAlert } from './index.styled';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { PillarGeo } from '../dto';

interface MapAlertComponentProps {
  isEditMode: boolean;
  isEditPillarMode: boolean;
  changeMode: () => void;
  changeModePillar: () => void;
  changeRouterMode: (flag: boolean) => void;
  changePillar: () => void;
  createOrEditRouter: () => void;
  pillar: PillarGeo | null;
  isEditRouterMode: boolean;
  newRouter: boolean;
}

export const MapAlertComponent: React.FC<MapAlertComponentProps> = ({
  isEditMode,
  isEditPillarMode,
  changeMode,
  changeModePillar,
  changePillar,
  pillar,
  changeRouterMode,
  createOrEditRouter,
  isEditRouterMode,
  newRouter,
}: MapAlertComponentProps) => {
  const showMapAllert = isEditMode || isEditPillarMode || isEditRouterMode;

  return (
    <>
      {showMapAllert && (
        <MapAlert>
          {isEditMode && (
            <BoxShadow
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Typography variant={'body1'}>Необходимо выбрать точку на карте</Typography>
              <Button onClick={changeMode}>Отменить</Button>
            </BoxShadow>
          )}
          {isEditPillarMode && (
            <>
              <BoxShadow
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography variant={'body1'}>Выберете необходимую опору</Typography>
                <Button onClick={changeModePillar}>Отменить</Button>
              </BoxShadow>
              {pillar && (
                <BoxShadow
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant={'body1'}>Подтвердите правильность выбора</Typography>
                  <Button onClick={changePillar}>ок</Button>
                  <Button onClick={changeModePillar}>Отменить</Button>
                </BoxShadow>
              )}
            </>
          )}
          {isEditRouterMode && (
            <>
              <BoxShadow
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography variant={'body1'}>
                  Выберите необходимую точку для определения новых координат
                </Typography>
                <Button onClick={() => changeRouterMode(false)}>Отменить</Button>
              </BoxShadow>
              {newRouter && (
                <BoxShadow
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant={'body1'}>Подтвердите правильность выбора</Typography>
                  <Button onClick={createOrEditRouter}>ок</Button>
                  <Button onClick={() => changeRouterMode(false)}>Отменить</Button>
                </BoxShadow>
              )}
            </>
          )}
        </MapAlert>
      )}
    </>
  );
};
