import React from 'react';

import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { MeterChangeRework } from '@/components/sections/MeterChangeRework/MeterChangeRework';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const McReworkPage = (props: any) => {
  useAccess({
    access: [AccessEnum.PUManaging],
  });
  useMetaTitle(`Замены ПУ на доработку`);
  return <MeterChangeRework />;
};
export default McReworkPage;
