import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';

export const useStatisticTeamManagerVoormanAndExecutorAcces = () => {
  const isTeamManagerVoormanAndExecutor = useAccess({
    access: [AccessEnum.TeamManagerVoormanAndExecutor],
    stopRedirect: true,
  });
  const isNotTeamManagerVoormanAndExecutor = useAccess({
    access: [AccessEnum.Statistic],
    stopRedirect: true,
  });
  return isTeamManagerVoormanAndExecutor && !isNotTeamManagerVoormanAndExecutor;
};
