import { AccessEnum } from '@/types/roles';
import { useAccess } from '@/hooks/useAccess';
import moment from 'moment';
import { useMemo } from 'react';

const getLengthRule = ({ minLength, maxLength }: { minLength: number; maxLength: number }) => {
  return {
    minLength: { value: minLength, message: getLengthText('min', minLength) },
    maxLength: { value: maxLength, message: getLengthText('max', maxLength) },
  };
};

const num_word = (value: number, words: [string, string]) => {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (num === 1) return words[0];
  return words[1];
};

const getLengthText = (minOrMax: 'min' | 'max', length: number) => {
  return `Длина должна быть ${minOrMax === 'min' ? 'не менее' : 'не более'} ${length} ${num_word(
    length,
    ['символа', 'символов']
  )}`;
};

export const useComplainFormEnergyValidation = () => {
  const isCallCenterSec = useAccess({
    access: [AccessEnum.notValidateComplaintSupAndEnergyType],
    stopRedirect: true,
  });

  return useMemo(() => {
    return {
      energyType: isCallCenterSec ? {} : { ...REQUIRED },
      classification: { ...REQUIRED },
      responseDate: { ...REQUIRED, validate: validateTime },
      sapNumber: isCallCenterSec
        ? {}
        : {
            ...REQUIRED,
            ...getLengthRule({
              minLength: FORM_VALIDATION_CONSTANTS.SAP_NUMBER_MIN_LENGTH,
              maxLength: FORM_VALIDATION_CONSTANTS.SAP_NUMBER_LENGTH,
            }),
          },
      declarerFio: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.DECLARER_FIO_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.DECLARER_FIO_LENGTH,
        }),
      },
      account: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.ACCOUNT_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.ACCOUNT_LENGTH,
        }),
      },
      phoneNumber: {
        ...REQUIRED,
        ...PHONE_PATTERN,
        validate: validatePhoneNumber,
      },
      phoneNumberAdditional: {
        ...PHONE_PATTERN,
      },
      meterType: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.METER_TYPE_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.METER_TYPE_LENGTH,
        }),
      },
      meterNumber: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.METER_NUMBER_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.METER_NUMBER_LENGTH,
        }),
      },
      tariffication: { ...REQUIRED },
      addressRegion: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.REGION_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.REGION_LENGTH,
        }),
      },
      addressArea: {
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.AREA_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.AREA_LENGTH,
        }),
      },
      addressLocality: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.LOCALITY_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.LOCALITY_LENGTH,
        }),
      },
      addressStreet: {
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.STREET_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.STREET_LENGTH,
        }),
      },
      addressHouse: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.HOUSE_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.HOUSE_LENGTH,
        }),
      },
      addressApartment: {
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.APARTMENT_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.APARTMENT_LENGTH,
        }),
      },
      res: { ...REQUIRED },

      text: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.TEXT_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.TEXT_LENGTH,
        }),
      },
      plannedDate: {
        validate: validateTime,
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.TEXT_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.TEXT_LENGTH,
        }),
      },
      letterDetails: {
        ...REQUIRED,
        ...getLengthRule({
          minLength: FORM_VALIDATION_CONSTANTS.TEXT_MIN_LENGTH,
          maxLength: FORM_VALIDATION_CONSTANTS.TEXT_LENGTH,
        }),
      },
    };
  }, []);
};
const validatePhoneNumber = (value: any) => {
  const cleanedValue = value.replace(/\s+/g, '');
  const isValidPattern = PHONE_PATTERN.pattern.value.test(cleanedValue);
  const hasSixConsecutiveIdentical = /(.)\1{5}/.test(cleanedValue);
  // Если значение не соответствует паттерну
  if (!isValidPattern) {
    return PHONE_PATTERN.pattern.message;
  }
  // Если есть 6 одинаковых подряд символов
  if (hasSixConsecutiveIdentical) {
    return 'Номер не должен содержать 6 одинаковых символов подряд';
  }
  return true; // Валидация прошла успешно
};
export const validateTime = (value: any) => {
  const inputTime = moment(value);
  const currentTime = moment();
  if (inputTime.isAfter(currentTime, 'day')) {
    return true; // Validation passed
  }
  return 'Дата должна быть в будушем';
};

const REQUIRED = { required: 'Обязательно для заполнения' };
const PHONE_PATTERN = {
  pattern: {
    value: /^(?:\+7|8|7)?[-\s]?\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/,
    message: 'Введите номер в формате +7ХХХХХХХХХХ',
  },
};

const formatAccountNumber = (value: string) => {
  // удаляем все символы, кроме цифр
  const cleanValue = value.replace(/\D/g, '');

  // разбиваем на части по шаблону 5-3-2
  const part1 = cleanValue.slice(0, 5);
  const part2 = cleanValue.slice(5, 8);
  const part3 = cleanValue.slice(8, 10);

  // собираем результат, добавляя тире между частями
  let formattedValue = part1;
  if (part2) formattedValue += `-${part2}`;
  if (part3) formattedValue += `-${part3}`;

  return formattedValue;
};

export const handleChangeAccount =
  (onChange: (...event: any[]) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatAccountNumber(event.target.value);
    onChange(formattedValue);
  };

const FORM_VALIDATION_CONSTANTS = {
  STAGE_LENGTH: 50,
  RESULT_TASK_STATUS_LENGTH: 50,
  RESULT_TASK_NUMBER_LENGTH: 50,
  REASON_TASK_NUMBER_LENGTH: 50,
  METER_NUMBER_MIN_LENGTH: 3,
  METER_NUMBER_LENGTH: 100,
  RESPONSE_LENGTH: 10000,
  RESPONSE_MIN_LENGTH: 5,
  TEXT_LENGTH: 10000,
  TEXT_MIN_LENGTH: 5,

  PHONE_NUMBER_MIN_LENGTH: 3,
  PHONE_NUMBER_LENGTH: 20,
  SAP_NUMBER_MIN_LENGTH: 1,
  SAP_NUMBER_LENGTH: 50,
  DECLARER_FIO_MIN_LENGTH: 3,
  DECLARER_FIO_LENGTH: 100,
  CLASSIFICATION_LENGTH: 50,
  WORK_TYPE_LENGTH: 50,
  ACCOUNT_MIN_LENGTH: 5,
  ACCOUNT_LENGTH: 12,
  METER_TYPE_LENGTH: 50,
  METER_TYPE_MIN_LENGTH: 1,
  TARRIFICATION_MAX: 3,

  STREET_MIN_LENGTH: 3,
  STREET_LENGTH: 50,
  LOCALITY_MIN_LENGTH: 3,
  LOCALITY_LENGTH: 50,
  REGION_MIN_LENGTH: 3,
  REGION_LENGTH: 50,
  AREA_MIN_LENGTH: 3,
  AREA_LENGTH: 50,
  APARTMENT_MIN_LENGTH: 1,
  APARTMENT_LENGTH: 5,
  HOUSE_MIN_LENGTH: 1,
  HOUSE_LENGTH: 10,
};
