import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const HousePnrBrowseWrapper = styled('div')({
  position: 'relative',

  '.parent': {
    padding: 0,
  },
});

export const HousePnrBrowseInputsWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  alignItems: 'flex-end',
  gap: 16,
});

export const HousePnrBrowseDataGrid = styled(DataGrid)({
  height: '650px',
  p: {
    margin: 0,
  },
});

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));
