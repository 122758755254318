import Skeleton from '@mui/material/Skeleton';
import { SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { BaseUI } from '@/types/Types';

interface LoadingProps extends BaseUI {
  loading: true | false;
  children?: ReactNode;
  sx?: SxProps;
}

export const Loading = (props: LoadingProps) => {
  const { loading, children, sx, className } = props;
  return (
    <>
      {loading ? (
        <Skeleton
          animation='wave'
          width='100%'
          height={50}
          className={className}
          sx={sx}
        />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
