import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';

import { useStatus } from '../store';
import { AddUnAttachmentDialog } from '@/components/features/taskEditor/dialog/AddUnAttachmentDialog';

import { ImageGroupViewProps } from './ImageGroupView';
import {
  ImageGroupViewControlsWrapper,
  ImageGroupViewControlsDownloadAllButton,
  ImageGroupViewControlsButtonWrapper,
} from './ImageGroupViewControls.styled';
import { GetFileUrlByFileId } from '@/services/YodaRestService';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useDisabledAll';

type ImageGroupViewControlsProps = ImageGroupViewProps;

const ImageGroupViewControls = ({ imageBox }: ImageGroupViewControlsProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const isDisabledAll = useStatus((state) => state.isDisabledAll);

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const openDialogHandler = () => setOpenAddDialog(true);
  const closeDialogHandler = () => setOpenAddDialog(false);

  const allFilesPromise = imageBox?.imageList.map((image, index) => ({
    url: image.url ?? GetFileUrlByFileId(image.id),
    folder: imageBox?.title,
    fileName: `${index + 1}${`_${imageBox?.title}`}`,
  }));

  return (
    <>
      <ImageGroupViewControlsWrapper>
        <span>{imageBox?.title}</span>
        <ImageGroupViewControlsButtonWrapper>
          <ImageGroupViewControlsDownloadAllButton
            disabled={!imageBox?.imageList?.length}
            buttonTitle='Скачать группу фото'
            zipName='Архив_фотографий'
            downloadLinksPromises={allFilesPromise}
          />

          <IconButton
            onClick={openDialogHandler}
            disabled={disabledAll || onlyShow || isDisabledAll()}
            title='Добавить фото'
            size='large'
          >
            <AddPhotoAlternateRoundedIcon color='primary' />
          </IconButton>
        </ImageGroupViewControlsButtonWrapper>
      </ImageGroupViewControlsWrapper>

      {imageBox?.obj && (
        <AddUnAttachmentDialog
          obj={imageBox.obj}
          openDialog={openAddDialog}
          onClose={closeDialogHandler}
        />
      )}
    </>
  );
};

export default ImageGroupViewControls;
