import { Grid } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import {
  MeasurementResponse,
  ReplacementMeterResponse,
  StampInstallResponse,
} from '@/dto/taskmap/task/TaskResultDto';
import { YungaAnalyzeMeterResponse } from '@/dto/taskmap/task/YungaDto';
import { YungaAnalyzeTextField } from '@/components/YungaAnalyzeTextField/YungaAnalyzeTextField';
import { ModeViewerResult } from '@/constants/modeViewer';
import { useStatus } from '../panel/store';
import { useCatalog } from '@/hooks/CatalogHook';
export const enum Types {
  OLD = 'old',
  NEW = 'new',
}

interface MeterItemProps {
  breaker?: string;
  balance?: number;
  factoryStamp?: string;
  stampInstall: StampInstallResponse;
  measurement?: MeasurementResponse;
  meter: ReplacementMeterResponse;
  analyze?: YungaAnalyzeMeterResponse;
  type: Types;
  mode: ModeViewerResult;
  keyString?: string;
  isPickUpOldMeter?: boolean;
  isSignedTransferredToMesCalculation?: boolean;
  onChangeMainFields?: (isChange: boolean) => void;
}

export const MeterItem = (props: MeterItemProps) => {
  const { register, setValue } = useFormContext();

  const {
    type,
    keyString,
    measurement,
    stampInstall,
    balance,
    breaker,
    factoryStamp,
    isSignedTransferredToMesCalculation,
    isPickUpOldMeter,
    onChangeMainFields,
  } = props;

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const [meter, setMeter] = useState<ReplacementMeterResponse>();
  const [analyze, setAnalyze] = useState<YungaAnalyzeMeterResponse>();

  const mainStr = type === Types.OLD ? `${keyString}.unmounted` : `${keyString}.mounted`;
  const secondStr =
    type === Types.OLD ? `${keyString}.existingMeasurement` : `${keyString}.installMeasurement`;

  Types.OLD && setValue(`${mainStr}.type`, props.meter.type);
  measurement && setValue(`${secondStr}.id`, measurement.id);

  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));

  useEffect(() => {
    setMeter(props.meter);
  }, [props.meter]);

  useEffect(() => {
    setAnalyze(props.analyze);
  }, [props.analyze]);

  useEffect(() => {
    measurement && setValue(`${secondStr}.t1`, measurement.t1);
    measurement && setValue(`${secondStr}.t2`, measurement.t2);
    measurement && setValue(`${secondStr}.t3`, measurement.t3);
  }, [measurement]);

  const checkTariff = (tariff?: string) => {
    if (tariff && tariff.match('^[123]$')) {
      return tariff;
    }
    return undefined;
  };

  const getMarkedType = (val?: string) => {
    if (val === 'MATCH') {
      return 'done';
    }
    if (val === 'NOT_MATCH') {
      return 'cancel';
    }
    if (val === 'UNKNOWN') {
      return 'unknown';
    }
  };

  return (
    <>
      {meter && (
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={6}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.pu)}
              registerName={`${mainStr}.number`}
              value={meter?.number}
              label='Номер'
              disabled={isDisabledAll()}
              isChange={onChangeMainFields}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteValue
              title='Тариф'
              defaultValue={checkTariff(measurement?.tariff?.toString())}
              fieldName={`${secondStr}.tariff`}
              values={Array.of('1', '2', '3')}
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            {type === Types.NEW && (
              // <TextField
              //   id='tf-breaker '
              //   label='Автомат тока'
              //   variant='standard'
              //   {...register(`breaker`, {
              //     value: breaker,
              //   })}
              //   sx={{ width: '100%' }}
              //   disabled
              // />
              <AutocompleteValue
                title='Автомат тока'
                defaultValue={breaker}
                fieldName={`${keyString}.automaticBreakerId`}
                values={getCatalogMapWithErr('automaticBreaker')}
                // disabled={isDisabledAll()}
              />
            )}
          </Grid>
          <Grid
            item
            xs={6}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.mac)}
              registerName={`${mainStr}.mac`}
              value={meter?.mac}
              label='MAC'
              disabled={isDisabledAll()}
              isChange={onChangeMainFields}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            {type === Types.NEW && (
              <YungaAnalyzeTextField
                type={getMarkedType(analyze?.stamp)}
                registerName={`${keyString}.stampInstallRequest.installNumber`}
                value={stampInstall?.installNumber}
                label='Пломба'
                disabled={isDisabledAll()}
                isChange={onChangeMainFields}
              />
            )}
            {type === Types.OLD && (
              <YungaAnalyzeTextField
                type={getMarkedType(analyze?.stamp)}
                registerName={`${keyString}.stampInstallRequest.existingNumber`}
                value={stampInstall?.existingNumber}
                label='Пломба'
                disabled={isDisabledAll()}
              />
            )}
          </Grid>

          {/* <Grid
            item
            xs={3}
          >
            {type === Types.NEW && (
              <TextField
                id='tf-factory-stamp-number '
                label='Заводская пломба'
                variant='standard'
                {...register(`stampInstall.stampNum`, {
                  value: stampInstall?.stampNum,
                })}
                sx={{ width: '100%' }}
                disabled
              />
            )}
          </Grid> */}
          <Grid
            item
            xs={6}
          >
            <TextField
              id='tf-montage-place'
              label='Место установки'
              variant='standard'
              {...register(`${mainStr}.montagePlace`, { value: meter?.montagePlace })}
              sx={{ width: '100%' }}
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            {type === Types.OLD ? (
              <TextField
                id='tf-balance'
                label='Баланс'
                variant='standard'
                {...register(`${keyString}.balance`, { value: balance })}
                sx={{ width: '100%' }}
                disabled={isDisabledAll()}
              />
            ) : (
              <TextField
                id='tf-factoryStampNumber'
                label='Пломба ККИ'
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`${keyString}.factoryStamp`, {
                  value: factoryStamp,
                })}
                disabled={isDisabledAll()}
              />
            )}
          </Grid>
          <Grid
            item
            xs={3}
          />
          <Grid
            item
            xs={3}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.t1)}
              registerName={`${secondStr}.t1`}
              value={measurement?.t1}
              label='T1'
              disabled={isDisabledAll()}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.t2)}
              registerName={`${secondStr}.t2`}
              value={measurement?.t2}
              label='T2'
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.t3)}
              registerName={`${secondStr}.t3`}
              value={measurement?.t3}
              label='T3'
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={3}
          />
          <Grid
            item
            xs={12}
          >
            {type === Types.NEW && (
              <TextField
                id='tf-descrioption'
                label='Описание'
                variant='standard'
                {...register(`mounted.puType`, {
                  value: meter?.puType,
                })}
                sx={{ width: '100%' }}
                disabled
              />
            )}
          </Grid>
          {type === Types.OLD && (
            <>
              <Grid
                item
                xs={5}
              >
                <AutocompleteValue
                  title='на балансе МЭС'
                  defaultValue={isSignedTransferredToMesCalculation ? 'да' : 'нет'}
                  fieldName={`${keyString}.isSignedTransferredToMesCalculation`}
                  values={['да', 'нет']}
                  disabled={isDisabledAll()}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <AutocompleteValue
                  title='Мастер забрал с собой'
                  defaultValue={isPickUpOldMeter ? 'да' : 'нет'}
                  fieldName={`${keyString}.isPickUpOldMeter`}
                  values={['да', 'нет']}
                  disabled={isDisabledAll()}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};
