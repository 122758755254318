import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import RequestStatistic from '@/components/statistics/RequestStatistic/RequestStatistic';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const RequestStatisticPage = () => {
  useAccess({
    access: [AccessEnum.Statistic, AccessEnum.TeamManagerVoormanAndExecutor],
  });
  useMetaTitle(`Статистика заявок`);
  return <RequestStatistic />;
};

export default RequestStatisticPage;
