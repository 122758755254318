import { Button, Grid, Stack } from '@mui/material';
import { useEffect } from 'react';

import ComplaintAddButton from '../ComplaintAddButton/ComplaintAddButton';
import ComplaintList from '../ComplaintList/ComplaintList';
import { useComplaintStore } from '../store';
import ComplaintBrowseFilter from '../ComplaintBrowseFilter/ComplaintBrowseFilter';
import shallow from 'zustand/shallow';
import ComplaintBrowsePagination from '../ComplaintBrowsePagination/ComplaintBrowsePagination';
import ComplaintInfo from '../ComplaintInfo/ComplaintInfo';
import DraggablePopup from '@/components/popups/DraggablePopup';
import ComplaintFormEnergy from '../ComplaintFormEnergy/ComplaintFormEnergy';
import ComplaintFormODPU from '../ComplaintFormODPU/ComplaintFormODPU';
import AddTaskToComplaintModal from '../AddTaskToComplaintModal';
import ComplaintQuerySearch from '../ComplaintQuerySearch/ComplaintQuerySearch';
import ComplaintReportButton from '../ComplaintReportButton/ComplaintReportButton';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { LoadingButton } from '@mui/lab';
import { WrapperForActionButtons, WrapperForReports } from './ComplaintsMainScreen.styled';

const ComplaintsMainScreen = () => {
  const {
    fetchEnergeTypeList,
    fetchEnergeClassificationList,
    fetchCompalintStageTypeList,
    fetchComplaintTypeList,
    fetchCompalaintSorceList,
    downloadComplaintsByFilter,
    isDownloadingReport,
  } = useComplaintStore(
    (state) => ({
      fetchEnergeTypeList: state.fetchEnergeTypeList,
      fetchEnergeClassificationList: state.fetchEnergeClassificationList,
      fetchCompalintStageTypeList: state.fetchCompalintStageTypeList,
      fetchCompalintWorkTypeEnergy: state.fetchCompalintWorkTypeEnergy,
      fetchComplaintTypeList: state.fetchComplaintTypeList,
      fetchCompalaintSorceList: state.fetchCompalaintSorceList,
      downloadComplaintsByFilter: state.downloadComplaintsByFilter,
      isDownloadingReport: state.isDownloadingReport,
    }),
    shallow
  );

  useEffect(() => {
    fetchEnergeTypeList();
    fetchEnergeClassificationList();
    fetchCompalintStageTypeList();
    fetchComplaintTypeList();
    fetchCompalaintSorceList();
  }, []);
  const allowedUseReport = useAccess({
    access: [AccessEnum.ComplaintsCreateEnergyType],
    stopRedirect: true,
  });

  return (
    <Stack
      p={2}
      sx={{ position: 'relative' }}
    >
      <ComplaintInfo />

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <ComplaintBrowseFilter />
        </Grid>
        <WrapperForActionButtons item>
          <div>
            <ComplaintAddButton />
          </div>
          <WrapperForReports>
            <LoadingButton
              disabled={isDownloadingReport}
              loading={isDownloadingReport}
              onClick={downloadComplaintsByFilter}
            >
              выгрузить жалобы
            </LoadingButton>
            {allowedUseReport && <ComplaintReportButton />}
          </WrapperForReports>
        </WrapperForActionButtons>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <>
          <ComplaintList />
          <ComplaintQuerySearch />
          <EditFormEnergy />
          <EditFormOdpu />
          <AddTaskToComplaintModal />
          <ComplaintBrowsePagination />
        </>
      </Grid>
    </Stack>
  );
};

export default ComplaintsMainScreen;

const EditFormEnergy = () => {
  const { isEditFormOpenEnergy, setIsEditFormOpenEnergy, handleSearch } = useComplaintStore(
    (state) => ({
      isEditFormOpenEnergy: state.isEditFormOpenEnergy,
      setIsEditFormOpenEnergy: state.setIsEditFormOpenEnergy,
      handleSearch: state.handleSearch,
    }),
    shallow
  );
  return (
    <DraggablePopup
      isOpen={isEditFormOpenEnergy}
      onClose={() => setIsEditFormOpenEnergy(false)}
    >
      <ComplaintFormEnergy closeModal={() => setIsEditFormOpenEnergy(false)} />
    </DraggablePopup>
  );
};
const EditFormOdpu = () => {
  const { isEditFormOpenOdpu, setIsEditFormOpenOdpu, handleSearch } = useComplaintStore(
    (state) => ({
      isEditFormOpenOdpu: state.isEditFormOpenOdpu,
      setIsEditFormOpenOdpu: state.setIsEditFormOpenOdpu,
      handleSearch: state.handleSearch,
    }),
    shallow
  );
  return (
    <DraggablePopup
      isOpen={isEditFormOpenOdpu}
      onClose={() => setIsEditFormOpenOdpu(false)}
    >
      <ComplaintFormODPU
        handleSearch={handleSearch}
        closeModal={() => setIsEditFormOpenOdpu(false)}
      />
    </DraggablePopup>
  );
};
