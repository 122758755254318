import React from 'react';
import { GetSMROdpuStatisticsTableByFilter } from '@/services/StatisticsService';
import { SMROdpuStatisticsTable } from '@/types/Types';

import { SMROdpuStatisticsFilter, TaskFilterParam } from '@/dto/taskmap/Dto';

import { SMROdpuStatistics } from '../SMROdpuStatistics/SMROdpuStatistics';
import { useActionLog } from '@/hooks/ActionLogHook';

const OdpuStatistic = () => {
  const { catchError } = useActionLog();

  const [smrOdpuStatisticsLoad, setSmrOdpuStatisticsLoad] = React.useState(false);

  const [smrOdpuStatisticsData, setSMROdpuStatisticsData] =
    React.useState<SMROdpuStatisticsTable>();

  const updateSMROdpuStatisticsTable = (filter: TaskFilterParam, monthNum: number) => {
    const smrOdpuStatisticsFilter: SMROdpuStatisticsFilter = {
      taskFilterParam: filter,
      monthNum: monthNum,
    };
    setSmrOdpuStatisticsLoad(true);
    GetSMROdpuStatisticsTableByFilter(smrOdpuStatisticsFilter)
      .then((data) => setSMROdpuStatisticsData(data.data))
      .catch((error) => catchError(error?.response?.data?.result ?? 'Непредвиденная ошибка', error))
      .finally(() => setSmrOdpuStatisticsLoad(false));
  };

  return (
    <>
      <SMROdpuStatistics
        load={smrOdpuStatisticsLoad}
        header={'Статистика по СМР ОДПУ'}
        updateFunction={updateSMROdpuStatisticsTable}
        data={smrOdpuStatisticsData}
      />
    </>
  );
};
export default OdpuStatistic;
