import Files from '@/components/features/drive/Files';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import React from 'react';

const FilesPage = () => {
  useAccess({
    access: [AccessEnum.Drive],
  });

  return <Files />;
};

export default FilesPage;
