import React, { FC } from 'react';
import { Colors } from '@/styles/colors';
import moment from 'moment';
import { MailNotificationsFolderResponse } from '@/dto/MailNotificationsDto';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { RowNumber } from '@/components/MailNotifications/MailNotifications.styled';
import { UploadZipFileById } from '@/services/MailNotificationsService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { ChronologyRowDiv } from '@/components/features/taskEditor/item/chronology/Chronology.styled';
import ChronologyItem from '@/components/features/taskEditor/item/chronology/ChronologyItem';

const FolderItem: FC<
  MailNotificationsFolderResponse & {
    borderColor?: Colors;
    onClick?: () => void;
    rowIndex: number;
    getFolders: () => void;
  }
> = ({
  createTs,
  fileName,
  borderColor,
  fileCount,
  onClick,
  loadedFileCount,
  rowIndex,
  id,
  getFolders,
}) => {
  const { catchError, addActionLog } = useActionLog();
  return (
    <>
      <ChronologyRowDiv borderColor={borderColor}>
        <RowNumber onClick={onClick}>{rowIndex}</RowNumber>
        <ChronologyItem
          label={'Имя'}
          value={fileName}
        />
        <ChronologyItem
          label={'Автор'}
          value={'Автор загрузки'}
        />
        <ChronologyItem
          label={'Дата'}
          value={createTs ? moment(createTs).format('DD.MM.YYYY') : '-'}
        />
        <ChronologyItem
          label={'Файлы'}
          value={`${loadedFileCount}/${fileCount}`}
          isWarning={loadedFileCount < fileCount}
        />
        {loadedFileCount < fileCount && (
          <label htmlFor={`input__file_${fileName}`}>
            <input
              type='file'
              accept='.zip'
              id={`input__file_${fileName}`}
              className='input input__file'
              hidden={true}
              onChange={(e) => {
                e.target.files &&
                  UploadZipFileById(id, e.target.files[0])
                    .then(() => addActionLog(ActionLogType.SUCCESS, 'Успешная загрузка архива'))
                    .catch((err) => {
                      catchError(err?.message ?? 'Ошибка загрузки архива', err);
                    })
                    .finally(getFolders);
              }}
              required={true}
              multiple={false}
            />

            <AddRoundedIcon
              color={'info'}
              sx={{ cursor: 'pointer' }}
            />
          </label>
        )}
      </ChronologyRowDiv>
    </>
  );
};

export default FolderItem;
