import { BubbleDataPoint, ChartData, ScatterDataPoint } from 'chart.js';
import { AxiosError } from 'axios';

export interface Task {
  id?: string;
  number: number;
  taskType: TaskType;
  taskStatus?: string;
  vldMessage?: string;
}

export enum TaskType {
  SMR_MKD = 'SMR_MKD',
  ASUMB_SMR_MKD = 'ASUMB_SMR_MKD',
}

export interface YodaToken {
  access_token?: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  create_ts: Date;
}

export type YodaTokenContextType = {
  getYodaToken: () => string | null;
  logOut: () => void;
};

export type AsumbTaskStatisticsType = {
  title: string;
  value: number;
  color: string;
};

export type AsumbTaskStatisticsForCheckChartType = {
  all: ChartData<'bar', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
  tko: ChartData<'pie', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
  meterChange: ChartData<'pie', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
};

export type MeterChangeForRevisionData = {
  all: ChartData<'line', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
  dataBar: Array<ChartData<'bar', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>>;
};

export type SMRStatisticsTableItem = {
  contractorName: string;
  onePhaseCount: number | null;
  threePhaseCount: number | null;
  puNotFoundCount: number | null;
  puIsUsedCount: number | null;
  stampNotFoundCount: number | null;
  notCompletedCount: number | null;
  incorrectMeasurementCount: number | null;
  failOnePhaseCount: number | null;
  failThreePhaseCount: number | null;
  failUnknownPhaseCount: number | null;
  allOnePhase: number | null;
  allThreePhase: number | null;
  allUnknownPhase: number | null;
  dynamicsOnePhase: number | null;
  dynamicsThreePhase: number | null;
  dynamicsUnknownPhase: number | null;
};

export const TaskStatuses = [
  { value: 'new', label: 'Новая' },
  { value: 'in_work', label: 'В работе' },
  { value: 'assigned', label: 'Назначенная' },
  { value: 'check', label: 'На проверке' },
  { value: 'returned', label: 'Возврат исполнителю' },
  { value: 'completed', label: 'Закрыта' },
  { value: 'not_relevant', label: 'Не актуальна' },
  { value: 'disput', label: 'Disput' },
];

export const McStatuses = [
  { value: 'puNotFound', label: 'ПУ не найден' },
  { value: 'isUsed', label: 'ПУ Используется' },
  { value: 'inAnotherContract', label: 'ПУ в другом контракте' },
  { value: 'puRemoved', label: 'ПУ удален' },
  { value: 'notComplete', label: 'Не завершен' },
  { value: 'removedByAnotherContractor', label: 'ПУ отвязан другим подрядчиком' },
  { value: 'stampNotFound', label: 'Пломба не найдена' },
  { value: 'incorrectMeasurement', label: 'Некорректные показания' },
];

export type SMROdpuStatisticsTableMonthResultItem = {
  all: number;
  installed: number;
  assigned: number;
};

export type SMROdpuStatisticsTableDataItem = {
  date: string;
  all: number;
  installed: number;
  assigned: number;
};

export type SMROdpuStatisticsTableItem = {
  executorName: string;
  installedSelectedMonth: string;
  allSelectedMonth: string;
  assignedSelectedMonth: string;
  monthResults: SMROdpuStatisticsTableMonthResultItem[];
  data: SMROdpuStatisticsTableDataItem[];
};

export type SMROdpuStatisticsTable = {
  allDays: string[];
  previousMonth: string[];
  smrOdpu: SMROdpuStatisticsTableItem[];
  smrOdpuEntity: SMROdpuStatisticsTableItem[];
  smrOdpuMoek: SMROdpuStatisticsTableItem[];
};

export type CallCenterStatisticsDto = {
  name: string;
  itemType: CallCenterStatisticItemType;
  maxMonthNumber: number;
  callsCount: number;
  tasksCount: number;
  pendingTasksCount: number;
  potentialReplacementsCount: number;
  items: CallCenterStatisticItem[];
  pendingTaskIds: string[];
};
export type StatisticMoreThanOneApealDto = {
  taskNumber: number;
  requests: string[];
  contractorName: string;
  tkoHistory: string[];
  taskId: string;
};
export type StatisticsResponsebleDto = {
  contractorName: string;
  contractorId: string;
  fromSiteCount: number;
  incomingBalanceCount: number;
  incomingCallsCount: number;
  installedFromIncoming: number;
  installedFromOutgoing: number;
  installedFromSite: number;
  installedTotalCount: number;
  outgoingBalanceCount: number;
  outgoingCallsCount: number;
  siteBalanceCount: number;
  tasksWithTkoAfterCallsTotalCount: number;
  tasksWithTkoAfterInCallCount: number;
  tasksWithTkoAfterOutCallCount: number;
  tasksWithTkoAfterSiteCallCount: number;
  totalBalanceCount: number;
  callsTotalCount: number;
};
export type StatisticsContractorDto = {
  contractorName: string;
  contractorId: string;
  stairs_1f: number;
  hall_1f: number;
  apartment_1f: number;
  stairs_3f: number;
  hall_3f: number;
  apartment_3f: number;
};

export enum CallCenterStatisticItemType {
  DATE = 'DATE',
  MONTH_TOTAL = 'MONTH_TOTAL',
  YEAR_TOTAL = 'YEAR_TOTAL',
  TOTAL = 'TOTAL',
}

export type CallCenterStatisticItem = {
  meterChangeCount: number;
  tkoCount: number;
  monthNumber: number;
  installationCallLogCount: number | string;
};

export type CallCenterStatisticsFilter = {
  contractIds?: string[];
  fromDate?: Date | string;
  toDate?: Date | string;
  contractorIds?: string[] | null;
  callRequestTypeIds?: string[];
  pendingTaskIds?: string[];
};
export type StatisticsResponsebleFilter = {
  contractIds?: string[];
  fromDate?: Date;
  toDate?: Date;
};

export type SmsStatusStatisticsItem = {
  contractName: string;
  callCenterSubscriberCount: number;
  subscriberWithoutSmsCount: number;
  subscriberWhomImpossibleSendSmsCount: SubscriberWhomImpossibleSendSmsCount[];
  subscribersWhomSmsWasSentCount: number;
  smsCount: number;
  sentCount: number;
  notSentCount: number;
  incomingCallAfterSms: IncomingCallAfterSms[];
};
export type StatisticByInstallationLocationData = {
  contract: string;
  contractor: string;
  montagePlace: string;
  phase: number;
  tkoCount: number;
  replacementCount: number;
  withoutResult: number;
  dynamicsForOneWeek: number;
  dynamicsForTwoWeek: number;
};

export type IncomingCallAfterSms = {
  taskId: string;
  address: string;
  subscribersPhoneNumber: string;
  messageStatus: string;
  installationLocation: string;
  phased: string;
  tariff: string;
  dateOfIncomingCall: Date;
  smsDate: Date;
  typeOfAppeal: string;
  lastTkoStatus: string;
  lastTkoDate: Date;
  visitHistory: string;
  replacementStatus: null | string;
};
export type SubscriberWhomImpossibleSendSmsCount = {
  taskId: string;
  address: string;
  subscribersPhoneNumber: string;
  messageStatus: string;
  installationLocation: string;
  phased: string;
  tariff: string;
};

/**
 * @description Базовый интерфейс для компонентов, которые предполагают стилизацию с помощью styled-components
 */
export interface BaseUI {
  className?: string;
  children?: React.ReactNode;
}

interface ApiFindAllContractItem {
  id: string;
  title: string;
}

interface AxiosErrorResponse {
  error?: string;
  message?: string;
  path?: string;
  status?: string;
  timestamp?: string;
}

interface AxiosErrorWithFlagResponse {
  messages: string[];
  timestamp?: string;
  userMessage?: boolean;
}

export type FetchCathError = AxiosError<AxiosErrorResponse>;
export type FetchWithUserMessageFlagCatch = AxiosError<AxiosErrorWithFlagResponse>;

export type ApiFindAllContractResponse = ApiFindAllContractItem[];

export type StatisticRPDto = {
  name: string;
  alias: string;
  dType: string;
};

export interface MapCatalog {
  [key: string]: string;
}
