import { GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { createStore } from '../utils/createStore';

interface ResAreaStore {
  resAreaSet: Set<String>;
  selectedResAreaSet: Set<String>;

  initAllReaArea: (geoTaskInBoundsList: GeoTaskInBounds[]) => void;
  updateResAreaList: (geoTaskInBoundsList: GeoTaskInBounds[]) => void;
  cleanResArea: () => void;
  selectResArea: (item: string, isSelect: boolean) => void;
  selectAllReaArea: (isSelect: boolean) => void;
  isResAreaSelected: (item: any) => boolean;
}

export const useResArea = createStore<ResAreaStore>(
  (set: any, get: any) => ({
    resAreaSet: new Set(),
    selectedResAreaSet: new Set(),

    initAllReaArea(geoTaskInBoundsList: GeoTaskInBounds[]) {
      const items = geoTaskInBoundsList
        .map((t: GeoTaskInBounds) => t.networkResArea)
        .filter((val, index, self) => self.indexOf(val) === index)
        .sort((a: string, b: string) => a?.localeCompare(b));

      set({ resAreaSet: items, selectedResAreaSet: items });
    },

    updateResAreaList: (geoTaskInBoundsList: GeoTaskInBounds[]) => {
      const items: string[] = geoTaskInBoundsList
        .map((t: GeoTaskInBounds) => t.networkResArea)
        .filter((val, index, self) => self.indexOf(val) === index)
        .sort((a: string, b: string) => a?.localeCompare(b));
      const selectedItems = [...get().selectedResAreaSet].filter(
        (t: string) => items.indexOf(t) > -1
      );
      // const removeItems=  Array.from(selectedItems).map(t=>items.contains(t))
      set({ resAreaSet: items, selectedResAreaSet: selectedItems });
    },

    cleanResArea() {
      set({ resAreaSet: [], selectedResAreaSet: [] });
    },

    selectResArea(item: string, isSelect: boolean) {
      let items = get().selectedResAreaSet;
      if (isSelect) {
        items = [...items, item];
      } else {
        items = get().selectedResAreaSet.filter((t: string) => t !== item);
      }
      set({ selectedResAreaSet: items });
    },

    selectAllReaArea(isSelect: boolean) {
      if (isSelect) {
        set({ selectedResAreaSet: get().resAreaSet });
      } else {
        set({ selectedResAreaSet: [] });
      }
    },

    isResAreaSelected(item: any): boolean {
      const selected = Array.from(get().selectedResAreaSet);
      return selected && selected.indexOf(item) > -1;
    },
  }),
  'Res area'
);
