import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { RegistryEnforse } from '@/components/features/registryEnforce/RegistryEnforse';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const RegistryEnforsePage = () => {
  useAccess({
    access: [AccessEnum.Registry],
  });
  useMetaTitle('Реестр Энфорс');
  return <RegistryEnforse />;
};

export default RegistryEnforsePage;
