import { Button, Grid, Stack, TextField, Tooltip, IconButton, Typography } from '@mui/material';
import { useComplaintStore } from '../store';
import {
  deleteFileFromComplaint,
  downloadComplaintImage,
  updateFilesInComplaint,
} from '@/services/ComplaintService/ComplaintService';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { useCatalog } from '@/hooks/CatalogHook';
import { useState } from 'react';
import shallow from 'zustand/shallow';
import FileInput from '@/components/inputs/FileInput/FileInput';

import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';

import ResponsibleBlock from './ResponsibleBlock';
import GalleryBlock from './GalleryBlock';

import CommonTextFields from './CommonTextFields';
import ResponsibleTextFields from './ResponsibleTextFields';
import MissedCall from './MissedCallComponent';
import { InfoComplaintBlockLabel, InfoComplaintBlockText } from './InfoComplaintBlock.styled';
import { useComplaintsEditAccess } from '../roles';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import ComplaintResultTaskBlock from '../ComplaintResultTaskBlock/ComplaintResultTaskBlock';
import { color } from '@/styles/mixins';
import { useGalleryDataFromTask } from '../utils';

const InfoComplaintBlock = ({ isEnergyType }: any) => {
  const [watchFiles, setWatchFiles] = useState([]);

  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const { setIsInProgress } = useScreenHoldHook();
  const { addActionLog, fetchWithUserMessageFlagCatch } = useActionLog();

  const methods = useForm();
  const { register, setValue: setFileValue, getValues } = methods;
  const {
    selectedCompliant,
    setIsEditFormOpenEnergy,
    setIsEditFormOpenOdpu,
    fetchSingleComplaint,
  } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
      setIsEditFormOpenEnergy: state.setIsEditFormOpenEnergy,
      setIsEditFormOpenOdpu: state.setIsEditFormOpenOdpu,
      fetchSingleComplaint: state.fetchSingleComplaint,
    }),
    shallow
  );

  const selectedCompliantId = selectedCompliant?.complaint?.base?.id;
  const hasAccessToEdit = useComplaintsEditAccess();

  const complaintODPUType = getCatalogMapWithErr('claimType').get(
    selectedCompliant?.odpuType?.toString() || ''
  );

  const complaintType = isEnergyType ? 'energy' : 'odpu';
  const updateFiles = () => {
    const formData = new FormData();
    getValues('files').forEach((file: any) => {
      formData.append('files', file);
    });
    setIsInProgress(true);
    updateFilesInComplaint(selectedCompliantId, formData, complaintType)
      .then(() => {
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error))
      .finally(() => setIsInProgress(false));
  };

  const deleteFilesFromBack = (id: string) => {
    return deleteFileFromComplaint(selectedCompliant?.complaint.base.id, complaintType, id)
      .then(() => {
        fetchSingleComplaint(selectedCompliantId, selectedCompliant?.complaint.type);
      })
      .catch((error) => fetchWithUserMessageFlagCatch(error));
  };

  const handleDownloadImgFileInput = (imageId: any) => downloadComplaintImage(imageId);

  return (
    <Stack
      spacing={1}
      maxHeight={'80vh'}
      overflow={'auto'}
      style={{ overscrollBehavior: 'contain' }}
    >
      <MissedCall />
      <ComplaintResultTaskBlock />
      <Stack
        style={{
          backgroundColor: color('trueWhite'),
          borderRadius: 15,
          padding: 15,
        }}
      >
        <Grid
          xs={12}
          container
          spacing={1}
          alignItems={'center'}
        >
          <Grid
            item
            xs={4}
          >
            <InfoComplaintBlockLabel>Дата создания</InfoComplaintBlockLabel>
          </Grid>
          <Grid
            item
            xs={5}
          >
            <InfoComplaintBlockText>
              {moment(
                isEnergyType
                  ? selectedCompliant?.complaint?.base?.createAt
                  : selectedCompliant?.odpuCreateDate
              ).format('DD.MM.YYYY')}
            </InfoComplaintBlockText>
          </Grid>
          <Grid
            item
            xs={3}
            display={'flex'}
            justifyContent={'end'}
          >
            <IconButton
              disabled={!hasAccessToEdit}
              onClick={() =>
                isEnergyType ? setIsEditFormOpenEnergy(true) : setIsEditFormOpenOdpu(true)
              }
            >
              <EditIcon color={hasAccessToEdit ? 'primary' : undefined} />
            </IconButton>
          </Grid>
          {isEnergyType ? (
            <>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Классификация</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {selectedCompliant?.classification?.title}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Вид обращения</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {selectedCompliant?.energyType?.code}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Номер САП</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText> {selectedCompliant?.sapNumber}</InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Срок ответа </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {moment(selectedCompliant?.responseDate).format('DD.MM.YYYY')}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> ФИО заявителя </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText> {selectedCompliant?.declarerFio}</InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Телефон </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>{selectedCompliant?.phoneNumber}</InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Доп. телефон </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {selectedCompliant?.phoneNumberAdditional}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> ЛС </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>{selectedCompliant?.account}</InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Тип ПУ</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>{selectedCompliant?.meterType}</InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> № прибора учета</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {selectedCompliant?.complaint.meterNumber}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel>Тарификация</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>{selectedCompliant?.tariffication}</InfoComplaintBlockText>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Тип жалобы</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>{complaintODPUType}</InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Реквизиты письма</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText> {selectedCompliant?.letterDetails}</InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Реквизиты ответа</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {selectedCompliant?.responseDetails}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Плановая дата </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {moment(selectedCompliant?.complaint?.plannedDate).format('DD.MM.YYYY')}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel>ID Ввода </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {selectedCompliant?.complaint.reasonTaskId}
                </InfoComplaintBlockText>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel> Родительская заявка </InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText
                  color={
                    selectedCompliant?.complaint.resultTaskId ? color('muiBlue') : color('gray')
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectedCompliant?.complaint.reasonTaskId &&
                      window.open('/task/edit/' + selectedCompliant?.complaint.reasonTaskId);
                  }}
                >
                  {selectedCompliant?.complaint.reasonTaskNumber}
                </InfoComplaintBlockText>
              </Grid>
            </>
          )}

          <Grid
            item
            xs={4}
          >
            <InfoComplaintBlockLabel>Адрес</InfoComplaintBlockLabel>
          </Grid>

          <Grid
            item
            xs={8}
          >
            <InfoComplaintBlockText>
              {selectedCompliant?.complaint.address.readOnlyAddress}
            </InfoComplaintBlockText>
          </Grid>
          {isEnergyType && (
            <>
              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel>РЭС</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {getCatalogMapWithErr('regionalElectricNetwork').get(
                    selectedCompliant?.res || ''
                  )}
                </InfoComplaintBlockText>
              </Grid>

              <Grid
                item
                xs={4}
              >
                <InfoComplaintBlockLabel>ПЭС</InfoComplaintBlockLabel>
              </Grid>
              <Grid
                item
                xs={8}
              >
                <InfoComplaintBlockText>
                  {getCatalogMapWithErr('energyCompany').get(selectedCompliant?.pes || '')}
                </InfoComplaintBlockText>
              </Grid>
              {selectedCompliant?.consumerReadyToMeet && (
                <>
                  <Grid
                    item
                    xs={4}
                  >
                    <InfoComplaintBlockLabel>Дата следующего звонка</InfoComplaintBlockLabel>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                  >
                    <InfoComplaintBlockText>
                      {moment(selectedCompliant?.consumerReadyToMeet).format('DD.MM.YYYY')}
                    </InfoComplaintBlockText>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid
            xs={12}
            item
          >
            <FormProvider {...methods}>
              <FileInput
                register={register('files')}
                setFormFiles={setFileValue}
                filesFromBackend={selectedCompliant?.files}
                watchFiles={setWatchFiles}
                filesFromBackendGetFunction={handleDownloadImgFileInput}
                deleteFileFromBack={deleteFilesFromBack}
                disableEdit={!hasAccessToEdit}
              />
            </FormProvider>
            {watchFiles.some((file: any) => !file.id) && (
              <Button onClick={updateFiles}>отправить файлы</Button>
            )}
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Tooltip
              title={selectedCompliant?.complaint.text || ''}
              placement='left'
              arrow
            >
              <TextField
                value={selectedCompliant?.complaint.text}
                fullWidth
                label='Текст обращения'
                multiline
                rows={3}
                variant='standard'
                disabled
              />
            </Tooltip>
          </Grid>
        </Grid>
        {isEnergyType && selectedCompliant && (
          <ResponsibleBlock
            selectedCompliant={selectedCompliant}
            disableEdit={!hasAccessToEdit}
          />
        )}
      </Stack>
      <GalleryBlock />
      {selectedCompliant &&
        selectedCompliant?.complaint.stage.name !== ComplaintStage.TRANSFERRED_TO_RESPONSIBLE && (
          <CommonTextFields
            isEnergyType={isEnergyType}
            selectedCompliant={selectedCompliant}
          />
        )}
      {selectedCompliant?.complaint.stage.name === ComplaintStage.TRANSFERRED_TO_RESPONSIBLE && (
        <ResponsibleTextFields isEnergyType={isEnergyType} />
      )}
    </Stack>
  );
};
export default InfoComplaintBlock;
