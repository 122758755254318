import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';

import { NetworkMap } from '@/components/sections/NetworkMap/NetworkMap';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const NetworkMapPage = () => {
  useAccess({
    access: [AccessEnum.MapUspd, AccessEnum.Map, AccessEnum.MapUspdOnly],
  });
  useMetaTitle('Карта УСПД');

  return <NetworkMap />;
};
export default NetworkMapPage;
