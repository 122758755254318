import { HouseEntranceShemeEntranceCube } from '@/components/sections/HouseEntranceSheme/HouseEntranceSheme.styled';
import { Button, IconButton, Menu, Tooltip, Popover } from '@mui/material';
import React, { useState } from 'react';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useAnchorElement } from '@/hooks/useAnchorElement';
import {
  PnrDndEditEntranceModalForm,
  PnrDndEditModalAddFlatForm,
  PnrDndEditModalEntranceHeaderInner,
} from './PnrDndEditModal.styled';
import { PnrDndEditModalFormValues } from './PnrDndEditModalForm';
import {
  ExistedFlat,
  existedFlatsByNumber,
  existedStaffRoomsByNumber,
  generateExistedFlatsErrorMessage,
  generateExistedStaffRoomsErrorMessage,
  PnrDndEditModalFormType,
} from './utils';
import { usePNRStore } from '../../store';
import shallow from 'zustand/shallow';
import { HouseEntranceFloorDataDto } from '@/dto/taskmap/Dto';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { HouseEntranceHeaderTextField } from '@/components/features/pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import { syncErrorCatch } from '@/hooks/ActionLogHook';

export interface PnrDndEditModalEntranceHeaderProps {
  entranceNumber: number;
  floorCount: number;
}

const PnrDndEditModalEntranceHeader = ({
  entranceNumber,
  floorCount,
}: PnrDndEditModalEntranceHeaderProps) => {
  const { houseEntranceData, setHouseEntranceData } = usePNRStore(
    (state) => ({
      houseEntranceData: state.houseEntranceData,
      setHouseEntranceData: state.setHouseEntranceData,
    }),
    shallow
  );
  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();

  const {
    anchorEl: anchorCompanyAdd,
    handleOpen: handleOpenCompanyAdd,
    handleClose: handleCloseCompanyAdd,
    open: openCompanyAdd,
  } = useAnchorElement();

  const [companyPlaceNumber, setCompanyPlaceNumber] = useState('');
  const handleEntranceEditSubmit = ({
    flatByEntranceRange,
    flatSinceWith,
    floorCount,
  }: PnrDndEditModalFormValues) => {
    if (!houseEntranceData) {
      return;
    }
    const allEntrances = [...houseEntranceData];
    const neededEntrance = allEntrances?.find(
      (entrance) => entrance.entranceNumber === entranceNumber
    );

    if (!neededEntrance) return;

    neededEntrance.floorCount = floorCount;
    neededEntrance.floors = neededEntrance.floors?.map((floor) => ({
      ...floor,
      flats: [],
    })) as HouseEntranceFloorDataDto[] | null;
    setHouseEntranceData(allEntrances);

    const alreadyExistedFlats: ExistedFlat[] = [];

    let flatNumber = flatSinceWith;
    const newFloors = [];
    for (let i = 1; i <= floorCount; i++) {
      const floorNumber = i;
      const flats = [];
      const neededId = neededEntrance?.floors?.find((it) => it.floorNumber === floorNumber)?.id;
      const isNonResidential = neededEntrance?.floors?.find(
        (it) => it.floorNumber === floorNumber
      )?.isNonResidentialFloor;

      if (!isNonResidential) {
        while (flats.length < flatByEntranceRange) {
          const currentFlatNumber = String(flatNumber);
          flatNumber++;
          const existedFlats = existedFlatsByNumber(currentFlatNumber);

          if (existedFlats.length) {
            alreadyExistedFlats.push(...existedFlats);
          } else {
            flats.push(currentFlatNumber);
          }
        }
      }

      newFloors.push({
        floorNumber,
        flats,
        isNonResidentialFloor: !!isNonResidential,
        ...(neededId ? { id: neededId } : {}),
      });
    }
    neededEntrance.floors = newFloors.sort((a, b) => b.floorNumber - a.floorNumber);
    if (neededEntrance.staffRooms?.length) {
      neededEntrance.staffRooms = neededEntrance.staffRooms.filter(
        (staffRoom) => parseInt(staffRoom.floorNumber) < neededEntrance.floorCount
      );
    }

    generateExistedFlatsErrorMessage(alreadyExistedFlats);
    setHouseEntranceData(allEntrances);
    handleClose();
  };

  const handleFlatNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyPlaceNumber(event.target.value);
  };

  const hanleNewStaffRoomSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (companyPlaceNumber.length < 1) {
      syncErrorCatch('Введите номер квартиры', {});
      return;
    }
    const existedStaffRooms = existedStaffRoomsByNumber(companyPlaceNumber);

    if (!existedStaffRooms.length) {
      if (!houseEntranceData) {
        return;
      }
      const allEntrances = [...houseEntranceData];
      const neededEntrance = allEntrances?.find(
        (entrance) => entrance.entranceNumber === entranceNumber
      );

      if (!neededEntrance) return;
      if (neededEntrance.staffRooms?.length) {
        neededEntrance.staffRooms.push({ floorNumber: '1', number: companyPlaceNumber });
      } else {
        neededEntrance.staffRooms = [{ floorNumber: '1', number: companyPlaceNumber }];
      }
      setHouseEntranceData(allEntrances);
      handleCloseCompanyAdd();
    } else {
      generateExistedStaffRoomsErrorMessage(existedStaffRooms);
    }
    setCompanyPlaceNumber('');
  };

  const handleReset = () => {
    if (!houseEntranceData) {
      return;
    }
    const allEntrances = [...houseEntranceData];
    const neededEntrance = allEntrances?.find(
      (entrance) => entrance.entranceNumber === entranceNumber
    );

    if (!neededEntrance) return;

    neededEntrance.floors = neededEntrance.floors?.map((floor) => ({
      ...floor,
      flats: [],
      isNonResidentialFloor: false,
    })) as HouseEntranceFloorDataDto[] | null;
    setHouseEntranceData(allEntrances);
    handleClose();
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 63 }} />
      <PnrDndEditModalEntranceHeaderInner>
        <HouseEntranceShemeEntranceCube>{entranceNumber}</HouseEntranceShemeEntranceCube>
        <Tooltip title={'Редактировать подъезд'}>
          <IconButton
            style={{ fontSize: 20 }}
            color='primary'
            onClick={handleOpen}
          >
            <EditRoundedIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>

        <Tooltip title={'Добавить юр. лицо'}>
          <IconButton
            style={{ fontSize: 20 }}
            color='primary'
            onClick={handleOpenCompanyAdd}
          >
            <PersonAddAltRoundedIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorCompanyAdd}
          open={openCompanyAdd}
          onClose={() => handleCloseCompanyAdd()}
        >
          <PnrDndEditModalAddFlatForm onSubmit={hanleNewStaffRoomSubmit}>
            <HouseEntranceHeaderTextField
              label='Номер помещения'
              value={companyPlaceNumber}
              variant='standard'
              InputLabelProps={{ shrink: true }}
              onChange={handleFlatNumberChange}
              autoFocus
              autoComplete='off'
            />
            <Button
              variant='contained'
              type='submit'
            >
              Добавить
            </Button>
          </PnrDndEditModalAddFlatForm>
        </Menu>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PnrDndEditEntranceModalForm
            type={PnrDndEditModalFormType.ENTRANCE}
            onSubmit={handleEntranceEditSubmit}
            forcedFloorCount={floorCount}
            onReset={handleReset}
          />
        </Popover>
      </PnrDndEditModalEntranceHeaderInner>
      <div style={{ width: 36 }} />
    </div>
  );
};

export default PnrDndEditModalEntranceHeader;
