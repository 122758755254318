import { Loading } from '@/components/ui/Loading';
import { HouseEntranceShemeWrapper } from '@/components/sections/HouseEntranceSheme/HouseEntranceSheme.styled';
import { color, scrollbarThin } from '@/styles/mixins';
import { InputLabel, styled } from '@mui/material';
import PnrDndEditModalForm from './PnrDndEditModalForm';

export const PnrDndEditModalInner = styled('div')`
  width: 100%;
  overflow: auto;
  user-select: none;
  display: flex;
  ${scrollbarThin};
  padding-bottom: 20px;
  align-items: flex-end;
`;

export const PnrDndEditModalAddFlatForm = styled('form')({
  display: 'flex',
  alignItems: 'center',
  gap: 14,
  padding: '8px 14px',
});

interface PnrDndEditModalDroppableInnerProps {
  isDraggingOver?: boolean;
  disableDrop?: boolean;
}

export const PnrDndEditModalDroppableInner = styled('div')<PnrDndEditModalDroppableInnerProps>(
  ({ isDraggingOver, disableDrop }) => ({
    display: 'flex',
    background: 'lightgrey',
    border: `0.5px solid`,
    borderColor: color('trueGray'),
    willChange: 'border-color',
    borderRadius: '5px',
    transition: 'border 0.3s ease',
    height: '26px',
    width: '100%',

    ...(isDraggingOver && {
      borderColor: disableDrop ? color('muiError') : color('muiBlue'),
    }),
  })
);

export const PnrDndEditModalFloorWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const PnrDndEditModalLoadingInput = styled(Loading)({
  height: '32px !important',
});

export const PnrDndEditModalShemeWrapper = styled(HouseEntranceShemeWrapper)({
  gap: 20,
});

export const PnrDndEditEntranceModalForm = styled(PnrDndEditModalForm)({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  padding: '10px 16px',
});

export const PnrDndEditHouseModalForm = styled(PnrDndEditModalForm)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  gap: 20,
  padding: '10px 16px',
});

interface PnrDndEditModalTrashProps {
  isDraggingOver?: boolean;
}

export const PnrDndEditModalTrash = styled('div')<PnrDndEditModalTrashProps>(
  ({ isDraggingOver }) => ({
    padding: '10px',
    background: `${isDraggingOver ? color('muiError', 0.9) : color('trueGray', 0.5)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    transition: 'background 0.3s ease',
    border: `1px solid ${color('muiError')}`,
    width: '40%',
    margin: 'auto',
    color: `${isDraggingOver ? 'white' : color('muiError', 0.9)}`,
  })
);

export const PnrDndEditModalEntranceHeaderInner = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: 4,
  alignItems: 'center',
  flexGrow: 1,
});

export const PnrDndEditModalStaffRoomInner = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
});

export const PnrDndEditModalCenterWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  justifyContent: 'center',
});

export const PnrDndEditModalInputLabel = styled(InputLabel)({
  fontWeight: '600',
  flexShrink: 0,
});
