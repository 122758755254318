import React from 'react';
import { useEffect, useState } from 'react';
import { YMaps, Map, Polygon, FullscreenControl } from 'react-yandex-maps';
import { ZoningPolygon } from './polygon';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useSearch } from '../hook/SearchHook';
import { useZoning } from '../hook/ZoningHook';
import { geoBoundsToGeometry } from '../utils/GeoUtils';
import { findAllZoningBound } from '@/services/ZoningService';
import { ZoningCluster } from './placemark';

export const ZoningMap = () => {
  const MOSCOW_CENTER_COORDINATES = [37.57, 55.75];
  const {
    geoBounds,
    setGeoBounds,
    setAutoUpdate,
    zoningResponse,
    zoningBoundResponse,
    setZoningBoundResponse,
  } = useSearch();
  const filter = useSearch((state) => state.getFilter());
  const { zoningGroupResponses } = useZoning();
  const { setIsInProgress } = useScreenHoldHook();
  const [mapRef, setMapRef] = useState<any>();
  const isGridVisible = false;

  useEffect(() => {
    handleRefreshMap();
  }, [geoBounds || filter]);

  useEffect(() => {
    setGeoRequest();
  }, [mapRef]);

  useEffect(() => {
    setZoningBoundResponse(undefined);
  }, [zoningResponse]);

  const onMapBoundsChange = (e: any) => {
    if (e.get('oldBounds') != e.get('newBounds')) {
      setGeoRequest();
    }
    setAutoUpdate(e.get('newZoom'));
  };

  const setGeoRequest = () => {
    setGeoBounds(mapRef?.getBounds());
  };

  const handleRefreshMap = () => {
    if (filter) {
      setIsInProgress(true);
      findAllZoningBound({ geoBounds: geoBounds, filter: filter })
        .then(({ data }) => setZoningBoundResponse(data))
        .finally(() => setIsInProgress(false));
    }
  };

  return (
    <YMaps
      query={{
        ns: 'use-load-option',
        load: 'Map,Placemark,control,geoObject.addon.balloon,geoObject.addon.hint',
        coordorder: 'longlat',
      }}
    >
      <Map
        instanceRef={(ref: any) => ref && setMapRef(ref)}
        modules={['geoQuery', 'geoObject.addon.editor']}
        defaultState={{
          center: MOSCOW_CENTER_COORDINATES,
          zoom: 5,
          controls: ['zoomControl'],
        }}
        width={'100%'}
        height={'100%'}
        options={{ autoFitToViewport: 'always', minZoom: 2 }}
        defaultOptions={{ minZoom: 2 }}
        onBoundsChange={onMapBoundsChange}
      >
        <FullscreenControl />
        <ZoningCluster></ZoningCluster>
        {zoningGroupResponses &&
          zoningGroupResponses.map((t) => (
            <ZoningPolygon
              key={`zoning-polygon-${t.groupId}`}
              zoningGroupResponse={t}
            />
          ))}
        {isGridVisible &&
          zoningBoundResponse
            ?.filter((t) => t?.geoBounds)
            .map((t, i) => {
              return (
                <Polygon
                  key={`zoning-polygon-${i}`}
                  geometry={geoBoundsToGeometry(t?.geoBounds)}
                  options={{
                    strokeWidth: 1,
                    strokeColor: '#0000FF',
                    fill: false,
                  }}
                />
              );
            })}
      </Map>
    </YMaps>
  );
};
