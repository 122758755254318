import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import AsumbStatistic from '@/components/statistics/AsumbStatistic/AsumbStatistic';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const AsumbStatisticPage = () => {
  useAccess({
    access: [AccessEnum.Statistic],
  });
  useMetaTitle(`Статистика АСУМБ`);
  return <AsumbStatistic />;
};

export default AsumbStatisticPage;
