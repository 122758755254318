import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import {
  ApiFormHousePlannings,
  ApiDeleteHousePlannings,
  ApiFormHousePrePlannings,
} from '@/services/YodaRestService';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { PnrPlanningButtonsWrapper } from './PnrPlanningButtons.styled';
import { usePnrPlanningStore } from '@/components/sections/PnrPlanning/store';
import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import shallow from 'zustand/shallow';
import { NetworkPlanningTypes } from '../PnrElementsInfo/utils';
import { useDisabledPnrPlanningButtons } from './utils';
type PlanTypes = NetworkPlanningTypes.SECONDLY | NetworkPlanningTypes.FIRSTLY;

export interface PnrPlanningsButtonsProps {
  refetchHousePlannings: () => void;
}
const PnrPlanningButtons = ({ refetchHousePlannings }: PnrPlanningsButtonsProps) => {
  const { resetStore, isEmptyPlanningsList, isEmptyPrePlanningsList } = usePnrPlanningStore(
    (state) => ({
      resetStore: state.resetStore,
      isEmptyPlanningsList: state.isEmptyPlanningsList,
      isEmptyPrePlanningsList: state.isEmptyPrePlanningsList,
    }),
    shallow
  );
  const disableByRole = useDisabledPnrPlanningButtons();
  const { setIsInProgress } = useScreenHoldHook();
  const { houseDtoId } = useParams();
  const { catchError, addActionLog } = useActionLog();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<null | PlanTypes>(null);

  const handleFormPlan = (planType: PlanTypes) => {
    setIsInProgress(true);
    const apiCall =
      planType === NetworkPlanningTypes.FIRSTLY ? ApiFormHousePlannings : ApiFormHousePrePlannings;
    apiCall(houseDtoId!)
      .then(({ data }) => {
        addActionLog(ActionLogType.SUCCESS, `Запланировано оборудования: ${data?.length} шт.`);
        refetchHousePlannings();
      })
      .catch((error) =>
        catchError(`Ошибка ${isEmptyPlanningsList ? 'создания' : 'обновления'} плана`, error)
      )
      .finally(() => setIsInProgress(false));
  };

  const openConfirmModal = (deletePlan: PlanTypes) => setIsOpenConfirmModal(deletePlan);
  const closeConfirmModal = () => setIsOpenConfirmModal(null);

  const handleDeletePlan = () => {
    setIsInProgress(true);
    ApiDeleteHousePlannings(houseDtoId!, isOpenConfirmModal === NetworkPlanningTypes.SECONDLY)
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, `План успешно удален`);
        resetStore();
        refetchHousePlannings();
      })
      .catch((error) => catchError(`Ошибка удаления плана`, error))
      .finally(() => {
        setIsInProgress(false);
        closeConfirmModal();
      });
  };

  return (
    <PnrPlanningButtonsWrapper
      groupName={'Планировщик'}
      startIcon={<NoteAltOutlinedIcon color={disableByRole ? undefined : 'primary'} />}
      disabled={disableByRole}
    >
      <Button onClick={() => handleFormPlan(NetworkPlanningTypes.FIRSTLY)}>
        {isEmptyPlanningsList ? 'Создать' : 'Обновить'} первичный план
      </Button>
      <Button
        disabled={isEmptyPlanningsList}
        onClick={() => openConfirmModal(NetworkPlanningTypes.FIRSTLY)}
      >
        Удалить первичный план
      </Button>

      <Button onClick={() => handleFormPlan(NetworkPlanningTypes.SECONDLY)}>
        {isEmptyPrePlanningsList ? 'Создать' : 'Обновить'} вторичный план
      </Button>
      <Button
        disabled={isEmptyPrePlanningsList}
        onClick={() => openConfirmModal(NetworkPlanningTypes.SECONDLY)}
      >
        Удалить вторичный план
      </Button>

      <ConfirmationDialog
        title={`Внимание!`}
        handleOk={handleDeletePlan}
        handleCancel={closeConfirmModal}
        open={!!isOpenConfirmModal}
        textForOk={'Да'}
        textForCancel='Нет'
      >
        <Typography variant='subtitle1'>Вы подтверждаете удаление плана?</Typography>
      </ConfirmationDialog>
    </PnrPlanningButtonsWrapper>
  );
};

export default PnrPlanningButtons;
