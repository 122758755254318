import { BaseUI } from '@/types/Types';
import React from 'react';
import { HouseEntranceFlatCountMatchedWrapper } from './HouseEntranceFlatCountMatched.styled';
import { Tooltip } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { usePNRStore } from '../store';

const HouseEntranceFlatCountMatched = ({ children, className }: BaseUI) => {
  const isFlatCountMatched = usePNRStore((state) => state.houseData)?.isFlatCountMatched;
  const validFlatCount = isFlatCountMatched !== null && isFlatCountMatched !== undefined;

  return (
    <HouseEntranceFlatCountMatchedWrapper
      matched={isFlatCountMatched}
      className={className}
    >
      {children}
      {validFlatCount && (
        <sup>
          <Tooltip
            title={
              isFlatCountMatched
                ? 'Всё в порядке. Данные с картографических сервисов проверены и актуальны.'
                : 'Внимание! Данные с картографических сервисов могут быть неточными. Рекомендуется проверить информацию'
            }
            placement='top'
          >
            <span>
              {isFlatCountMatched ? (
                <CheckCircleOutlineRoundedIcon color='success' />
              ) : (
                <ErrorOutlineRoundedIcon color='warning' />
              )}
            </span>
          </Tooltip>
        </sup>
      )}
    </HouseEntranceFlatCountMatchedWrapper>
  );
};

export default HouseEntranceFlatCountMatched;
