import { CallCenterWorkTypeForSelect } from '@/dto/taskmap/Dto';
import { CallCenterStatisticsDto, CallCenterStatisticsFilter } from '@/types/Types';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { GetCallCenterStatisticsXlsx } from '@/services/StatisticsService';

interface Option {
  catalog?: boolean;
}
interface DownloadExcelParams {
  filter: CallCenterStatisticsFilter;
  fetchCatch: (error: any, message: string) => void;
  setIsExcelLoading: (isLoading: boolean) => void;
  setIsReadyToDownload: (isReady: boolean) => void;
}

const TEMPLATE = 'CALL_CENTER';

export const convertIntoFilter = (data: any[], options?: Option): CallCenterWorkTypeForSelect[] => {
  const uniqueTitles: Record<string, boolean> = {};
  return data.map((gr: any) => {
    let labelKey = options?.catalog ? `${gr.callType} ${gr.workTypeName}` : gr.title;
    if (!uniqueTitles[labelKey]) {
      uniqueTitles[labelKey] = true;
    } else {
      labelKey += ' ';
    }

    return { value: gr.id, label: labelKey };
  });
};

export const getMaxMonth = (data: CallCenterStatisticsDto[]) => {
  return data
    ? Math.max.apply(
        null,
        data?.map((item) => item.maxMonthNumber)
      )
    : 0;
};

export const UnixTimestampToDateTime = (timestamp: Date | number) => {
  const date = new Date(timestamp);
  return date;
};

// Define the Row interface
interface Row {
  [key: string]: string | number;
}

interface Column {
  field: string;
  headerName: string;
  width: number;
}
export const generateExcelFile = (data: CallCenterStatisticsDto[], showOnlyTotals: boolean) => {
  const filteredData = showOnlyTotals
    ? data.filter((item) => item.itemType === 'TOTAL' || item.itemType === 'MONTH_TOTAL')
    : data;

  const rows: Row[] = filteredData.map((item) => {
    const row: Row = {
      Name: item.name,
      'Кол-во звонков': item.callsCount,
      'Кол-во заявок': item.tasksCount,
      'Кол-во необработанных заявок': item.pendingTasksCount,
      'Кол-во потенциальных замен': item.potentialReplacementsCount,
    };

    for (let i = 0; i < getMaxMonth(data); i++) {
      const monthNum = i + 1;
      row['ПУ ' + monthNum] = item.items[i]?.meterChangeCount || 0;
      row['ТКО ' + monthNum] = item.items[i]?.tkoCount || 0;
    }
    return row;
  });

  const columns: Column[] = [
    { field: 'Name', headerName: '', width: 150 },
    { field: 'Кол-во звонков', headerName: 'Кол-во звонков', width: 150 },
    { field: 'Кол-во заявок', headerName: 'Кол-во заявок', width: 150 },
  ];
  for (let i = 0; i < getMaxMonth(data); i++) {
    const monthNum = i + 1;
    columns.push({
      field: 'ПУ ' + monthNum,
      headerName: 'ПУ ' + monthNum + ' м',
      width: 150,
    });
    columns.push({
      field: 'ТКО ' + monthNum,
      headerName: 'ТКО ' + monthNum + ' м',
      width: 150,
    });
  }
  columns.push(
    {
      field: 'Кол-во необработанных заявок',
      headerName: 'Кол-во необработанных заявок',
      width: 200,
    },
    { field: 'Кол-во потенциальных замен', headerName: 'Кол-во потенциальных замен', width: 250 }
  );

  const dataForExcel: (string | number)[][] = [columns.map((column) => column.headerName)]; // Excel header row
  // if (data.length > 0) {
  //   dataForExcel.push(getFirstHeaderLine().map((cell) => cell.props.children)); // First header row
  // }
  rows.forEach((row) => {
    dataForExcel.push(columns.map((column) => row[column.field])); // Excel data rows
  });
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataForExcel);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, 'call_center_statistics.xlsx');
};

export const convertTimeToShowInEye = (dataInRange: number[] | Date[]) => {
  if (dataInRange.length) {
    return `${moment(dataInRange[0]).format('DD.MM.YYYY')} ~ ${moment(dataInRange[1]).format(
      'DD.MM.YYYY'
    )}`;
  }
  return '';
};

export const downloadExcel = ({
  filter,
  fetchCatch,
  setIsExcelLoading,
  setIsReadyToDownload,
}: DownloadExcelParams) => {
  setIsExcelLoading(true);
  GetCallCenterStatisticsXlsx({ taskIdList: filter?.pendingTaskIds ?? [], template: TEMPLATE })
    .then((res: any) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      // Создаем URL для Blob
      const url = window.URL.createObjectURL(blob);
      // Создаем элемент ссылки для запуска загрузки
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report-1.xlsx'; // Устанавливаем имя файла
      // Запускаем загрузку
      a.click();
      // Освобождаем URL для Blob
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      fetchCatch(error, 'Неизвестная ошибка');
    })
    .finally(() => {
      setIsReadyToDownload(false);
      setIsExcelLoading(false);
    });
};
