import { Box, InputLabel, Modal, TextField } from '@mui/material';
import React, { FC } from 'react';
import { usePNRStore } from '../store';
import { Button } from '@material-ui/core';
import { ApiSaveListHouseEntranceData } from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';

interface IEntranceResetAlertModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateHouseEntranceData?: () => void;
}

export const EntranceResetAlertModal: FC<IEntranceResetAlertModal> = ({
  open,
  setOpen,
  updateHouseEntranceData,
}) => {
  const { houseEntranceData, houseData, entranceIdx } = usePNRStore((state) => ({
    houseEntranceData: state.houseEntranceData,
    houseData: state.houseData,
    entranceIdx: state.entranceIdx,
  }));

  const { catchError } = useActionLog();

  const closeModal = () => {
    setOpen(false);
  };

  const resetEntranceData = () => {
    if (houseEntranceData && houseData) {
      const entranceDataList = houseEntranceData?.map((elem, index) => ({
        ...elem,
        floorCount: houseData.floorCount ? houseData.floorCount : elem.floorCount,
        floors: elem.floors
          ? elem.floors?.map((el, ind) => ({
              ...el,
              flats: [],
              isNonResidentialFloor: false,
            }))
          : [],
        staffRooms: [],
      }));
      ApiSaveListHouseEntranceData(houseData.houseId, entranceDataList)
        .then((res) => {
          setOpen(false);
          if (updateHouseEntranceData) {
            updateHouseEntranceData();
          }
        })
        .catch((error) => {
          catchError('Ошибка сохранения данных', error);
        });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 20,
            p: 4,
          }}
        >
          <div
            style={{
              marginBottom: '20px',
              fontSize: '20px',
              fontWeight: '500',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            Ранее заполненные данные по подъездам будут удалены. Продолжить?
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              marginLeft: '20px',
              marginTop: '10px',
            }}
          ></div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              variant='contained'
              onClick={resetEntranceData}
            >
              OK
            </Button>
            <Button
              style={{ marginLeft: '20px' }}
              onClick={closeModal}
            >
              Отмена
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
