import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Theme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SxProps } from '@mui/system';
import { useDisabledAll } from '@/hooks/useDisabledAll';

interface ConfirmationDialogProps {
  title: string;
  handleOk(): void;
  handleCancel(): void;
  open: boolean;
  children?: React.ReactNode;
  fullScreen?: boolean;
  moreButtons?: React.ReactNode | React.ReactNode[];
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  textForOk?: string;
  textForCancel?: string;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title,
    handleOk,
    handleCancel,
    open,
    children,
    fullScreen = false,
    moreButtons,
    sx,
    maxWidth = 'md',
    fullWidth = false,
    textForCancel = 'отмена',
    textForOk = 'ок',
  } = props;
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    handleCancel();
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleAction = (isOk: boolean) => {
    if (isOk) {
      handleOk();
    } else {
      handleCancel();
    }
    setOpenDialog(false);
  };
  const disabledAll = useDisabledAll();

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      aria-describedby='confirmation-dialog-description'
      fullScreen={fullScreen}
      sx={sx}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {title && title.length > 0 && (
        <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
        {moreButtons &&
          (Array.isArray(moreButtons) ? moreButtons.map((elem) => elem) : moreButtons)}
        <Button
          onClick={() => handleAction(true)}
          autoFocus
          disabled={disabledAll}
        >
          {textForOk}
        </Button>
        <Button onClick={() => handleAction(false)}>{textForCancel}</Button>
      </DialogActions>
    </Dialog>
  );
};
