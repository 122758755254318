import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import {
  SupplyBillBrowseDto,
  SupplyNetworkElement,
  SupplyNetworkElementItem,
  SupplyPU,
  SupplyPUItem,
  SupplyStamp,
} from '@/dto/taskmap/Dto';
import { useMemo } from 'react';
import { SupplyConstant } from '../SupplyConstant';
import useSupplyStore from '../SupplyStore';
import * as XLSX from 'xlsx';
import { useAccess, useDisabledAccess } from '@/hooks/useAccess';
import { AccessEnum, RolesEnum } from '@/types/roles';

interface PuErrors {
  dup: NestedMapObj;
  exist?: NestedMapObj;
  noYodaTask?: NestedMapObj;
}
export interface LocalStoragePUs {
  [id: string]: {
    pu?: SupplyPU[];
    stamps?: SupplyStamp[];
    puErrors?: PuErrors;
    os?: SupplyNetworkElement[];
    osErrors?: PuErrors;
  };
}

export type PU_SCAN_FIELD = 'number' | 'mac';
export const LS_KEY_STOREHOUSE = 'storehouse';
export const STOREHOUSE_LS_EXPIRATION_DURATION = 1000 * 60 * 60 * 2; //Сброс LS склада каждые 2 часа бездействия
export const STOREHOUSE_LS_VERSION = 4;

export type LS_PU_TYPE =
  | 'archive'
  | 'return'
  | 'deliver'
  | 'factory_get'
  | 'relocation_post'
  | 'relocation_get'
  | 'factory_post';

export const useStorehouseLS = () => {
  const getValue = (): LocalStoragePUs => {
    const item = window.localStorage.getItem(LS_KEY_STOREHOUSE);
    if (item) {
      const parsedItem = JSON.parse(item);
      const now = Date.now();
      if (parsedItem?.version !== STOREHOUSE_LS_VERSION) {
        window.localStorage.removeItem(LS_KEY_STOREHOUSE);
        return {};
      }
      if (now > (parsedItem?.expires ?? now - 1)) {
        window.localStorage.removeItem(LS_KEY_STOREHOUSE);
        return {};
      }

      return parsedItem;
    } else {
      return {};
    }
  };

  const setValue = (value: any, lsKey: any) => {
    const previousValue = getValue();

    const item = JSON.stringify({
      ...previousValue,
      ...{
        [lsKey]: {
          ...previousValue[lsKey],
          ...value,
        },
      },
      expires: Date.now() + STOREHOUSE_LS_EXPIRATION_DURATION,
      version: STOREHOUSE_LS_VERSION,
    });
    window.localStorage.setItem(LS_KEY_STOREHOUSE, item);
  };

  const deleteValue = (lsKey: any) => {
    const previousValue = getValue();
    delete previousValue[lsKey];

    const item = JSON.stringify({
      ...previousValue,

      expires: Date.now() + STOREHOUSE_LS_EXPIRATION_DURATION,
      version: STOREHOUSE_LS_VERSION,
    });
    window.localStorage.setItem(LS_KEY_STOREHOUSE, item);
  };

  return { getValue, setValue, deleteValue };
};

/**
 * @returns true для работников склада и false для
 * SupplyInfo(Руководителей проекта/составителей накладных возврата)
 */
export const useStorehouseWorkerAccess = (): boolean | undefined => {
  return useAccess({
    access: [AccessEnum.Storehouse],
    stopRedirect: true,
  });
};

export const useStorehouseChiefAccess = (): boolean | undefined => {
  return useAccess({
    access: [AccessEnum.StorehouseChief],
    stopRedirect: true,
  });
};

export const useAdminAccess = (): boolean | undefined => {
  return useAccess({
    access: [AccessEnum.Admin],
    stopRedirect: true,
  });
};

export const useDisabledStatisticButton = () => {
  return useDisabledAccess({
    disabledRoles: [RolesEnum.SupplyInfo, RolesEnum.StorehouseContractor],
  });
};

export const getSavePuListId = (type: LS_PU_TYPE, data?: SupplyBillBrowseDto) => {
  if (type === 'archive') {
    return data ? data?.id?.toString() ?? 'archive_current' : 'archive_current';
  }
  return type;
};

export const generateScanId = (pu: SupplyPU, puList: SupplyPUItem[]) => {
  let maxId = 0;
  if (puList.length > 0) {
    maxId = Math.max(...puList.map((o) => o.tmpId));
  } else if (pu.puItems.length > 0) {
    maxId = Math.max(...pu.puItems.map((o) => o.tmpId));
  }
  return maxId + 1;
};

export const generateRowNum = (pu: SupplyPU, puList: SupplyPUItem[]) => {
  let maxRowNum = 0;
  if (puList.length > 0) {
    maxRowNum = Math.max(...puList.map((o) => o.rowNum));
  } else if (pu.puItems.length > 0) {
    maxRowNum = Math.max(...pu.puItems.map((o) => o.rowNum));
  }
  return maxRowNum + 1;
};

export const isNumberExist = (data: SupplyPUItem, puList: SupplyPUItem[]) => {
  const mac = data?.mac;
  const number = data?.number;
  if (mac && mac.length > 0) {
    return puList.filter((it) => it.mac === mac && it.mac !== ',');
  } else if (number && number.length > 0) {
    return puList.filter((it) => it.number === number && it.number !== ',');
  } else {
    return [];
  }
};

export const generateNextId = (
  ids: SupplyPUItem[] | SupplyPU[] | SupplyNetworkElement[] | SupplyNetworkElementItem[]
) => {
  let maxId = 0;
  if (ids?.length > 0) {
    maxId = Math.max(...ids.map((o) => o.tmpId));
  }
  return maxId + 1;
};

type UpdateRowNumsOverload = {
  (param: SupplyPUItem[]): SupplyPUItem[];
  (param: SupplyNetworkElementItem[]): SupplyNetworkElementItem[];
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const updateRowNums: UpdateRowNumsOverload = (
  itemsList: SupplyPUItem[] | SupplyNetworkElementItem[]
) => {
  let i = 1;
  itemsList.forEach((it) => {
    it.rowNum = i;
    i++;
  });
  return itemsList;
};

export const convertIntoFilter = (data: unknown) => {
  return Array.isArray(data)
    ? data?.filter((el) => el.name && el.id)?.map((gr) => ({ value: gr.id, label: gr.name }))
    : [];
};

export const convertIntoMap = (data: unknown) => {
  return Array.isArray(data) ? new Map(data?.map((item) => [item.id, item.name])) : new Map();
};

export const convertMapIntoFilter = (data: unknown) => {
  return Object.entries(data as { [key: string]: string })?.map((entry) => ({
    value: entry[0],
    label: entry[1],
  }));
};

export const PuList_mock_TEST = (amountOfPus: number) => {
  const PuList_mock_TEST_Items = [...Array(amountOfPus).keys()].map((i) => ({
    tmpId: i + 1,
    id: null,
    rowNum: i + 1,

    meterTypeId: 1,
    meterTypeName: 'Нартис',
    number: (i + 1).toString(),
    mac: '',
  }));

  return [
    {
      tmpId: 1,

      meterTypeId: 1,
      meterTypeName: 'Нартис',
      puItems: PuList_mock_TEST_Items,
      declaredCount: '',
      puNumRegexExpr: '',
    },
  ];
};

export type osDupErrorMap = Map<
  // puList tmpId
  number,
  // puListItem tmpId
  Map<number, boolean>
>;

export type PUExistErrorMap = Map<
  // puList tmpId
  number,
  Map<
    // puListItem tmpId
    number,
    {
      billName: string;
      item: SupplyPUItem;
    }
  >
>;

export type osNoYodaTaskErrorMap = Map<
  // puList tmpId
  number,
  // puListItem tmpId
  Map<number, boolean>
>;

export type OSExistErrorMap = Map<
  number,
  Map<
    number,
    {
      billName: string;
      item: SupplyNetworkElementItem;
    }
  >
>;

export const getPUsFromErrorMaps = (
  osDupFieldError: osDupErrorMap,
  osExistsFieldError: PUExistErrorMap,
  noYodaTaskFieldError: osNoYodaTaskErrorMap,
  puListItems: SupplyPUItem[],
  selectedPUId: number | undefined
) => {
  if (!selectedPUId) return;
  const errorIds: number[] = [];
  const puToRemoveIds: number[] = [];
  const resultDupMap: Map<string, SupplyPUItem[]> = new Map();
  const resultDupStrings = [];
  const resultList = puListItems.filter(
    (it) => (it.mac && it.mac.length > 0) || (it.number && it.number.length > 0)
  );

  const selectedPuDupErrors = osDupFieldError.get(selectedPUId);
  if (selectedPuDupErrors) {
    const osDupFieldErrorIds = [...selectedPuDupErrors]
      ?.filter((puError) => puError[1] === true)
      ?.map((puError) => puError[0]);

    osDupFieldErrorIds.forEach((osDupFieldErrorId) => {
      const errorPu = resultList?.find((pu) => pu?.tmpId === osDupFieldErrorId);
      if (errorPu) {
        if (errorPu.mac) {
          const dup = resultDupMap.get(errorPu.mac);
          if (dup) {
            resultDupMap.set(errorPu.mac, [...dup, errorPu]);
          } else {
            resultDupMap.set(errorPu.mac, [errorPu]);
          }
        }
        if (errorPu.number) {
          const dup = resultDupMap.get(errorPu.number);
          if (dup) {
            resultDupMap.set(errorPu.number, [...dup, errorPu]);
          } else {
            resultDupMap.set(errorPu.number, [errorPu]);
          }
        }
        errorIds.push(errorPu.tmpId);
      }
    });

    for (const [key, value] of resultDupMap) {
      resultDupStrings.push(
        `Номер/МАС "${key}" продублирован в строках: ${value.map((pu) => pu.rowNum).join(',')}`
      );
      puToRemoveIds.push(...value.map((pu) => pu.tmpId).slice(1));
    }
  }

  const resultExistStrings: string[] = [];
  const selectedPuExistErrors = osExistsFieldError.get(selectedPUId);
  if (selectedPuExistErrors) {
    const osExistFieldErrorIds = [...selectedPuExistErrors];

    osExistFieldErrorIds.forEach((osExistFieldErrorId) => {
      const existPu = osExistFieldErrorId[1];
      resultExistStrings.push(
        `Номер/МАС "${existPu?.item?.mac || existPu?.item?.number}" продублирован в накладной: ${
          existPu.billName
        }`
      );
      errorIds.push(existPu.item.tmpId);
      puToRemoveIds.push(existPu.item.tmpId);
    });
  }

  const resultNoYodaTaskStrings: string[] = [];

  const selectedPuNoYodaTaskErrors = noYodaTaskFieldError.get(selectedPUId);
  if (selectedPuNoYodaTaskErrors) {
    const osDupFieldErrorIds = [...selectedPuNoYodaTaskErrors]
      ?.filter((puError) => puError[1] === true)
      ?.map((puError) => puError[0]);

    osDupFieldErrorIds.forEach((osDupFieldErrorId) => {
      const errorPu = resultList?.find((pu) => pu?.tmpId === osDupFieldErrorId);

      if (errorPu) {
        resultNoYodaTaskStrings.push(
          `Номер/МАС "${
            errorPu?.mac || errorPu?.number
          }" дублирован или не соответствует адресу массового возврата в заявках YODA`
        );
        errorIds.push(errorPu.tmpId);
        puToRemoveIds.push(errorPu.tmpId);
      }
    });
  }

  const uniqueErrorIds = [...new Set(errorIds)];

  return {
    dup: resultDupStrings,
    exist: resultExistStrings,
    noYodaTasks: resultNoYodaTaskStrings,
    allIds: uniqueErrorIds,
    removeIds: puToRemoveIds,
  };
};

interface FocusOnCellProps {
  cellRowId: number;
  secondNumber?: boolean;
}

export const focusOnCell = ({ cellRowId, secondNumber }: FocusOnCellProps) => {
  const cellToFocus = document.querySelector(
    `[data-tmpid="${cellRowId.toString()}"]${secondNumber ? '[data-double-number="2"]' : ''}`
  ) as HTMLElement | null;
  cellToFocus?.focus();
};

export const useMeterTypes = (
  selectedMeterTypesMap: Map<number, SelectFilterOptType | null>,
  itemsList: SupplyPU[] | SupplyNetworkElement[],
  meterTypes: SelectFilterOptType[]
) => {
  return useMemo(() => {
    const allSelectedMeterTypeValues: number[] = [];
    const selectedMeterTypes: SelectFilterOptType[] = [];

    for (const [_key, meterType] of selectedMeterTypesMap) {
      meterType && allSelectedMeterTypeValues.push(meterType.value as number);
    }

    meterTypes.forEach((element) => {
      if (allSelectedMeterTypeValues.includes(element.value)) {
        selectedMeterTypes.push(element);
      }
    });

    return { selectedMeterTypes };
  }, [itemsList, meterTypes]);
};

export const NOT_FULL_PU_COMPLETENESS_VALUE = 2;

export const PU_TASK_NUMBER_OPTIONS = [
  { value: '1', label: 'Устанавливался' },
  { value: '2', label: 'Не устанавливался' },
];
export const PU_TASK_NUMBER_INSTALLED_OPTION = PU_TASK_NUMBER_OPTIONS[0];
export const PU_TASK_NUMBER_NOT_INSTALLED_OPTION = PU_TASK_NUMBER_OPTIONS[1];

export const useGeneratedYears = () => {
  const memoizedYears = useMemo(() => {
    const currentYear = new Date(Date.now()).getFullYear();
    const START_YEAR = 1900;
    const years: SelectFilterOptType[] = [];

    for (let year = currentYear; year >= START_YEAR; year--) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  }, []);
  return memoizedYears;
};

export const convertLSErrorObjectToMap = <T>(object: NestedMapObj) => {
  const map = new Map();

  for (const key in object) {
    const puList = object[key];
    const innerMap = new Map();

    for (const puListItems in puList) {
      const existItem = puList[puListItems];
      innerMap.set(Number(puListItems), existItem);
    }
    map.set(Number(key), innerMap);
  }
  return map as unknown as T;
};

export interface NestedMapObj {
  [puListTmpId: string]: {
    [puListItemTmpId: string]: unknown;
  };
}

export const toObject = (map: Map<number, any>): NestedMapObj => {
  if (!(map instanceof Map)) return map;
  return Object.fromEntries(
    Array.from(map.entries(), ([k, v]) => {
      if (v instanceof Map) {
        return [k, toObject(v)];
      } else {
        return [k, v];
      }
    })
  );
};

export const checkErrors = (
  osDupFieldError: osDupErrorMap,
  osExistsFieldError: PUExistErrorMap | OSExistErrorMap,
  noYodaTaskErrorMap: osNoYodaTaskErrorMap,
  selectedPuId?: number
) => {
  if (osExistsFieldError?.get(selectedPuId ?? -1)?.size) {
    return true;
  }
  const dupErrors = osDupFieldError?.get(selectedPuId ?? -1);
  if (dupErrors) {
    const filteredErrors = [...dupErrors].filter((el) => el[1] === true);
    if (filteredErrors.length) {
      return true;
    }
  }

  const noYodaTaskErrors = noYodaTaskErrorMap?.get(selectedPuId ?? -1);
  if (noYodaTaskErrors) {
    const filteredErrors = [...noYodaTaskErrors].filter((el) => el[1] === true);
    if (filteredErrors.length) {
      return true;
    }
  }
  return false;
};

export const useValidReturnBillFields = (
  puListItems: (SupplyPUItem | SupplyNetworkElementItem)[],
  returnBill: boolean
) => {
  if (!returnBill) return true;
  const checkedTaskNumbers = useSupplyStore((state) => state.checkedTaskNumbers);
  const returnNewItemsMode = useSupplyStore((state) => state.returnNewItemsMode);

  const isValidReturnItems = (items: (SupplyPUItem | SupplyNetworkElementItem)[]) => {
    return items.every((item) => {
      if (!item.manufactureYear || !item.ownerId || !item.state || !item.completeness) return false;
      if (
        item?.state?.id !== EquipmentState.VANDALISM &&
        item?.state?.id !== EquipmentState.OLD &&
        item?.state?.id !== EquipmentState.NEW &&
        !item.defectCause
      )
        return false;
      if (
        item?.completeness?.id === SupplyConstant.PU_ITEM_NOT_FULL_COMPLEETNESS &&
        !item.completenessComment
      )
        return false;
      if (
        typeof item?.taskNumber === 'string' &&
        !(checkedTaskNumbers.get(item?.taskNumber) === 'success')
      )
        return false;
      if (!item?.taskNumber && !item?.address?.length) return false;

      return true;
    });
  };

  if (returnNewItemsMode) {
    // Если включен "Массовый возврат", то проверяем только первый элемент
    return isValidReturnItems([puListItems[0]]);
  } else {
    return isValidReturnItems(puListItems);
  }
};

export const MAX_INT_VALUE = 2147483647;

export const generatePuExcelFile = (
  data: SupplyPUItem[],
  puOwnerOptions: any,
  meterName?: string
) => {
  const columns = [
    { field: 'rowNum', headerName: '№ п/п' },
    { field: 'meterTypeName', headerName: 'Тип прибора' },
    { field: 'macNumber', headerName: 'Номер/MAC' },
    { field: 'manufactureYear', headerName: 'Год выпуска' },
    { field: 'completeness', headerName: 'Комплектность' },
    { field: 'completenessComment', headerName: 'Комментарий при неполной комплектности' },
    { field: 'ownerId', headerName: 'Принадлежность' },
    { field: 'state', headerName: 'Состояние' },
    { field: 'defectCause', headerName: 'Брак' },
    { field: 'taskNumber', headerName: '№ Заявки' },
    { field: 'address', headerName: 'Адрес демонтированного ПУ' },
    { field: 'generalComment', headerName: 'Общий комментарий' },
    { field: 'supplyComment', headerName: 'Складской комментарий' },
  ];

  const dataForExcel: (string | number)[][] = [columns.map((column) => column.headerName)];

  data.forEach((row) => {
    dataForExcel.push([
      row.rowNum,
      meterName ?? '',
      row.mac ?? row.number ?? '',
      row.manufactureYear ?? '',
      row.completeness?.name ?? '',
      row.completenessComment ?? '',
      row.ownerId ? puOwnerOptions.find((option: any) => option.value === row?.ownerId).label : '',
      row.state?.name ?? '',
      row.defectCause?.name ?? '',
      row.taskNumber ?? '',
      row.address ?? '',
      row.generalComment ?? '',
      row.supplyComment ?? '',
    ]);
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataForExcel);
  worksheet['!cols'] = [
    { wpx: 50 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 250 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 250 },
    { wpx: 250 },
    { wpx: 250 },
  ]; //ширина колонок

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Лист1');
  XLSX.writeFile(workbook, `${meterName ?? 'ПУ'}.xlsx`);
};

export const replaceNonNums = (value?: string) => value?.replace(/[^0-9]/g, '');
export type TextFieldChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export const DOUBLE_NUMBER_METER_TYPE_SPLITTER = ',';
export const DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE = 'doubleNumber';

export const splitDoubleNumber = (doubleNumber?: string | null) => {
  const splittedNumber = doubleNumber?.split(DOUBLE_NUMBER_METER_TYPE_SPLITTER);
  const splittedNumbers = { number1: '', number2: '' };
  if (splittedNumber) {
    splittedNumbers.number1 = splittedNumber[0];
    splittedNumbers.number2 = splittedNumber[1];
  }
  return splittedNumbers;
};

export enum CommentModalState {
  completeness = 'Комментарий при неполной комплектности',
  adress = 'Адрес демонтированного ПУ',
  general = 'Общий комментарий',
  storehouse = 'Складской комментарий',
}
export type ActiveCommentModalState = CommentModalState | null;
export interface ActiveCommentModal {
  value?: string;
  onChange?: onChangeFunc;
  error?: boolean;
  disableChange?: boolean;
}

export type onChangeFunc = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => void;

export enum SupplyPUItemConfirmState {
  firstNumber = 'firstNumber',
  secondNumber = 'secondNumber',
  bothNumbers = 'bothNumbers',
}

export enum EquipmentState {
  // Новое
  NEW = 1,
  // Брак
  DEFECT = 2,
  // Б/У
  OLD = 3,
  // Сгорел
  BURNED = 4,
  // Вандализм
  VANDALISM = 5,
  // Механические повреждения
  MECHANICAL_DAMAGE = 6,
  // Дубликат
  DUPLICATE = 7,
  // Отремонтированный
  REPAIRED = 8,
}

export const useTaskNumberCheckState = (taskNumberValue?: string | null) => {
  const checkedTaskNumbers = useSupplyStore((state) => state.checkedTaskNumbers);

  const getTaskNumberCheckState = () => {
    if (!!taskNumberValue && taskNumberValue.length > 0) {
      return checkedTaskNumbers.get(taskNumberValue);
    }
  };

  const checkingTaskNumber = getTaskNumberCheckState() === 'checking';
  const errorTaskNumber = getTaskNumberCheckState() === 'error';
  const successTaskNumber = getTaskNumberCheckState() === 'success';

  return { checkingTaskNumber, errorTaskNumber, successTaskNumber };
};

export const FULL_COMPLETENESS_OPTION = { name: 'Полная', id: 1 };
export const FULL_PU_STATE_OPTION = { name: 'Новое', id: 1 };
// Количество столбцов, которые мы прячем в режиме возвратов новых ПУ;
export const EMPTY_RETURN_NEW_ITEM_CELLS = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const isChildType = (label = '') => {
  return !!parseInt(label[0]) || parseInt(label[0]) === 0;
};
