import { GeoPoint } from '@/components/maps/GeoPoint';
import { createStore } from '../utils/createStore';

interface GeoCardStore {
  geoPointInCardList: Set<GeoPoint>;
  addToGeoCard: (geoPoint: GeoPoint) => void;
  removeFromGeoCard: (geoPoint: GeoPoint) => void;
  removeAllFromGeoCard: () => void;
  geoCardIndexOf: (geoPoint: GeoPoint) => number;
  updateGeoCardItem: (geoPoint: GeoPoint) => void;
}

export const useGeoCard = createStore<GeoCardStore>(
  (set: any, get: any) => ({
    geoPointInCardList: new Set(),

    addToGeoCard(geoPoint: GeoPoint) {
      const items = get().geoPointInCardList || new Set();
      const index = get().geoCardIndexOf(geoPoint);
      if (index > -1) {
        items.splice(index, 1);
      } else {
        items.push(geoPoint);
      }
      set({ geoPointInCardList: items });
    },

    removeFromGeoCard(geoPoint: GeoPoint) {
      const items = get().geoPointInCardList;
      const index = get().geoCardIndexOf(geoPoint);
      if (index > -1) {
        items.splice(index, 1);
      }
      set({ geoPointInCardList: items });
    },

    removeAllFromGeoCard() {
      set({ geoPointInCardList: [] });
    },

    geoCardIndexOf(geoPoint: GeoPoint) {
      const items = [...get().geoPointInCardList];
      if (geoPoint) {
        let comparator;
        if (geoPoint.getObject() == null) {
          comparator = (t: GeoPoint) => t.getObject() == null;
        } else {
          comparator = (t: GeoPoint) => t.getObject()?.taskId === geoPoint.getObject()?.taskId;
        }
        return items.findIndex(comparator);
      }
      return -1;
    },

    updateGeoCardItem: (geoPoint: GeoPoint) => {
      const items = get().geoPointInCardList;
      const index = get().geoCardIndexOf(geoPoint);
      if (~index) {
        items[index] = geoPoint;
        set({ geoPointInCardList: items });
      }
    },
  }),
  'Geo card'
);

// useEffect(() => {
//   const unsubscribe = useGeoObjectInCardStore.subscribe((state) => console.log(state));
//   return () => {
//     unsubscribe();
//   };
// }, []);
