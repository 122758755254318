import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import {
  ExtraConnectionNewRequest,
  ExtraConnectionRequest,
  ExtraConnectionResponse,
} from '@/dto/taskmap/task/TaskResultDto';
import {
  ExternalConnectionPopupFormInputWrapper,
  ExternalConnectionPopupFormRow,
  ExternalConnectionPopupFormBorderWrapper,
  ExternalConnectionPopupFormWrapper,
  ExternalConnectionPopupIconButtonFix,
  ExternalConnectionPopupFormButtonsWrapper,
} from './ExternalConnectionPopup.styled';
import AddIcon from '@mui/icons-material/Add';
import CabelItem from './CabelItem';
import { addVruExternalConnection, deleteVruExternalConnection } from '@/services/TaskService';
import { useExternalConnectionPopup } from '@/components/popups/externalConnectionPopup/store';
import { useActionLog } from '@/hooks/ActionLogHook';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useDisabledAll';

interface IConnectionItem {
  externalConnectionItem: ExtraConnectionResponse;
}

interface IExtraConnections {
  [x: string]: ExtraConnectionRequest;
}

const convert = (
  data: ExtraConnectionRequest | ExtraConnectionNewRequest
): ExtraConnectionRequest | ExtraConnectionNewRequest => {
  return {
    ...data,
    ppoCableRequestList: data.ppoCableRequestList?.map((elem) =>
      elem?.id?.includes('new')
        ? { sectionId: elem.sectionId, type: elem.type, linesNumber: elem.linesNumber }
        : elem
    ),
  };
};

const ConnectionItem: FC<IConnectionItem> = ({ externalConnectionItem }: IConnectionItem) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { fetchCatch } = useActionLog();

  const methods = useFormContext();
  const { register, control, setValue } = methods;

  const {
    ppoVruInputId,
    addExtraConnection,
    addExtraConnectionCabel,
    deleteExtraConnection,
    deleteAllExtraConnectionCabel,
  } = useExternalConnectionPopup(
    ({
      ppoVruInputId,
      addExtraConnection,
      addExtraConnectionCabel,
      deleteExtraConnection,
      deleteAllExtraConnectionCabel,
    }) => ({
      ppoVruInputId,
      addExtraConnection,
      addExtraConnectionCabel,
      deleteExtraConnection,
      deleteAllExtraConnectionCabel,
    })
  );

  const Wrapper =
    externalConnectionItem.id === 'new'
      ? ExternalConnectionPopupFormWrapper
      : ExternalConnectionPopupFormBorderWrapper;

  const formString = `${externalConnectionItem.id}`;

  const [isLoading, setIsLoading] = useState(false);

  const handleAddCabel = () => {
    addExtraConnectionCabel(externalConnectionItem.id, { id: `new-${new Date()}` });
  };

  const handleAddConnection = (data: IExtraConnections) => {
    setIsLoading(true);
    addVruExternalConnection(ppoVruInputId, convert(data.new))
      .then((r) => {
        addExtraConnection(r.data);
        setValue('new.power', undefined);
        setValue('new.connectionPlace', undefined);
        setValue('new.transferPossible', undefined);
        setValue('new.transferAgreed', undefined);
        deleteAllExtraConnectionCabel('new');
        setValue('new.ppoCableRequestList', undefined);
      })
      .catch((e) => fetchCatch(e, 'Ошибка создания стороннего подключения'))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteConnection = () => {
    setIsLoading(true);
    deleteVruExternalConnection(externalConnectionItem.id)
      .then(() => deleteExtraConnection(externalConnectionItem.id))
      .catch((e) => fetchCatch(e, 'Ошибка удаления стороннего подключения'))
      .finally(() => setIsLoading(false));
  };

  return (
    <Wrapper disabled={isLoading}>
      {externalConnectionItem.id !== 'new' && (
        <ExternalConnectionPopupIconButtonFix
          onClick={() => handleDeleteConnection()}
          disabled={disabledAll || onlyShow}
        >
          <DeleteForeverOutlinedIcon />
        </ExternalConnectionPopupIconButtonFix>
      )}
      <ExternalConnectionPopupFormRow>
        <ExternalConnectionPopupFormInputWrapper width={'630px'}>
          <TextField
            sx={{ width: '100%' }}
            type='number'
            id='count'
            label='Место подключения'
            multiline
            variant='standard'
            defaultValue={externalConnectionItem?.connectionPlace}
            {...register(`${formString}.connectionPlace`, {
              value: externalConnectionItem?.connectionPlace,
            })}
          />
        </ExternalConnectionPopupFormInputWrapper>
      </ExternalConnectionPopupFormRow>
      <ExternalConnectionPopupFormRow>
        <ExternalConnectionPopupFormInputWrapper width={'150px'}>
          <TextField
            sx={{ width: '100%' }}
            type='number'
            id='count'
            label='Мощность кВ'
            variant='standard'
            defaultValue={externalConnectionItem?.power}
            {...register(`${formString}.power`, {
              value: externalConnectionItem?.power,
            })}
          />
        </ExternalConnectionPopupFormInputWrapper>
        <ExternalConnectionPopupFormInputWrapper width={'200px'}>
          <FormControlLabel
            control={
              <Controller
                defaultValue={externalConnectionItem?.transferPossible}
                name={`${formString}.transferPossible`}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={'Перенос возможен'}
          />
        </ExternalConnectionPopupFormInputWrapper>
        <ExternalConnectionPopupFormInputWrapper width={'250px'}>
          <FormControlLabel
            control={
              <Controller
                defaultValue={externalConnectionItem?.transferAgreed}
                name={`${formString}.transferAgreed`}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={'Перенос согласован с УК'}
          />
        </ExternalConnectionPopupFormInputWrapper>
      </ExternalConnectionPopupFormRow>
      <Typography variant={'h6'}>Кабель</Typography>
      {externalConnectionItem?.ppoCableResponseList?.map((cabelItem, index) => (
        <CabelItem
          key={cabelItem.id}
          cabel={cabelItem}
          externalConnectionId={externalConnectionItem.id}
          index={index}
        />
      ))}
      <Grid>
        <Button
          onClick={handleAddCabel}
          startIcon={<AddIcon />}
          disabled={disabledAll || onlyShow}
        >
          Добавить кабель
        </Button>
      </Grid>
      {externalConnectionItem.id === 'new' && (
        <Grid justifyContent={'center'}>
          <ExternalConnectionPopupFormButtonsWrapper>
            <Button
              onClick={methods.handleSubmit(handleAddConnection)}
              disabled={disabledAll || onlyShow}
            >
              создать ЗАПИСЬ
            </Button>
          </ExternalConnectionPopupFormButtonsWrapper>
        </Grid>
      )}
    </Wrapper>
  );
};

export default ConnectionItem;
