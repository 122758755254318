import React from 'react';
import { YungaBrows } from '@/components/sections/Yunga/YungaBrowse';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const YungaPage = () => {
  useAccess({
    access: [AccessEnum.YUNGA],
  });
  useMetaTitle(`Юнга`);
  return <YungaBrows />;
};

export default YungaPage;
