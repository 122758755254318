import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, Tab } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '@/components/ui/Loading';
import {
  ReplacementRequest,
  InstallResultResponse,
  TaskResultType,
  TechInspectionResponse,
  ReplacementResponseList,
} from '@/dto/taskmap/task/TaskResultDto';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { findAllResultByTaskIdAndType } from '@/services/TaskService';
import { ReplacementItem } from '../item/ReplacementItem';
import { TechInspectionItem } from '../item/TechInspectionItem';
import { ModeViewerResult } from '@/constants/modeViewer';
import { useGalleryData } from '@/components/Gallery/store';
import { AddTKO } from '../item/tko/AddTKO';
import WarrantyPanel from './WarrantyPanel';
import { getAutomaticBreaker, getWaranty } from '@/services/WarantyService';
import { WarrantyWorkType } from '@/dto/taskmap/Dto';
import Network from '@/components/Network';
import { useNetworkStore } from '@/components/Network/store';
import { History } from '@/components/features/taskEditor/item/History';
import Chronology from '@/components/features/taskEditor/item/chronology';
import TransformerAmperage from '@/components/Transformer/TransformerAmperage';
import { useParams } from 'react-router-dom';
import { sortNetworkData } from '@/components/Network/utils';
import { sortWarrantyResponses } from './utils';

interface ReplacementPanelProps {
  taskResponse: TaskResponse;
  reloadParent: () => void;
  reloadParentFlag: boolean;
}

export const ReplacementPanel = (props: ReplacementPanelProps) => {
  const { taskId } = useParams();
  const { taskResponse } = props;
  const methods = useForm<ReplacementRequest>();
  const methodsWaranty = useForm();
  const [tabPane, setTabPane] = useState('1');
  const { catchError } = useActionLog();
  const [replacementResponses, setReplacementResponses] = useState<ReplacementResponseList[]>([]);
  const [warrantyResponses, setWarrantyResponses] = useState<WarrantyWorkType[]>([]);
  const [techInspectionResponses, setTechInspectionRespons] = useState<TechInspectionResponse[]>(
    []
  );
  const [loadingReplacement, setLoadingReplacement] = useState(false);
  const [loadingTechInspection, setLoadingTechInspection] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const clearImageGroups = useGalleryData(({ clearImageGroups }) => clearImageGroups);

  const { networkData, getNetworkData } = useNetworkStore((store) => ({
    networkData: store.networkDataN,
    getNetworkData: store.getNetworkData,
  }));

  const [optionsAutomaticBreaker, setOptionsAutomaticBreaker] = useState<Map<string, string>>();
  useEffect(() => {
    const contractId = taskResponse?.contractResponse?.id;
    contractId &&
      warrantyResponses &&
      getAutomaticBreaker(contractId)
        .then(({ data }) => {
          setOptionsAutomaticBreaker(
            new Map(data.map((elem) => [elem.id, elem.automaticBreakerName]))
          );
        })
        .catch((err) => catchError('Значения для автоматического выключателя не загружены', err));
  }, [taskResponse]);

  const inverseReloadFlag = () => {
    setReloadFlag(!reloadFlag);
  };

  const findRes = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      setLoadingReplacement(true);
      findAllResultByTaskIdAndType(taskId, TaskResultType.REPLACEMENT)
        .then((res) => {
          setReplacementResponses(res.data as ReplacementResponseList[]);
          inverseReloadFlag();
          findWarranty();
        })
        .catch((err) => catchError('Ошибка при загрузке информации о замене', err))
        .finally(() => setLoadingReplacement(false));
    }
  };
  const findWarranty = () => {
    const taskId = taskResponse?.id;
    getWaranty(taskId)
      .then(({ data }) => {
        setWarrantyResponses(data);
      })
      .catch((err) => catchError(err?.message ?? 'Ошибка при загрузке информации о гарантии', err));
  };

  const loadTechInspection = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      setLoadingTechInspection(true);
      findAllResultByTaskIdAndType(taskId, TaskResultType.TKO)
        .then((res) => setTechInspectionRespons(res.data as TechInspectionResponse[]))
        .catch((err) => catchError('Ошибка при загрузке информации о ТКО', err))
        .finally(() => setLoadingTechInspection(false));
    }
  };

  useEffect(() => {
    findRes();
    const taskId = taskResponse?.id;
    taskId && getNetworkData(taskId);
    loadTechInspection();
  }, [taskResponse, props.reloadParentFlag]);

  // TODO: загрузка всех изображений галереи
  // useEffect(() => {
  //   updateImages();
  // }, []);
  //
  // useEffect(() => {
  //   updateImages();
  // }, [reloadFlag]);
  //
  // const updateImages = () => {
  //   setImageGroups([
  //     ...getReplacemenListImageGroup(replacementResponses),
  //     ...getSMRTkoListImageGroup(techInspectionResponses),
  //   ]);
  // };

  const handleChangeTabPane = (event: React.SyntheticEvent, newTab: string) => {
    setTabPane(newTab);
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    clearImageGroups();
  };

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        paddingBottom: 2,
      }}
    >
      <TabContext value={tabPane}>
        <Box>
          <TabList
            onChange={handleChangeTabPane}
            centered
            aria-label='result task tabs'
          >
            <Tab
              label='Результат работы'
              value='1'
              onClick={handleClick}
            />
            <Tab
              label='История'
              value='2'
              onClick={handleClick}
            />
            <Tab
              label='Хронология событий'
              value='3'
              onClick={handleClick}
            />
          </TabList>
        </Box>
        <TabPanel
          value='1'
          sx={{ padding: '17px 0 0 0' }}
        >
          <Grid
            container
            spacing={2}
          >
            <Loading loading={loadingReplacement}>
              {replacementResponses &&
                [...replacementResponses]
                  .sort((a, b) => {
                    if (a.replacementState === 'OK' && b.replacementState === 'OK') return 0;
                    if (a.replacementState !== 'OK' && b.replacementState === 'OK') return 1;
                    if (a.replacementState === 'OK' && b.replacementState !== 'OK') return -1;
                    return -1;
                  })
                  .map((t, index) => (
                    <Grid
                      key={t.id}
                      item
                      xs={12}
                    >
                      <FormProvider {...methods}>
                        <ReplacementItem
                          updateReplacementResponse={findRes}
                          ids={{ ...taskResponse }}
                          replacementResponse={t}
                          // transformerInfoResponse={replacementResponses.transformerInfoResponse}
                          mode={index === 0 ? ModeViewerResult.LARGE : undefined}
                        />
                      </FormProvider>
                    </Grid>
                  ))}
              {networkData && (
                <Grid
                  item
                  xs={12}
                >
                  <FormProvider {...methods}>
                    {sortNetworkData(networkData).map((elem, index) => (
                      <Grid
                        key={elem}
                        item
                        xs={12}
                      >
                        <Network
                          id={elem}
                          mode={index ? ModeViewerResult.SMALL : ModeViewerResult.LARGE}
                        />
                        {Divider(!!Object.keys(networkData).length)}
                      </Grid>
                    ))}
                  </FormProvider>
                </Grid>
              )}
              {taskId && (
                <Grid
                  item
                  xs={12}
                >
                  <TransformerAmperage
                    id={taskId}
                    type={'task'}
                  />
                </Grid>
              )}
              {Divider(warrantyResponses.length > 0)}
              {warrantyResponses &&
                [...warrantyResponses]
                  .sort(sortWarrantyResponses)
                  .map((warranty: WarrantyWorkType) => (
                    <Grid
                      key={warranty.id}
                      item
                      xs={12}
                    >
                      <FormProvider {...methodsWaranty}>
                        <WarrantyPanel
                          ids={{ ...taskResponse, contractId: taskResponse?.contractResponse?.id }}
                          warrantyResponse={warranty}
                          updateWarranty={findWarranty}
                          optionsAB={optionsAutomaticBreaker}
                        />
                      </FormProvider>
                    </Grid>
                  ))}

              {Divider(techInspectionResponses.length > 0)}
              {techInspectionResponses &&
                [...techInspectionResponses]
                  .sort(
                    (a: TechInspectionResponse, b: TechInspectionResponse) =>
                      (b.eventOn as unknown as number) - (a.eventOn as unknown as number)
                  )
                  .map((t) => (
                    <Grid
                      key={t.id}
                      item
                      xs={8}
                    >
                      <TechInspectionItem
                        response={t}
                        update={loadTechInspection}
                        contractId={taskResponse?.contractResponse?.id}
                      />
                    </Grid>
                  ))}
            </Loading>
          </Grid>
          <AddTKO
            contractId={taskResponse?.contractResponse?.id}
            afterAdd={loadTechInspection}
            taskId={taskResponse?.id}
          />
        </TabPanel>
        <TabPanel
          value='2'
          sx={{ padding: '17px 0 0 0' }}
        >
          <History />
        </TabPanel>
        <TabPanel
          value='3'
          sx={{ padding: '17px 0 0 0' }}
        >
          <Chronology />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const Divider = (isDivide: boolean) => {
  if (isDivide) return <div style={{ width: '100%', marginBottom: 24 }}></div>;
  return null;
};
