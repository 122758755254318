import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Stack, Tab } from '@mui/material';
import { useEffect, useState, MouseEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '@/components/ui/Loading';
import {
  PpoHouseResponse,
  PpoResponse,
  PpoVruTaskResponse,
  ReplacementRequest,
  TaskResultType,
  TechInspectionResponse,
  VruTaskResponse,
} from '@/dto/taskmap/task/TaskResultDto';
import { OdpuInfoResponse, TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import {
  createODPUHouseInfoApi,
  findAllResultByTaskIdAndType,
  findODPUHouseInfo,
  savePpoOdpu,
  updateODPUHouseInfoApi,
} from '@/services/TaskService';
import { PpoVruItem } from '../item/PpoVruItem';
import { TechInspectionItem } from '../item/TechInspectionItem';
import { VruTaskItem } from '../item/VruTaskItem';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { PpoHouseInput } from '../item/PpoHouseInput';
import SaveIcon from '@mui/icons-material/Save';
import { useCablePopup } from '@/components/popups/cablePopup/store';
import { LockerPopup } from '@/components/popups/lockerPopup/LockerPopup';
import { useGalleryData } from '@/components/Gallery/store';
import { AddTKO } from '../item/tko/AddTKO';
import { getPpoHouseInputResImageGroup, getPpoVruInputResImageGroup } from './utils';
import { CablePopup } from '@/components/popups/cablePopup/CablePopup';
import { ExpendableToolsPopup } from '@/components/popups/expendableToolsPopup/ExpendableToolsPopup';
import { useExpendableToolsPopup } from '@/components/popups/expendableToolsPopup/store';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { getAutomaticBreaker } from '@/services/WarantyService';
import { ExternalConnectionPopup } from '@/components/popups/externalConnectionPopup/ExternalConnectionPopup';
import { useDisabledAll } from '@/hooks/useDisabledAll';
import { useTaskEditorOnlyShow } from '../useTaskEditorOnlyShow';
import { History } from '@/components/features/taskEditor/item/History';
import { useDisabledAccess } from '@/hooks/useAccess';
import { RolesEnum } from '@/types/roles';

interface OdpuPanelProps {
  taskResponse: TaskResponse;
  reloadParent?: () => void;
  reloadParentFlag?: boolean;
}

export const OdpuPanel = (props: OdpuPanelProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { taskResponse, reloadParentFlag } = props;
  const { setIsInProgress } = useScreenHoldHook();
  const methodsOdpu = useForm<ReplacementRequest>();
  const methods = useForm();
  const ppoReloadCounter = useCablePopup((state) => state.reloadCounter);
  const { reloadCounter: expendableToolReloadCounter } = useExpendableToolsPopup(
    ({ reloadCounter }) => ({
      reloadCounter,
    })
  );

  const isPPOMainTabGroup = useDisabledAccess({
    disabledRoles: [RolesEnum.PPOODPUVisitor],
  });

  const [tabPane, setTabPane] = useState(isPPOMainTabGroup ? '1' : '2');
  const [vruTaskResponseList, setVruTaskResponseList] = useState<VruTaskResponse[]>();
  const [ppoResponse, setPpoResponse] = useState<PpoVruTaskResponse[]>();
  const [techInspectionResponses, setTechInspectionRespons] = useState<TechInspectionResponse[]>();
  const { catchError, addActionLog } = useActionLog();
  const [loadingOdpu, setLoadingOdpu] = useState(false);
  const [loadingTko, setLoadingTko] = useState(false);
  const [loadingPpo, setLoadingPpo] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [houseInfo, setHouseInfo] = useState<PpoHouseResponse>();

  const clearImageGroups = useGalleryData(({ clearImageGroups }) => clearImageGroups);
  const setTaskType = useGalleryData(({ setTaskType }) => setTaskType);

  const inverseReloadFlag = () => {
    setReloadFlag(!reloadFlag);
  };

  const loadTko = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      setLoadingTko(true);
      findAllResultByTaskIdAndType(taskId, TaskResultType.TKO)
        .then((res) => setTechInspectionRespons(res.data))
        .catch((err) => catchError('Ошибка при загрузке информации о ТКО', err))
        .finally(() => setLoadingTko(false));
    }
  };
  const loadODPU = () => {
    if (taskResponse) {
      setLoadingOdpu(true);
      findAllResultByTaskIdAndType(taskResponse.id, TaskResultType.ODPU)
        .then((res) => {
          setVruTaskResponseList(res.data);
          inverseReloadFlag();
        })
        .catch((err) => catchError('Ошибка загрузки результата ОДПУ', err))
        .finally(() => setLoadingOdpu(false));
    }
  };
  const loadPPO = () => {
    if (taskResponse) {
      setLoadingPpo(true);
      findAllResultByTaskIdAndType(taskResponse.id, TaskResultType.PPO)
        .then((res) => setPpoResponse(res.data))
        .catch((err) => catchError('Ошибка при загрузке информации о ППО', err))
        .finally(() => setLoadingPpo(false));
    }
  };
  const loadPPOHouseInfo = () => {
    if (taskResponse) {
      setLoadingPpo(true);
      findODPUHouseInfo(taskResponse.id)
        .then((res) => {
          if (res.data) {
            setHouseInfo(res.data);
          }
        })
        .catch((err) => catchError('Ошибка при загрузке информации о Доме', err))
        .finally(() => setLoadingPpo(false));
    }
  };

  useEffect(() => {
    loadODPU();
    loadPPO();
    loadTko();
    loadPPOHouseInfo();
  }, [taskResponse, reloadParentFlag, expendableToolReloadCounter]);

  useEffect(() => {
    if (ppoReloadCounter > 0) loadPPO();
  }, [ppoReloadCounter]);

  const handleChangeTabPane = (event: React.SyntheticEvent, newTab: string) => {
    setTabPane(newTab);
  };
  useEffect(() => {
    const tmp: OdpuInfoResponse | undefined = taskResponse.taskInfo;
    const stage = tmp?.odpuStage;
    if (stage === 'DESIGNING' || stage === 'ON_APPROVAL' || stage === 'PPO') {
      setTabPane('1');
    }
  }, []);

  const onSave = (data: any) => {
    setIsInProgress(true);
    savePpoOdpu(taskResponse.id, data.vruRequestList)
      .then((res) => {
        setPpoResponse(res.data);
        addActionLog(ActionLogType.SUCCESS, 'Изменения сохранены');
      })
      .catch((err) => {
        catchError(`Ошибка сохранения ППО. ${err?.response?.data?.message ?? ''}`, err);
      })
      .finally(() => setIsInProgress(false));
  };

  const onSaveOrEditHouse = (data: any) => {
    setIsInProgress(true);
    if (houseInfo) {
      updateODPUHouseInfoApi(taskResponse.id, data.houseRequest)
        .then((res) => {
          loadPPOHouseInfo();
          addActionLog(ActionLogType.SUCCESS, 'Изменения сохранены');
        })
        .catch((err) => {
          catchError(
            `Ошибка сохранения характеристики дома. ${err?.response?.data?.message ?? ''}`,
            err
          );
        })
        .finally(() => setIsInProgress(false));
    } else {
      createODPUHouseInfoApi(taskResponse.id, data.houseRequest)
        .then((data) => {
          loadPPOHouseInfo();
        })
        .catch((err) => {
          catchError(
            `Ошибка сохранения характеристики дома. ${err?.response?.data?.message ?? ''}`,
            err
          );
        })
        .finally(() => setIsInProgress(false));
    }
  };
  useEffect(() => {
    taskResponse?.type && setTaskType(taskResponse.type);
  }, [reloadFlag]);
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);

  // TODO: загрузка всех изображений галереи
  // const updateImages = () => {
  //   setImageGroups([
  //     ...getSMRTaskListImageGroup(vruTaskResponseList),
  //     ...getSMRTkoListImageGroup(techInspectionResponses),
  //   ]);
  // };

  const handleClick = (e: any) => {
    e.stopPropagation();
    clearImageGroups();
  };
  const handleDoubleClickHouse = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    let obj: Partial<PpoResponse> = {};

    if (houseInfo && ppoResponse) {
      obj = {
        houseInfo,
        ppoResponse,
      };
    }

    if (obj.houseInfo && obj.ppoResponse) {
      setImageGroups(getPpoHouseInputResImageGroup(obj as PpoResponse)); // Type assertion
    }
  };

  const handleDoubleClickEntrence = (e: MouseEvent<HTMLElement>, item: PpoVruTaskResponse) => {
    e.stopPropagation();
    setImageGroups(getPpoVruInputResImageGroup(item));
  };

  const [optionsAutomaticBreaker, setOptionsAutomaticBreaker] = useState<Map<string, string>>();
  useEffect(() => {
    const contractId = taskResponse?.contractResponse?.id;
    contractId &&
      getAutomaticBreaker(contractId)
        .then(({ data }) => {
          setOptionsAutomaticBreaker(
            new Map(data.map((elem) => [elem.id, elem.automaticBreakerName]))
          );
        })
        .catch((err) => catchError('Значения для автоматического выключателя не загружены', err));
  }, [taskResponse]);

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        paddingBottom: 2,
      }}
    >
      <TabContext value={tabPane}>
        <Box>
          <TabList
            onChange={handleChangeTabPane}
            centered
            aria-label='result odpu tabs'
          >
            <Tab
              label='ППО ОДПУ'
              value='1'
              onClick={handleClick}
            />
            <Tab
              label='СМР ОДПУ'
              value='2'
              onClick={handleClick}
            />
            <Tab
              label='История'
              value='3'
              onClick={handleClick}
            />
          </TabList>
        </Box>
        <TabPanel
          value='1'
          sx={{ padding: '17px 0 0 0' }}
        >
          <CablePopup />
          <ExpendableToolsPopup />
          <ExternalConnectionPopup />
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <BoxShadow onDoubleClick={handleDoubleClickHouse}>
                <FormProvider {...methods}>
                  <Grid
                    item
                    container
                    spacing={2}
                  >
                    {ppoResponse && (
                      <PpoHouseInput
                        {...ppoResponse}
                        houseInfo={houseInfo}
                        onSave={onSaveOrEditHouse}
                      />
                    )}
                    <Loading loading={loadingPpo}>
                      {ppoResponse &&
                        [...ppoResponse].map((t, i) => (
                          <Grid
                            onDoubleClick={(e) => handleDoubleClickEntrence(e, t)}
                            key={t.id}
                            item
                            container
                          >
                            <PpoVruItem
                              task={taskResponse}
                              taskId={taskResponse.id}
                              index={i}
                              vru={t}
                              loadAll={loadPPO}
                            />
                          </Grid>
                        ))}
                    </Loading>
                    <LockerPopup
                      setPpoResponse={setPpoResponse}
                      taskId={taskResponse.id}
                    />
                  </Grid>
                </FormProvider>
                <Stack
                  flexDirection={'row'}
                  justifyContent={'end'}
                >
                  {!disabledAll && !onlyShow && (
                    <SaveIcon
                      onClick={methods?.handleSubmit(onSave)}
                      sx={{
                        color: 'gray',
                        fontSize: 22,
                        cursor: 'pointer',
                        mt: 2,
                      }}
                    />
                  )}
                </Stack>
              </BoxShadow>
            </Grid>
            <Loading loading={loadingTko}>
              {techInspectionResponses &&
                [...techInspectionResponses].map((t) => (
                  <Grid
                    key={t.id}
                    item
                    xs={8}
                  >
                    <TechInspectionItem
                      response={t}
                      update={loadTko}
                      contractId={taskResponse?.contractResponse?.id}
                    />
                  </Grid>
                ))}
            </Loading>
          </Grid>
        </TabPanel>
        <TabPanel
          value='2'
          sx={{ padding: '17px 0 0 0' }}
        >
          <Grid
            container
            spacing={2}
          >
            <Loading loading={loadingOdpu}>
              {vruTaskResponseList &&
                [...vruTaskResponseList].map((t) => (
                  <Grid
                    key={t.id}
                    item
                    container
                  >
                    <FormProvider {...methodsOdpu}>
                      <VruTaskItem
                        response={t}
                        updateRes={setVruTaskResponseList}
                        ids={{
                          vruId: t.id,
                          taskId: taskResponse.id,
                          contractId: taskResponse.contractResponse?.id,
                        }}
                        updateReplacementResponse={loadODPU}
                        optionsAutomaticBreaker={optionsAutomaticBreaker}
                      />
                    </FormProvider>
                  </Grid>
                ))}
            </Loading>
            <Loading loading={loadingTko}>
              {techInspectionResponses &&
                [...techInspectionResponses].map((t) => (
                  <Grid
                    key={t.id}
                    item
                    xs={8}
                  >
                    <TechInspectionItem
                      response={t}
                      update={loadTko}
                      contractId={taskResponse?.contractResponse?.id}
                    />
                  </Grid>
                ))}
            </Loading>
          </Grid>
          {!disabledAll && !onlyShow && (
            <AddTKO
              contractId={taskResponse?.contractResponse?.id}
              afterAdd={loadTko}
              taskId={taskResponse?.id}
            />
          )}
        </TabPanel>
        <TabPanel value={'3'}>
          <History />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
