import {
  DeliveryPointResponse,
  IImage,
  MeterResponse,
  PpoResponse,
  PpoTransformerResponseList,
  PpoVruInputResponse,
  PpoVruTaskResponse,
  ReplacementResponseList,
  TechInspectionResponse,
  TransformerResponse,
  TransStationFeederResponse,
  TransStationInputResponse,
  TransStationResponse,
  VruInputResponse,
  VruTaskResponse,
} from '@/dto/taskmap/task/TaskResultDto';
import { toFormateDate } from '@/utils/heplers';
import { IImageGroup } from '@/components/Gallery/store';
import { TaskTransformator, WarantyStatus, WarrantyWorkType } from '@/dto/taskmap/Dto';
import { ReplacementItemProps } from '../item/ReplacementItem';
import { TransformerAmperage, TransformerListAmperageResponseDto } from '@/dto/TransformerDto';

export function collectUniqueImages(data: any): IImage[] {
  const uniqueImages: IImage[] = [];

  function collectIdsRecursive(obj: any) {
    if (obj instanceof Array) {
      for (const item of obj) {
        collectIdsRecursive(item);
      }
    } else if (obj instanceof Object) {
      for (const key in obj) {
        if (key === 'imageList') {
          obj[key].forEach((image: IImage) => {
            const duplicate = uniqueImages.find((uniqImage) => uniqImage.id === image.id);
            if (!duplicate) uniqueImages.push(image);
          });
        } else {
          collectIdsRecursive(obj[key]);
        }
      }
    }
  }
  collectIdsRecursive(data);
  return uniqueImages;
}

export const filterMap = (
  map: Map<string, string>,
  pred: (key: string, value: string) => boolean
): Map<string, string> => {
  const result = new Map();
  for (const [k, v] of map) {
    if (pred(k, v)) {
      result.set(k, v);
    }
  }
  return result;
};

export const getSMRTransformerListImageGroup = (
  list: TransformerResponse[] | undefined
): IImageGroup[] => {
  return list
    ? list.map((elem) => ({
        title: `ТТ - фаза ${elem.phase}: ${elem.serialNumber}`,
        imageList: elem.imageList || [],
        obj: elem.id,
      }))
    : [];
};

export const getSMRInputListImageGroup = (
  list: ReplacementResponseList[] | undefined
): IImageGroup[] => {
  return list
    ? list
        ?.map((elem) => [
          {
            title: `Замена ${toFormateDate(elem?.replacedOn)}`,
            imageList: elem?.imageList || [],
            obj: elem?.id || '',
          },
        ])
        .flatMap((e) => e)
    : [];
};

export const getSMRVruListImageGroup = (list: VruInputResponse[] | undefined): IImageGroup[] => {
  return list
    ? list
        .map((elem) => [
          {
            title: `Ввод: ${elem.title}`,
            imageList: elem.imageList || [],
            obj: elem.id,
          },
          ...getSMRInputListImageGroup(elem.replacementResponse),
        ])
        .flatMap((e) => e)
    : [];
};

export const getSMRTkoListImageGroup = (
  list: TechInspectionResponse[] | undefined
): IImageGroup[] => {
  return list
    ? list.map((elem) => ({
        title: `ТКО ${toFormateDate(elem.eventOn)}`,
        imageList: elem.imageList || [],
        obj: elem.id,
      }))
    : [];
};

export const getSMRTkoResImageGroup = (res: TechInspectionResponse | undefined): IImageGroup[] => {
  return res
    ? [
        {
          title: `ТКО ${toFormateDate(res.eventOn)}`,
          imageList: res.imageList || [],
          obj: res.id,
        },
      ]
    : [];
};
export const getWarrantyResImageGroup = (res: WarrantyWorkType | undefined): IImageGroup[] => {
  return res
    ? [
        {
          // title: `${res.type} ${toFormateDate(res.workDoneAt)}`,
          title: `Гарантийные работы ${toFormateDate(res.workDoneAt)}`,
          imageList: res.imageList || [],
          obj: res.id,
        },
        ...getWarrantyResTransImageGroup(res.taskTransformator, res.id, res.workDoneAt),
      ]
    : [];
};
export const getWarrantyResTransImageGroup = (
  res: TaskTransformator | undefined | null,
  id: string,
  date?: Date
): IImageGroup[] => {
  return res
    ? [
        {
          title: `Гарантийные работы замена ТТ ${toFormateDate(date)}`,
          imageList: res.imageList || [],
          obj: id,
        },
      ]
    : [];
};

export const getSMRTaskListImageGroup = (list: VruTaskResponse[] | undefined): IImageGroup[] => {
  return list
    ? list.map((elem) => [...getSMRVruListImageGroup(elem.inputResponseList)]).flatMap((e) => e)
    : [];
};

export const getReplacemenListImageGroup = (
  list: ReplacementResponseList[] | undefined
): IImageGroup[] => {
  return list
    ? list.map((elem) => ({
        title: `Замена ${toFormateDate(elem.replacedOn)}`,
        imageList: elem.imageList || [],
        obj: elem.id,
      }))
    : [];
};

export const getReplacemenResImageGroup = (
  res: ReplacementResponseList | undefined
): IImageGroup[] => {
  return res
    ? [
        {
          title: `Замена ${toFormateDate(res.replacedOn)}`,
          imageList: res.imageList || [],
          obj: res.id,
        },
      ]
    : [];
};

export const getPpoHouseInputResImageGroup = (res: PpoResponse | undefined): IImageGroup[] => {
  let result: IImageGroup[] = [];
  if (res) {
    result.push({
      title: `Дом`,
      imageList: res?.houseInfo?.imageList || [],
      obj: res.houseInfo?.id || '',
    });
    res?.ppoResponse?.forEach((el) => {
      result = [...result, ...getPpoVruInputResImageGroup(el)];
    });
  }
  return result;
};

export const getPpoVruInputResImageGroup = (res: PpoVruTaskResponse | undefined): IImageGroup[] => {
  let result: IImageGroup[] = [];
  if (res) {
    result.push({
      title: `${res.installationPlace}`,
      imageList: res?.ppoOdpuVruResponse?.imageList || [],
      obj: res.id,
    });
    res.inputResponseList?.forEach((inputEl) => {
      result = [...result, ...getPpoEnterInputResImageGroup(inputEl)];
    });
  }
  return result;
};

export const getPpoEnterInputResImageGroup = (
  res: PpoVruInputResponse | undefined
): IImageGroup[] => {
  return res
    ? [
        {
          title: `Ввод ${res.inputNumber}`,
          imageList: res?.imageList || [],
          obj: res.id,
        },
        ...getMeterImageGroup(res.meterResponse, res?.inputNumber),
        ...getTransStationImageGroup(res.ppoTransformerResponseList, res?.inputNumber),
      ]
    : [];
};

export const getMeterImageGroup = (
  res: MeterResponse | undefined,
  inputNumber: string | undefined
): IImageGroup[] => {
  return res
    ? [
        {
          title: `Ввод ${inputNumber} ПУ`,
          imageList: res?.imageList || [],
          obj: res.id,
        },
      ]
    : [];
};
export const getTransStationImageGroup = (
  res: PpoTransformerResponseList[] | undefined,
  inputNumber: string | undefined
): IImageGroup[] => {
  return (
    res
      ?.map((elem) =>
        elem
          ? [
              {
                title: `Ввод ${inputNumber} TT - ${elem.phaseName}`,
                imageList: elem?.imageList || [],
                obj: elem.id,
              },
            ]
          : []
      )
      .flatMap((e) => e) || []
  );
};

export const getTransAmperImageGroup = (
  res: TransformerListAmperageResponseDto[] | undefined,
  inputNumber?: string | undefined
): IImageGroup[] => {
  return (
    res
      ?.map((elem) =>
        elem
          ? [
              {
                title: `Ввод ${elem.serialNumber} TT - ${elem.phase}`,
                imageList: collectUniqueImages(elem) || [],
                obj: elem.id,
              },
            ]
          : []
      )
      .flatMap((e) => e) || []
  );
};

export const getTransImageGroup = (res: TransformerAmperage | undefined): IImageGroup[] => {
  return res?.imageList
    ? [
        {
          title: `Трансформатор тока`,
          imageList: collectUniqueImages(res) || [],
          obj: res.mount.phaseList[0].id,
        },
      ].flatMap((e) => e) || []
    : [];
};

export const getTransTPImageGroup = (res: TransStationResponse | undefined): IImageGroup[] => {
  return res
    ? [
        {
          title: `ТП`,
          imageList: res.imageList || [],
          obj: res.id,
        },
        ...getTransTRImageListGroup(res.inputs),
        ...getTransFeederListImageGroup(res.tpFeeders),
      ]
    : [];
};

export const getTransTRImageGroup = (res: TransStationInputResponse | undefined): IImageGroup[] => {
  return res
    ? [
        {
          title: `Трансформатор тока ${res?.name}`,
          imageList: res.imageList || [],
          obj: res.id,
        },
        ...getTransFeederImageGroup(res.tpFeeder),
      ]
    : [];
};

export const getTransTRImageListGroup = (
  res: TransStationInputResponse[] | undefined
): IImageGroup[] => {
  return res ? res.map((elem) => getTransTRImageGroup(elem)).flatMap((e) => e) : [];
};

export const getTransFeederImageGroup = (
  res: TransStationFeederResponse | undefined
): IImageGroup[] => {
  return res
    ? [
        {
          title: `ТП`,
          imageList: res.tpMeter?.imageList
            ? [...res.imageList, ...res.tpMeter.imageList]
            : [...res.imageList] || [],
          obj: res.id,
        },
      ]
    : [];
};

export const getTransFeederListImageGroup = (
  res: TransStationFeederResponse[] | undefined
): IImageGroup[] => {
  return res ? res.map((elem) => getTransFeederImageGroup(elem)).flatMap((e) => e) : [];
};

export const getDeliveryPointImageGroup = (
  res: DeliveryPointResponse | undefined
): IImageGroup[] => {
  return res
    ? [
        {
          title: `Точка поставки`,
          imageList: [...(res?.imageList ?? [])],
          obj: res?.smrTaskId,
        },
      ]
    : [];
};

export const concatImagesFromTransformerInfoResponse = (
  list: ReplacementItemProps
): IImageGroup[] => {
  return list?.replacementResponse
    ? [
        {
          title: `Замена ${toFormateDate(list?.replacementResponse.replacedOn)}`,
          imageList: list?.replacementResponse.imageList || [],
          obj: list?.replacementResponse.id,
        },
        ...getSMRTransformerListImageGroup(list?.transformerInfoResponse?.transformerResponseList),
      ]
    : [];
};

export const sortWarrantyResponses = (a: WarrantyWorkType, b: WarrantyWorkType) => {
  if (a.status === WarantyStatus.CANCELED && b.status !== WarantyStatus.CANCELED) return 1;
  if (a.status !== WarantyStatus.CANCELED && b.status === WarantyStatus.CANCELED) return -1;
  return (b.workDoneAt as unknown as number) - (a.workDoneAt as unknown as number);
};
