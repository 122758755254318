import { Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import {
  MeasurementResponse,
  ReplacementMeterResponse,
  ReplacementResponse,
  StampInstallResponse,
} from '@/dto/taskmap/task/TaskResultDto';
import { YungaAnalyzeMeterResponse } from '@/dto/taskmap/task/YungaDto';
import { YungaAnalyzeTextField } from '@/components/YungaAnalyzeTextField/YungaAnalyzeTextField';
import { ModeViewerResult } from '@/constants/modeViewer';
import { useStatus } from '../panel/store';
import { useCatalog } from '@/hooks/CatalogHook';
import { ChronologyRowOtherDiv } from './chronology/Chronology.styled';
import ChronologyItem from './chronology/ChronologyItem';

export const enum Types {
  OLD = 'old',
  NEW = 'new',
}

interface MeterNewItemProps {
  breaker?: string;
  locker?: string;
  factoryStamp?: string;
  stampInstall: StampInstallResponse;
  measurement?: MeasurementResponse;
  replacement: ReplacementResponse;
  meter: ReplacementMeterResponse;
  analyze?: YungaAnalyzeMeterResponse;
  mode: ModeViewerResult;
  keyString?: string;
  onChangeMainFields?: (isChange: boolean) => void;
}

export const MeterNewItem = (props: MeterNewItemProps) => {
  const { register, setValue } = useFormContext();

  const {
    locker,
    keyString,
    measurement,
    stampInstall,
    breaker,
    factoryStamp,
    onChangeMainFields,
    replacement,
  } = props;

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const [meter, setMeter] = useState<ReplacementMeterResponse>();
  const [analyze, setAnalyze] = useState<YungaAnalyzeMeterResponse>();

  const mainStr = `${keyString}.mounted`;
  const secondStr = `${keyString}.installMeasurement`;

  measurement && setValue(`${secondStr}.id`, measurement.id);

  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));

  useEffect(() => {
    setMeter(props.meter);
  }, [props.meter]);

  useEffect(() => {
    setValue(`mounted.puType`, meter?.puType);
  }, [meter]);

  useEffect(() => {
    setAnalyze(props.analyze);
  }, [props.analyze]);

  useEffect(() => {
    measurement && setValue(`${secondStr}.t1`, measurement.t1);
    measurement && setValue(`${secondStr}.t2`, measurement.t2);
    measurement && setValue(`${secondStr}.t3`, measurement.t3);
  }, [measurement]);

  const checkTariff = (tariff?: string) => {
    if (tariff && tariff.match('^[123]$')) {
      return tariff;
    }
    return undefined;
  };

  const getMarkedType = (val?: string) => {
    if (val === 'MATCH') {
      return 'done';
    }
    if (val === 'NOT_MATCH') {
      return 'cancel';
    }
    if (val === 'UNKNOWN') {
      return 'unknown';
    }
  };

  const mac_imei = `${replacement?.installedPuMac ?? '-'}/${replacement?.installedPuImei ?? '-'}`;

  return (
    <>
      <ChronologyRowOtherDiv>
        <ChronologyItem
          label={'Тип'}
          value={replacement?.installedPuType}
          width={'100%'}
        />
        <ChronologyItem
          label={'№'}
          value={replacement?.installedPuNumber}
          width={'20%'}
        />
        <ChronologyItem
          label={'МАС/IMEI'}
          value={mac_imei}
          width={'80%'}
        />
      </ChronologyRowOtherDiv>
      <Typography
        mb={2}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <span style={{ fontWeight: 'bold' }}>Устанавливаемый ПУ:</span>
        <AutocompleteValue
          sx={{ ml: 2, flex: ' 1 1 auto' }}
          title=''
          defaultValue={meter?.type}
          fieldName={`${mainStr}.type`}
          values={getCatalogMapWithErr('referenceMeterType')}
          disabled={isDisabledAll()}
        />
      </Typography>
      {meter && (
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={6}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.pu)}
              registerName={`${mainStr}.number`}
              value={meter?.number}
              label='Номер'
              disabled={isDisabledAll()}
              isChange={onChangeMainFields}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.mac)}
              registerName={`${mainStr}.mac`}
              value={meter?.mac}
              label='MAC'
              disabled={isDisabledAll()}
              isChange={onChangeMainFields}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteValue
              title='Тариф'
              defaultValue={checkTariff(measurement?.tariff?.toString())}
              fieldName={`${secondStr}.tariff`}
              values={Array.of('1', '2', '3')}
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteValue
              title='Автомат тока'
              defaultValue={breaker}
              fieldName={`${keyString}.automaticBreakerId`}
              values={getCatalogMapWithErr('automaticBreaker')}
              // disabled={isDisabledAll()}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.stamp)}
              registerName={`${keyString}.stampInstallRequest.installNumber`}
              value={stampInstall?.installNumber}
              label='Пломба'
              disabled={isDisabledAll()}
              isChange={onChangeMainFields}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <TextField
              id='tf-factoryStampNumber'
              label='Пломба ККИ'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${keyString}.factoryStamp`, {
                value: factoryStamp,
              })}
              disabled={isDisabledAll()}
            />
          </Grid>

          <Grid
            item
            xs={6}
          >
            <TextField
              id='tf-montage-place'
              label='Место установки'
              variant='standard'
              {...register(`${mainStr}.montagePlace`, { value: meter?.montagePlace })}
              sx={{ width: '100%' }}
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <AutocompleteValue
              title='Шкаф'
              defaultValue={locker ? `${locker}` : locker}
              fieldName={`${keyString}.smrLockerId`}
              values={getCatalogMapWithErr('smrLocker')}
              disabled={isDisabledAll()}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.t1)}
              registerName={`${secondStr}.t1`}
              value={measurement?.t1}
              label='T1'
              disabled={isDisabledAll()}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.t2)}
              registerName={`${secondStr}.t2`}
              value={measurement?.t2}
              label='T2'
              disabled={isDisabledAll()}
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <YungaAnalyzeTextField
              type={getMarkedType(analyze?.t3)}
              registerName={`${secondStr}.t3`}
              value={measurement?.t3}
              label='T3'
              disabled={isDisabledAll()}
            />
          </Grid>

          {/*<Grid*/}
          {/*  item*/}
          {/*  xs={12}*/}
          {/*>*/}
          {/*  <TextField*/}
          {/*    id='tf-descrioption'*/}
          {/*    label='Описание'*/}
          {/*    variant='standard'*/}
          {/*    {...register(`mounted.puType`, {*/}
          {/*      value: meter?.puType,*/}
          {/*    })}*/}
          {/*    sx={{ width: '100%' }}*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
      )}
    </>
  );
};
