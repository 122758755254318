import { Slider } from '@mui/material';
import { useMap } from '../../../../hooks/MapHook';

export const GeoTaskRadar = (props: any) => {
  const { setGeoCircleRadius } = useMap();

  const handleChangeRadius = (event: any) => {
    setGeoCircleRadius(props.geoPoint, event.target.value);
  };

  return (
    <Slider
      aria-label='Temperature'
      defaultValue={0}
      valueLabelDisplay='auto'
      step={50}
      marks
      min={0}
      max={500}
      onChange={handleChangeRadius}
    />
  );
};
