import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { MapPnrSetting } from './MapPnrSetting';
import DeleteIcon from '@mui/icons-material/Delete';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import LoadingButton from '@mui/lab/LoadingButton';
import { GeoCard } from './GeoCard';
import { GeoPoint, GeoPointType, FreeGeoPoint } from '@/components/maps/GeoPoint';
import { CopiedButton } from '@/components/button/CopiedButton';
import { useGeoCard } from '@/hooks/GeoCardHook';
import { useGeoQuery } from '@/hooks/GeoQueryHook';
import { useMap } from '@/hooks/MapHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { usePnr } from '@/hooks/PnrHook';
import { useResArea } from '@/hooks/ResAreaHook';
import { useActionLog } from '@/hooks/ActionLogHook';

export const ViewMapManagerTab = () => {
  const { currentGeoResponse, isClusterMode } = useGeoQuery();
  const { loadPnrTransformerStation } = usePnr();
  const {
    removeAllGeoCircle,
    getMapCenter,
    currentGeoBounds,
    addGeoCircle,
    mapZoom,
    selectedGeoPointList,
    setSelectedGeoPointList,
  } = useMap();
  const [loadingPnrTransformerStation, setLoadingPnrTransformerStation] = useState(false);
  const { setIsInProgress } = useScreenHoldHook();
  const { catchError } = useActionLog();

  const [isSelectAllCheked, setIsSelectAllCheked] = useState(true);
  const [openMapSetting, setOpenMapSetting] = useState(false);
  const { geoPointInCardList, removeAllFromGeoCard, addToGeoCard } = useGeoCard();
  const { resAreaSet, isResAreaSelected, cleanResArea, selectResArea, selectAllReaArea } =
    useResArea();

  const getValueForClipboard = async (): Promise<string[]> => {
    if (geoPointInCardList) {
      const idList: any =
        [...geoPointInCardList]
          .filter((t: GeoPoint) => t.getType().valueOf() != GeoPointType.FREE_POINT)
          .map((t: GeoPoint) => t.getObject()?.taskId) || [];
      return Promise.resolve(idList);
    } else {
      return Promise.reject('');
    }
  };

  const addEmptyGeoObject = () => {
    const coords = getMapCenter();
    const freeGeoPoint: FreeGeoPoint = new FreeGeoPoint(coords[0], coords[1]);
    addToGeoCard(freeGeoPoint);
    addGeoCircle(freeGeoPoint, coords, 0);
  };

  const handleSelectNetworkResArea = (event: any) => {
    selectResArea(event.target.value, event.target.checked);
  };

  const selectAllNetvorkRes = (event: any) => {
    selectAllReaArea(event.target.checked);
    setIsSelectAllCheked(event.target.checked);
  };

  const onOpenMapSettingClick = () => {
    setOpenMapSetting(true);
  };

  const closeMapSetting = () => {
    setOpenMapSetting(false);
  };

  const showPnrTransformerStation = () => {
    setLoadingPnrTransformerStation(true);
    loadPnrTransformerStation(currentGeoBounds)
      .catch((err) => catchError('Ошибка', err))
      .finally(() => setLoadingPnrTransformerStation(false));
  };

  const clean = () => {
    setIsInProgress(true);
    setSelectedGeoPointList([]);
    removeAllFromGeoCard();
    cleanResArea();
    removeAllGeoCircle();
    setIsInProgress(false);
  };

  return (
    <>
      <Box
        display='flex'
        justifyContent='start'
        alignItems={'center'}
        sx={{ mb: 2, mt: -1, mx: -1 }}
      >
        <LoadingButton
          size='small'
          loadingIndicator='Загрузка…'
          variant='outlined'
          onClick={showPnrTransformerStation}
          loading={loadingPnrTransformerStation}
          disabled={mapZoom < 14}
        >
          Отобразить ТП
        </LoadingButton>
        <Tooltip title={'Настройка подсказок для геоточек'}>
          <TuneIcon
            onClick={onOpenMapSettingClick}
            sx={{ color: 'gray', fontSize: 22, cursor: 'pointer', ml: 2 }}
          />
        </Tooltip>
        <MapPnrSetting
          open={openMapSetting}
          close={closeMapSetting}
        ></MapPnrSetting>
        {![...geoPointInCardList].find(
          (t: GeoPoint) => t.getType().valueOf() == GeoPointType.FREE_POINT
        ) &&
          !isClusterMode && (
            <Tooltip title={'Добавить пустую геоточку'}>
              <AddLocationAltIcon
                onClick={addEmptyGeoObject}
                sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
              ></AddLocationAltIcon>
            </Tooltip>
          )}
        {[...geoPointInCardList]?.length > 0 && (
          <>
            <CopiedButton
              getValue={getValueForClipboard}
              title='Скопировать ИД выбранных заявок'
            ></CopiedButton>
            <DeleteIcon
              onClick={clean}
              sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
            />
          </>
        )}
      </Box>
      <Box
        display='flex'
        justifyContent='flex-start'
      >
        {currentGeoResponse && currentGeoResponse.count > 1000 && (
          <Grid
            item
            xs={12}
          >
            <Box>
              <FormLabel
                id='res-label'
                sx={{ mr: 1 }}
              >
                РЭС
              </FormLabel>
              <FormControlLabel
                key={'select-all'}
                control={
                  <Checkbox
                    key={'select-all'}
                    checked={isSelectAllCheked}
                    onChange={selectAllNetvorkRes}
                    sx={{ py: 0 }}
                  />
                }
                label={'Выбрать все'}
              />
            </Box>
            <Stack
              direction='column'
              spacing={0}
              sx={{ flexWrap: 'unwrap', m: 1 }}
              style={{ maxHeight: 200, overflow: 'auto' }}
            >
              {Array.from(resAreaSet)?.map((item: any, index: number) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={index}
                      onChange={handleSelectNetworkResArea}
                      checked={isResAreaSelected(item)}
                      value={item}
                      sx={{ py: 0 }}
                    />
                  }
                  label={item}
                />
              ))}
            </Stack>
            <Divider />
          </Grid>
        )}
      </Box>
      <Box
        display='flex-column'
        justifyContent='start'
        sx={{ mb: 1, mt: -1 }}
      >
        {[...geoPointInCardList]?.length > 0 && (
          <Grid
            item
            xs={12}
            style={{ maxHeight: 650, overflow: 'auto' }}
          >
            {[...geoPointInCardList].map((t, i) => (
              <GeoCard
                key={i}
                geoPoint={t}
              />
            ))}
          </Grid>
        )}
      </Box>
      <Grid
        item
        xs={12}
        alignSelf='flex-end'
      >
        <Box
          display='flex'
          justifyContent='flex-end'
        >
          {currentGeoResponse && selectedGeoPointList.length == 0 && (
            <Typography>Заявок на карте: {currentGeoResponse?.count} шт.</Typography>
          )}
        </Box>
      </Grid>
    </>
  );
};
