import { Box, Grid, Snackbar, SnackbarContent, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CallingMapInner from './map';
import { CallingMapFilter } from './filter/FilterCalligMap';
import { useCallingMapState } from './store';
import {
  findAllInstallationCallMap,
  getGeoLessPointsCount,
  getSingleTaskfromCallMap,
} from '@/services/CallingMapService';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CallingMapPopup } from './components/CallingMapPopup';
import shallow from 'zustand/shallow';
import InfoBlockRebuild from './components/InfoBlockRebuild';
import { color } from '@/styles/mixins';
import {
  applyFilterAndFetchData,
  divideBounds,
  handleCombinedData,
  isNumberInRange,
  makeFilter,
  makePolygon,
  splitGeoJSONByGrid,
} from './utils/CallingMapUtils';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { Filter, GeolessGetDataPayload, IgeoPointCallingMap } from './types/types';
import useDebounce from '../zoningMap/hook/useDebounce';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import EventCalandarPopup from './components/EventCalandarPopup';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { SubscriberForm } from '@/components/SubscriberForm';
import { FormProvider, useForm } from 'react-hook-form';
import { SubscriberParam } from '@/dto/SubscribersDto';
import { addSub } from '@/services/SubscribersService';
import TabPanel from './components/TabPanel';
import { getGeoLessPoints } from '@/services/CallingMapService';
import GeoLessPointsTable from './components/GeoLessPointsTable';
import { geojsonToWKT } from '@terraformer/wkt';
import { BoxShadow } from '@/components/ui/BoxShadow';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const turf = window?.turf;

type ZoomStateType = 'SAME' | 'OUT' | 'IN';

export interface GeoBounds {
  maxX: number;
  maxY: number;
  minY: number;
  minX: number;
}
export interface GeoData {
  polygon: string;
  formatedFilter: any;
  limit?: number;
}

export const CallingMap = () => {
  const {
    setGeoPoints,
    isOpenPopUp,
    singleAbonent,
    isOpenSnackBar,
    bounds,
    mapZoom,
    toggleUrgentGeoPoins,
    isOpenEventCalendarPopUp,
    setIsOpenEventCalendarPopUp,
    taskId,
    isOpenAddAbonentPopUp,
    setOpenAddAbonentPopup,
    setSingleGeoPoint,
    setGeoLess,
    setGeoLessCount,
    existingGeoPoints,
    limit,
    currentPage,
    pageSize,
  } = useCallingMapState(
    (state) => ({
      setGeoPoints: state.setGeoPoints,
      isOpenPopUp: state.isOpenPopUp,
      singleAbonent: state.singleAbonent,
      setExecutors: state.setExecutors,
      isOpenSnackBar: state.isOpenSnackBar,
      bounds: state.bounds,
      taskId: state.taskId,
      mapZoom: state.mapZoom,
      toggleUrgentGeoPoins: state.toggleUrgentGeoPoins,
      isAuthorized: state.isAuthorized,
      isOpenEventCalendarPopUp: state.isOpenEventCalendarPopUp,
      setIsOpenEventCalendarPopUp: state.setIsOpenEventCalendarPopUp,
      isOpenAddAbonentPopUp: state.isOpenAddAbonentPopUp,
      setOpenAddAbonentPopup: state.setOpenAddAbonentPopup,
      setSingleGeoPoint: state.setSingleGeoPoint,
      setGeoLess: state.setGeoLess,
      setGeoLessCount: state.setGeoLessCount,
      existingGeoPoints: state.geoPoints,
      limit: state.limit,
      currentPage: state.currentPage,
      pageSize: state.pageSize,
      setCurrentPage: state.setCurrentPage,
      setPageSize: state.setPageSize,
    }),
    shallow
  );
  const deboundceBounds = useDebounce(bounds, 800);
  const { catchError, addActionLog } = useActionLog();
  const [mapAPI, setMapAPI] = useState(null);
  const [minZoom, setMinZoom] = useState(16);
  const { setIsInProgress } = useScreenHoldHook();
  const [value, setValue] = useState(0);

  const [isTableSearch, setIsTableSearch] = useState(false);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const [zoomState, setZoomState] = useState<ZoomStateType>('SAME');
  const [diffGeojson, setDiffGeojson] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(4);

  const notSingleSearch = activeTab === 4;
  const singleSearch = activeTab === 3;
  const isTableMode = Boolean(value);

  const getGeoLessTableData = (
    { currentPage, pageSize }: GeolessGetDataPayload,
    formatedFilter: any
  ) => {
    getGeoLessPoints({
      body: { filterItemList: formatedFilter.filterItemList },
      page: currentPage,
      pageSize: pageSize,
    })
      .then(({ data }) => {
        setGeoLess(data);
      })
      .catch((err) => {
        catchError('Ошибка', err);
      })
      .finally(() => setIsInProgress(false));
  };

  const getTableCount = (cb?: (flag: boolean) => void, formatedFilter: any = {}) => {
    cb && cb(true);
    getGeoLessPointsCount({ filterItemList: formatedFilter.filterItemList })
      .then(({ data }) => {
        setGeoLessCount(data);
      })
      .catch((err) => {
        catchError('Ошибка', err);
      })
      .finally(() => cb && cb(false));
  };

  const getTableData = ({ currentPage, pageSize }: GeolessGetDataPayload, formatedFilter: any) => {
    getGeoLessTableData({ currentPage, pageSize }, formatedFilter);
    getTableCount(setIsCountLoading, formatedFilter);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue) setIsTableSearch(true);
    else setIsTableSearch(false);
  };

  const onSubmit = () => {
    const {
      callCenterRequestType,
      callPeriod,
      requestPeriod,
      installationCallStatus,
      montagePlaceType,
      phase,
      taskType,
      phoneNumber,
      termTaskId,
      tkoPeriodDefiner,
    } = useCallingMapState.getState();
    const formatedFilter = makeFilter(
      {
        callCenterRequestType,
        taskType,
        montagePlaceType,
        phase,
        installationCallStatus,
        callPeriod,
        phoneNumber,
        taskId: termTaskId,
        requestPeriod,
        tkoPeriod: tkoPeriodDefiner,
      },
      deboundceBounds,
      undefined
    );

    setIsInProgress(true);
    if (!isTableMode) {
      findAllInstallationCallMap({ polygon: makePolygon(deboundceBounds), formatedFilter })
        .then(({ data }) => {
          setGeoPoints(data);
        })
        .catch((err) => {
          catchError('Ошибка', err);
        })
        .finally(() => setIsInProgress(false));
    } else {
      getTableData({ currentPage, pageSize }, formatedFilter);
    }
  };

  useEffect(() => {
    const {
      callCenterRequestType,
      callPeriod,
      requestPeriod,
      installationCallStatus,
      montagePlaceType,
      phase,
      taskType,
      tkoPeriodDefiner,
    } = useCallingMapState.getState();

    if (deboundceBounds.length && !toggleUrgentGeoPoins && notSingleSearch) {
      if (isNumberInRange(mapZoom)) {
        applyFilterAndFetchData({
          bounds,
          accuracy: undefined,
          useCallingMapState,
          catchError,
          findAllInstallationCallMap,
          setIsInProgress,
          polygon: makePolygon(deboundceBounds),
        })
          .then((data: IgeoPointCallingMap[]) => setGeoPoints(data))
          .finally(() => setIsInProgress(false));
      } else {
        if (zoomState === 'SAME' && diffGeojson?.geometry) {
          const formatedFilter = makeFilter(
            {
              callCenterRequestType,
              taskType,
              montagePlaceType,
              phase,
              installationCallStatus,
              callPeriod,
              requestPeriod,
              tkoPeriod: tkoPeriodDefiner,
            },
            bounds,
            undefined
          );

          const splitPolygons = splitGeoJSONByGrid(diffGeojson?.geometry, mapZoom).map((el: any) =>
            geojsonToWKT(el.geometry)
          );

          if (splitPolygons.length) {
            setIsInProgress(true);

            const promises = splitPolygons.map((polygon: any) =>
              findAllInstallationCallMap({
                polygon,
                formatedFilter,
                limit,
              }).then(({ data }) => data)
            );

            Promise.all(promises)
              .then((results) => {
                const combinedGeoPoints = results.flat();
                handleCombinedData(
                  combinedGeoPoints,
                  setGeoPoints,
                  existingGeoPoints,
                  zoomState,
                  catchError
                );
              })
              .catch((error) => catchError('Ошибка', error))
              .finally(() => setIsInProgress(false));
          }
        } else {
          const deboundceBoundsArr = divideBounds(deboundceBounds);
          const promises = deboundceBoundsArr.map((boundsInside) => {
            const polygon = turf?.polygon([boundsInside]);

            return applyFilterAndFetchData({
              bounds: boundsInside,
              accuracy: 10,
              useCallingMapState,
              catchError,
              findAllInstallationCallMap,
              setIsInProgress,
              polygon: geojsonToWKT(polygon.geometry),
              limit,
            });
          });

          Promise.all([Promise.all(promises)])
            .then(([results]) => {
              handleCombinedData(
                results.flat(),
                setGeoPoints,
                existingGeoPoints,
                zoomState,
                catchError
              );
            })
            .catch((error) => {
              catchError('Ошибка', error);
              setGeoPoints([]);
            })
            .finally(() => setIsInProgress(false));
        }
      }
    }
  }, [deboundceBounds]);

  const methods = useForm<SubscriberParam>({ mode: 'onChange' });

  const handleAddNumber = (data: SubscriberParam) => {
    if (!taskId) {
      return;
    }
    setIsInProgress(true);
    data.taskId = taskId;
    addSub(data)
      .then(({ data }) => {
        if (data.length) {
          addActionLog(ActionLogType.SUCCESS, 'Абонет добавлен');
        } else {
          catchError('Ошибка добавления абонента', {});
        }
        methods.reset();
        return getSingleTaskfromCallMap(taskId);
      })
      .then(({ data }) => {
        setSingleGeoPoint(data);
      })
      .catch((err) => catchError('Ошибка добавления абонента', err))
      .finally(() => setIsInProgress(false));
  };

  return (
    <Box m={1}>
      <Grid
        container
        spacing={4}
        mt={1}
      >
        <Grid
          item
          xs={8}
          height={'78vh'}
        >
          <Stack
            display={'flex'}
            alignItems={'center'}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              <Tab label='карта' />
              <Tab label='заявки без координат' />
            </Tabs>
          </Stack>
          <TabPanel
            value={value}
            index={0}
          >
            <CallingMapInner
              setMapAPI={setMapAPI}
              setMinZoom={setMinZoom}
              minZoom={minZoom}
              setZoomState={setZoomState}
              setDiffGeojson={setDiffGeojson}
              diffGeojson={diffGeojson}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
          >
            <GeoLessPointsTable
              getGeoLessTableData={getGeoLessTableData}
              getTableCount={getTableCount}
              setIsCountLoading={setIsCountLoading}
              isCountLoading={isCountLoading}
            />
          </TabPanel>
        </Grid>
        <Grid
          item
          xs={4}
          mt={6}
        >
          <CallingMapFilter
            mapAPI={mapAPI}
            onSubmit={onSubmit}
            isTableMode={value}
            isTableSearch={isTableSearch}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {!isNumberInRange(mapZoom) && !singleSearch && (
            <BoxShadow
              sx={{ marginTop: 1 }}
              background={color('sandy')}
            >
              <Typography>Для работы с заявкой необходимо "приблизить" карту</Typography>
            </BoxShadow>
          )}
          <InfoBlockRebuild />
        </Grid>
        <CallingMapPopup
          isOpen={isOpenPopUp}
          abonent={singleAbonent}
          onClose={() => false}
        />
        <FormProvider {...methods}>
          <AccessPopup
            headerText={'Добавить абонента'}
            isOpen={isOpenAddAbonentPopUp}
            onClose={() => {
              setOpenAddAbonentPopup(false);
              methods.reset();
            }}
            onSuccess={() => {
              methods.handleSubmit(handleAddNumber)();
              setOpenAddAbonentPopup(false);
            }}
          >
            <SubscriberForm />
          </AccessPopup>
        </FormProvider>

        <EventCalandarPopup
          isOpen={isOpenEventCalendarPopUp}
          onClose={() => setIsOpenEventCalendarPopUp(false)}
          maxWidth={'lg'}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpenSnackBar}
        >
          <SnackbarContent
            sx={{ backgroundColor: '#FBDEDB' }}
            message={
              <Stack
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                p={3}
              >
                <ErrorOutlineIcon style={{ color: color('trueRed') }} />
                <Typography style={{ color: color('typographyColorMap1') }}>
                  Выберите результат звонка и заполните обязательные поля для того, чтобы сохранить
                  новые данные
                </Typography>
              </Stack>
            }
          />
        </Snackbar>
      </Grid>
    </Box>
  );
};
