import { createStore } from '../utils/createStore';

export const useBlockKey = createStore(
  (set: any, get: any) => ({
    isBlocking: false,
    setIsBlocking: (block: boolean) => {
      set({ isBlocking: block });
    },
  }),
  'hackedDataGridPro'
);
