import { getAdpuArray, getTKOArray } from '@/services/ReportService';
import { findAllCatalogByType } from '@/services/TaskService';
import { ApiFindAllGroup } from '@/services/YodaRestService';
import { catchError } from './ActionLogHook';
import { createStore } from '@/utils/createStore';
import { StateSelector } from 'zustand';
import { makeArrayOfStingsToOptions } from '@/components/inputs/DynamicFilter/utils';
import { GetCatalogForRP } from '@/services/RPDashboardService';
import { catalogsLookUp } from '@/app/context/AuthProvider';
import { getItemWithExpiration, responseToMap, setItemWithExpiration } from '@/utils/heplers';

/**
 * Для получения каталога использовать методы getCatalogMap - для получения мапы,
 * getCatalogList - для получения массива.
 * Названия ключей должны быть строго такими же, как название каталога, но в сamelCase.
 *
 * пример:
 *
 * const { getCatalogMap } = useCatalog();
 * ...
 * <AutocompleteValue
 *    title='...'
 *    fieldName={`...`}
 *    values={getCatalogMap('smrLineType')}
 * />
 *
 * */

const tablePostfixRp = 'RP';
const MINUTES_TO_REFRESH_CATALOGS = 60;

type CatalogsMap = {
  taskType: Map<string, string>;
  taskStatus: Map<string, string>;
  taskPriority: Map<string, string>;
  taskConsumer: Map<string, string>;
  phase: Map<string, string>;
  taskGroupMap: Map<string, string>;
  changeStatus: Map<string, string>;
  actStatus: Map<string, string>;
  stampInstallStatus: Map<string, string>;
  replacementReason: Map<string, string>;
  roles: Map<string, string>;
  puConnectType: Map<string, string>;

  protectionType: Map<string, string>;
  ppoInputType: Map<string, string>;
  ppoVoltageState: Map<string, string>;
  ppoLocationTt: Map<string, string>;
  ppoLocationMeter: Map<string, string>;
  ppoCableComposition: Map<string, string>;
  expendable: Map<string, string>;
  ppoInstallPlaceLocker: Map<string, string>;

  referenceMeterType: Map<string, string>;
  amperageTransformerType: Map<string, string>;
  voltageTransformerType: Map<string, string>;
  sendingStatus: Map<string, string>;
  networkEquipmentType: Map<string, string>;
  manufacturer: Map<string, string>;

  fileType: Map<string, string>;
  fileStage: Map<string, string>;

  odpuManagementCompany: Map<string, string>;
  odpuStage: Map<string, string>;
  territorialBranch: Map<string, string>;
  claimType: Map<string, string>;

  ktt: Map<string, string>;
  ktnType: Map<string, string>;
  kttEnum: Map<string, string>;
  ttType: Map<string, string>;
  exPuPlace: Map<string, string>;
  tpType: Map<string, string>;
  puType: Map<string, string>;
  meterType: Map<string, string>;
  newPuType: Map<string, string>;
  smrLineType: Map<string, string>;
  smrLocker: Map<string, string>;

  callStatus: Map<string, string>;

  warrantyWorkStatus: Map<string, string>;
  warrantyWorkType: Map<string, string>;
  simChangeStatus: Map<string, string>;
  measurementStatus: Map<string, string>;

  cableSection: Map<string, string>;
  montagePlaceType: Map<string, string>;

  fzOrTechConnectionTaskType: Map<string, string>;

  simType: Map<string, string>;
  simOperator: Map<string, string>;
  networkElementStatus: Map<string, string>;
  networkMontagePlace: Map<string, string>;
  networkElementPlanningType: Map<string, string>;
  networkElementMontagePlace: Map<string, string>;

  ppoOdpuTechDecision: Map<string, string>;
  ppoState: Map<string, string>;
  ppoCurrentRateRange: Map<string, string>;
  powerScheme: Map<string, string>;
  protectionDeviceRating: Map<string, string>;
  callCenterRequestType: Map<string, string>;
  signalLevel: Map<string, string>;
  pillarType: Map<string, string>;
  uspdInstallPlace: Map<string, string>;
  mobileOperatorType: Map<string, string>;
  installationCallType: Map<string, string>;
  tkoPeriodDefiner: Map<string, string>;

  cableDescentType: Map<string, string>;
  cableType: Map<string, string>;

  energyCompany: Map<string, string>;
  regionalElectricNetwork: Map<string, string>;

  ppoLocationNewMeter: Map<string, string>;
  ppoInstallPlaceTt: Map<string, string>;
  automaticBreaker: Map<string, string>;

  floorType: Map<string, string>;
};

type CatalogsArray = {
  adpuArray: string[];
  tkoArray: { code: string; name: string }[];
};

type ObjectKey<Obj> = keyof Obj;
export type CatalogsMapKeys = ObjectKey<CatalogsMap>;
export type CatalogsArrayKeys = ObjectKey<CatalogsArray>;

type Functions = {
  initCatalog: () => void;
  getCatalogMapWithErr: (key: CatalogsMapKeys) => Map<string, string>;
  updateCatalogMapWithErr: (key: CatalogsMapKeys) => Map<string, string>;
  getCatalogListWithErr: (key: CatalogsArrayKeys) => string[];
  getCatalogListForRp: (key: string) => Array<{ label: string; value: string }>;
  getAdpuArray: () => void;
  getTKOArray: () => void;
};

type CatalogsKeys = CatalogsArrayKeys | CatalogsMapKeys;
type Loading = {
  loading: Map<CatalogsKeys, boolean>;
};

type CatalogStore = CatalogsMap & CatalogsArray & Functions & Loading;

const kebabize = (str: string) =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

export const useCatalogZus = createStore<CatalogStore>(
  (set: any, get: any) => ({
    loading: new Map(),
    cableSectionTest: new Map(),
    taskType: new Map(),
    taskStatus: new Map(),
    taskPriority: new Map(),
    taskConsumer: new Map(),
    phase: new Map(),
    taskGroupMap: new Map(),
    roles: new Map(),
    puConnectType: new Map(),
    tariffMap: new Map(),
    changeStatus: new Map(),
    actStatus: new Map(),
    stampInstallStatus: new Map(),
    replacementReason: new Map(),
    tkoPeriodDefiner: new Map(),

    protectionType: new Map(),
    ppoInputType: new Map(),
    ppoVoltageState: new Map(),
    ppoLocationTt: new Map(),
    ppoLocationMeter: new Map(),
    ppoCableComposition: new Map(),
    expendable: new Map(),
    ppoInstallPlaceLocker: new Map(),
    ppoOdpuTechDecision: new Map(),
    ppoState: new Map(),

    fileType: new Map(),
    fileStage: new Map(),

    referenceMeterType: new Map(),
    amperageTransformerType: new Map(),
    voltageTransformerType: new Map(),
    odpuStage: new Map(),
    sendingStatus: new Map(),
    networkEquipmentType: new Map(),
    manufacturer: new Map(),
    networkElementPlanningType: new Map(),
    odpuManagementCompany: new Map(),
    claimType: new Map(),
    adpuArray: [],
    tkoArray: [],
    territorialBranch: new Map(),
    callStatus: new Map(),

    ktt: new Map(),
    ktnType: new Map(),
    kttEnum: new Map(),
    ttType: new Map(),
    exPuPlace: new Map(),
    tpType: new Map(),
    puType: new Map(),
    meterType: new Map(),
    newPuType: new Map(),
    smrLineType: new Map(),
    smrLocker: new Map(),

    warrantyWorkStatus: new Map(),
    warrantyWorkType: new Map(),
    simChangeStatus: new Map(),
    measurementStatus: new Map(),

    cableSection: new Map(),
    montagePlaceType: new Map(),
    fzOrTechConnectionTaskType: new Map(),

    cableDescentType: new Map(),
    cableType: new Map(),

    simType: new Map(),
    simOperator: new Map(),
    networkElementStatus: new Map(),
    networkMontagePlace: new Map(),
    ppoCurrentRateRange: new Map(),
    powerScheme: new Map(),
    protectionDeviceRating: new Map(),
    callCenterRequestType: new Map(),
    installationCallType: new Map(),

    signalLevel: new Map(),
    pillarType: new Map(),

    uspdInstallPlace: new Map(),
    mobileOperatorType: new Map(),
    energyCompany: new Map(),
    regionalElectricNetwork: new Map(),

    ppoLocationNewMeter: new Map(),
    ppoInstallPlaceTt: new Map(),
    automaticBreaker: new Map(),
    floorType: new Map(),
    networkElementMontagePlace: new Map(),

    updateCatalogMapWithErr: (key) => {
      set({ loading: get().loading.set(key, true) });
      const tmp = kebabize(key);
      findAllCatalogByType(tmp)
        .then((res) => {
          set({ [key]: responseToMap(res.data) });
        })
        .catch((err) => catchError(`Каталог ${tmp} не загружен`, err));

      return get()[key];
    },

    getCatalogMapWithErr: (key) => {
      const catalog = getItemWithExpiration(catalogsLookUp, key);
      if (catalog && !get().loading.get(key)) {
        set({ loading: get().loading.set(key, true) });
        set({ [key]: responseToMap(catalog?.data) });
        return get()[key];
      }
      if (get()[key] && get()[key].size === 0 && !get().loading.get(key)) {
        set({ loading: get().loading.set(key, true) });
        const tmp = kebabize(key);
        findAllCatalogByType(tmp)
          .then((res) => {
            setItemWithExpiration(key, res.data, MINUTES_TO_REFRESH_CATALOGS);
            set({ [key]: responseToMap(res.data) });
          })
          .catch((err) => catchError(`Каталог ${tmp} не загружен`, err));
      }
      return get()[key];
    },

    getCatalogListWithErr: (key) => {
      if (get()[key].length === 0 && !get().loading.get(key)) {
        set({ loading: get().loading.set(key, true) });
        const tmp = kebabize(key);
        findAllCatalogByType(tmp)
          .then((res) => {
            set({ [key]: res.data });
          })
          .catch((err) => catchError(`Каталог ${tmp} не загружен`, err));
      }
      return get()[key];
    },
    getCatalogListForRp: (key: string) => {
      const cusTomKey = key + tablePostfixRp;
      if (!get()[cusTomKey] && !get().loading.get(cusTomKey)) {
        set({ loading: get().loading.set(cusTomKey, true) });
        GetCatalogForRP(key)
          .then((res) => set({ [cusTomKey]: makeArrayOfStingsToOptions(res.data) }))
          .catch((err) => {
            set({ [cusTomKey]: [] });
            catchError(`Каталог ${key} не загружен`, err);
          });
      }

      return get()[cusTomKey];
    },

    initCatalog: () => {
      const groups: Map<string, string> = get().taskGroupMap;
      if (groups?.size === 0) {
        ApiFindAllGroup().then((res) => {
          const result = new Map();
          res.data.forEach((t: any) => {
            result.set(t.id, t.title);
          });
          set({ taskGroupMap: result });
        });
      }
    },

    getAdpuArray: () => {
      const adpuArray: string[] = get().adpuArray;
      if (adpuArray && adpuArray.length === 0) {
        getAdpuArray()
          .then((res) => set({ adpuArray: res.data }))
          .catch((err) => catchError(`Каталог шаблонов не загружен`, err));
      }
    },
    getTKOArray: () => {
      const tkoArray: { code: string; name: string }[] = get().tkoArray;
      if (tkoArray && tkoArray.length === 0) {
        getTKOArray()
          .then((res) => set({ tkoArray: res.data }))
          .catch((err) => catchError(`Каталог шаблонов не загружен`, err));
      }
    },
  }),
  'Catalog'
);

export const useCatalog: <T>(
  selector: StateSelector<CatalogStore, T>,
  ...shallow: ((a: T, b: T) => boolean)[]
) => T = (selector, ...shallow) => useCatalogZus(selector, ...shallow);
