import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import CatalogEquipmentItem from '@/components/sections/CatalogsEditor/EquipmentEditor/CatalogEquipmentItem';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const CatalogEquipmentEditorPage = () => {
  useAccess({
    access: [AccessEnum.Catalogs],
  });
  useMetaTitle('Сетевое оборудование - редактирование');
  return <CatalogEquipmentItem />;
};
export default CatalogEquipmentEditorPage;
