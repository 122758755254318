import { SupplyStatisticResponseDTO } from '@/dto/SupplyBillService/SupplyBillServiceResponse';
import { Button, TablePagination } from '@mui/material';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import React from 'react';
import { SupplyStatisticDataGridWrapper } from './SupplyStatistic.styled';
import { useSupplyStatisticColumns, useSupplyStatisticForm } from './utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { arrowLabel } from '@/components/features/tasksBrowse/utils';
import { SupplyStatisticApiGenerateExcel } from '@/services/SupplyBillService';
import { catchError } from '@/hooks/ActionLogHook';
import { downLoadExcel } from '@/components/features/complaints/ComplaintReportButton/utils';

export interface SupplyStatisticDataGridProps {
  data: SupplyStatisticResponseDTO[];
  isLoading: boolean;
  page: number;
  pageSize: number;
  totalCount: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const SupplyStatisticDataGrid = ({
  data,
  isLoading,
  setPageSize,
  setPage,
  page,
  pageSize,
  totalCount,
}: SupplyStatisticDataGridProps) => {
  const columns = useSupplyStatisticColumns();
  const { getFilterValues } = useSupplyStatisticForm();

  const generateExcelFile = () => {
    const filter = getFilterValues();
    SupplyStatisticApiGenerateExcel(filter)
      .then((data) => downLoadExcel(data, 'Статистика накладных'))
      .catch((error) => catchError('Ошибка скачивания EXCEL', error));
  };

  return (
    <>
      <Button
        endIcon={<FileDownloadIcon />}
        onClick={generateExcelFile}
        sx={{ mr: 'auto' }}
        disabled={isLoading || !data.length}
      >
        скачать в excel
      </Button>
      <SupplyStatisticDataGridWrapper>
        <DataGrid
          disableVirtualization
          disableColumnFilter
          disableSelectionOnClick
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          columns={columns}
          loading={isLoading}
          getRowId={(row) => row.contractorId}
          components={{
            Pagination: () => (
              <TablePagination
                count={totalCount}
                page={page}
                rowsPerPage={pageSize}
                onRowsPerPageChange={(newPageSize) => {
                  setPageSize(Number(newPageSize.target.value));
                  setPage(0);
                }}
                labelDisplayedRows={({ from, to, count }) => (
                  <>
                    {`${from} - ${to} из `}
                    {count}
                  </>
                )}
                onPageChange={(ev, newPage) => setPage(newPage)}
                rowsPerPageOptions={[1, 2, 5, 10, 50]}
                labelRowsPerPage={'Строк на странице:'}
                getItemAriaLabel={arrowLabel}
              />
            ),
          }}
        />
      </SupplyStatisticDataGridWrapper>
    </>
  );
};

export default SupplyStatisticDataGrid;
