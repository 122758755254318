import { useQuery } from 'react-query';
import { GetCallingMapOperator } from '@/services/YodaRestService';
import { KEYS } from './Keys';
import { useActionLog } from '@/hooks/ActionLogHook';
import { FetchCathError } from '@/types/Types';
import { initWebRTC } from '@/components/maps/callingMap/utils/vats';
import { useCallingMapState } from '@/components/maps/callingMap/store';

export function useCallingMapOperator() {
  const { fetchCatch, catchError } = useActionLog();
  const setIsAuthorized = useCallingMapState((state) => state.setIsAuthorized);
  useQuery({
    queryKey: KEYS.callingMapOperator,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: GetCallingMapOperator,
    onError: (error: FetchCathError) => fetchCatch(error),
    onSuccess: ({ data }) => {
      initWebRTC(
        data?.login,
        data?.password,
        () => setIsAuthorized(true),
        () => catchError('Ошибка авторизации оператора', {}),
        () => catchError('Ошибка подключения к вирутальной АТС', {})
      );
    },
  });
}
