import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { YungaLogResponse } from '@/dto/Yunga';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useYunga } from '@/hooks/YungaHook';
import { CopiedButton } from '@/components/button/CopiedButton';
import moment from 'moment';

interface YungaLogItemProps {
  yungaLogResponse: YungaLogResponse;
  index: number;
}

export const YungaLogItem = (props: YungaLogItemProps) => {
  const { yungaLogResponse, index } = props;
  const { fileId, setFileId } = useYunga();

  const handleChange = (val: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setFileId(isExpanded ? val : undefined);
  };

  const handleCopied = async (val?: string): Promise<any> => {
    if (val) {
      return Promise.resolve(val);
    }
    return Promise.reject('');
  };

  const getDateTime = (val?: Date) => {
    return val ? moment(val).format('DD.MM.yyyy HH:mm:ss') : '-';
  };

  const getColorStatus = (val?: string) => {
    if (!val) {
      return '';
    }
    switch (val) {
      case 'SENT':
        return '';
      case 'OK':
        return '#CEEDC7';
      case 'ERROR':
        return '#FFCAC8';
    }
  };

  return (
    <Accordion
      expanded={fileId === yungaLogResponse.fileId}
      onChange={handleChange(yungaLogResponse.fileId)}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='yunga-item'
        id='yunga-item-header'
        sx={{
          boxShadow: '0px 4px 10px 0px #00000040',
          backgroundColor: getColorStatus(yungaLogResponse.status),
        }}
      >
        <Grid
          container
          direction={'row'}
          spacing={2}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item>
            <Grid
              item
              container
              direction={'row'}
              alignItems='center'
            >
              <Grid item>
                <Typography variant='subtitle2'>log ID: {yungaLogResponse.id}</Typography>
              </Grid>
              <Grid item>
                <CopiedButton
                  getValue={() => handleCopied(yungaLogResponse.id)}
                  title='Скопировать log ID'
                ></CopiedButton>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={'row'}
              alignItems='center'
            >
              <Grid item>
                <Typography variant='subtitle2'>file ID: {yungaLogResponse.fileId}</Typography>
              </Grid>
              <Grid item>
                <CopiedButton
                  getValue={() => handleCopied(yungaLogResponse.fileId)}
                  title='Скопировать file ID'
                ></CopiedButton>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle2'
                color={'#7B8FA1'}
              >
                to: {getDateTime(yungaLogResponse.requestAt)} from:{' '}
                {getDateTime(yungaLogResponse.responseAt)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='h6'> {yungaLogResponse.status}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {yungaLogResponse.response && (
          <Typography variant='h6'>
            <pre style={{ whiteSpace: 'pre-wrap' }}> {yungaLogResponse.response} </pre>
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
