import { LS_KEY_STOREHOUSE } from '@/components/storehouse/SupplyPUComponent/utils';
import { yodaRoles } from '@/hooks/useRole';
import { createContext } from 'react';
import { RoutesProps } from 'react-router-dom';
import { YodaToken, YodaTokenContextType } from '../../types/Types';

const yodatk = 'ydoatk';
const yodaTkExIn = 'yodaTkExIn';
const yodaTs = 'yodaTs';
const yodarf = 'yodarf';
export const catalogsLookUp = 'catalogsLookUp';
export const yodaJwt = 'yodaJwt';

export const AuthContext = createContext<YodaTokenContextType | null>(null);

export const AuthProvider = ({ children }: RoutesProps) => {
  const getYodaToken = () => {
    return yodaTokenLocal();
  };

  const logOut = () => {
    removeTokens();
  };

  return <AuthContext.Provider value={{ getYodaToken, logOut }}>{children}</AuthContext.Provider>;
};

export function removeTokens() {
  removeAuthTokens();
}

function removeAuthTokens() {
  removeLsTokensArray([yodatk, yodarf, yodaJwt, yodaRoles, LS_KEY_STOREHOUSE, catalogsLookUp]);
}

const removeLsTokensArray = (tokens: string[]) => {
  tokens.forEach((token) => {
    localStorage.removeItem(token);
  });
};

/**
 *  Check how old token is if the duration is
 * less than expiresIn return token from local
 * stor else null
 * @returns access_token or null
 */
export function yodaTokenLocal(): string | null {
  const tCreated = Number(localStorage.getItem(yodaTs));
  const clearTime = (new Date().getTime() - tCreated) / 1000;
  const expiresIn = Number(localStorage.getItem(yodaTkExIn));
  if (clearTime > expiresIn) return null;
  return localStorage.getItem(yodatk) as string;
}

export function yodaRefreshTokenLocal(): string {
  return localStorage.getItem(yodarf) as string;
}

export function saveYodaToken(token: YodaToken) {
  token.create_ts = new Date();
  token.expires_in = token.expires_in - 90;
  if (token.access_token) localStorage.setItem(yodatk, token.access_token);
  localStorage.setItem(yodarf, token.refresh_token);
  localStorage.setItem(yodaTkExIn, String(token.expires_in));
  localStorage.setItem(yodaTs, String(token.create_ts.getTime()));
}
