import { Clusterer } from 'react-yandex-maps';
import { geoResponseToGeoTaskList } from '../../utils';
import { GeoPointType, TaskGeoPoint } from '@/components/maps/GeoPoint';
import { GeoTask } from '@/dto/taskmap/Dto';
import { useGeoCard } from '@/hooks/GeoCardHook';
import { useGeoQuery } from '@/hooks/GeoQueryHook';
import { useMap } from '@/hooks/MapHook';
import { TaskPlacemark } from '../placemark/TaskPlacemark';

export const PlacemarkLayout = () => {
  const { currentGeoResponse } = useGeoQuery();
  const { geoPointInCardList } = useGeoCard();
  const { setClusterRef, selectedGeoPointList } = useMap();

  const handleCluster = (ref: any) => {
    setClusterRef(ref);
  };

  return (
    <>
      {selectedGeoPointList?.length > 0 ? (
        selectedGeoPointList.map((t) => (
          <TaskPlacemark
            key={t.getObject()?.taskId}
            geoPoint={t}
          ></TaskPlacemark>
        ))
      ) : (
        <>
          {currentGeoResponse && (
            <>
              <Clusterer
                instanceRef={(ref) => ref && handleCluster(ref)}
                options={{ minClusterSize: 10 }}
              >
                {geoResponseToGeoTaskList(currentGeoResponse).map((t: GeoTask) => (
                  <TaskPlacemark
                    key={t.taskId}
                    geoPoint={new TaskGeoPoint(t)}
                  ></TaskPlacemark>
                ))}
              </Clusterer>
              {[...geoPointInCardList].find(
                (t) => t.getType().valueOf() == GeoPointType.FREE_POINT
              ) && (
                <TaskPlacemark
                  key={'free-point'}
                  geoPoint={[...geoPointInCardList].find(
                    (t) => t.getType().valueOf() == GeoPointType.FREE_POINT
                  )}
                ></TaskPlacemark>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
