import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { addSub, getSubList } from '@/services/SubscribersService';
import { SubscriberItem } from '@/components/SubscriberItem';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { SubscriberForm } from '@/components/SubscriberForm';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { FormProvider, useForm } from 'react-hook-form';
import { SubscriberParam, SubscriberResponse } from '@/dto/SubscribersDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useDisabledAll } from '@/hooks/useDisabledAll';
import { useTaskEditorOnlyShow } from '../useTaskEditorOnlyShow';

interface TaskSubscribersPanelProps {
  taskId: string;
}

export const TaskSubscribersPanel: FC<TaskSubscribersPanelProps> = ({ taskId }) => {
  const [subList, setSubList] = useState<SubscriberResponse[]>([]);
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { catchError } = useActionLog();

  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm<SubscriberParam>({ mode: 'onChange' });
  const onSubmit = (data: SubscriberParam) => {
    addSub(data)
      .then(({ data }) => {
        setSubList([...subList, ...data]);
        methods.reset();
      })
      .catch((err) => catchError('Ошибка добавления абонента', err));
  };

  methods.setValue('taskId', taskId);

  useEffect(() => {
    getSubList(taskId)
      .then(({ data }) => setSubList(data))
      .catch((err) => catchError('Ошибка загрузки списка абонентов', err));
  }, []);

  const isDisabled = () =>
    methods?.formState?.errors?.contactPhone?.type === 'required' ||
    methods?.formState?.errors?.fio?.type === 'required' ||
    methods?.formState?.errors?.fio?.type === 'pattern' ||
    methods?.formState?.errors?.contactPhone?.type === 'pattern';

  return (
    <BoxShadow p={1}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Абоненты</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {subList.map((elem) => (
            <SubscriberItem
              key={elem.id}
              {...elem}
              setSubList={setSubList}
              subList={subList}
            />
          ))}
          <AddRoundedIcon
            fontSize={'large'}
            color='info'
            sx={{ cursor: !disabledAll && !onlyShow ? 'pointer' : '', mt: 1 }}
            onClick={() => !disabledAll && !onlyShow && setIsOpen(true)}
          />
          <FormProvider {...methods}>
            <AccessPopup
              headerText={'Добавить абонента'}
              disabledSuccess={isDisabled()}
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onSuccess={() => {
                methods.handleSubmit(onSubmit)();
                setIsOpen(false);
              }}
            >
              <SubscriberForm />
            </AccessPopup>
          </FormProvider>
        </AccordionDetails>
      </Accordion>
    </BoxShadow>
  );
};
