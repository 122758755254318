import { NavExitWrapper } from '../NavBar.styled';
import StorehouseUserName from '@/components/blocks/StorehouseUserName/StorehouseUserName';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { IconButton, Tooltip } from '@mui/material';

import { AccessController } from '@/components/common/AccessController';
import { AccessEnum } from '@/types/roles';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import { color } from '@/styles/mixins';
import { NavDropdownItem } from '@/components/widgets/Navbar/NavDropdownItem';
import { CustomLink, LinkType } from '../CustomLink';
import { useLogOut } from '@/hooks/useLogOut';
import { useNavbar } from '../store';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { NavbarLinksWrapper } from './NavbarLinks.styled';
const URL = 'https://yoda.sec2.ru/open?screen=report$Report.run';

export const NavbarLinks = () => {
  const logOut = useLogOut();
  const { actionLogList } = useActionLog();
  const { setOpenErrorsDialog, toggleDrawer } = useNavbar();
  const handleOpenErrorsDialog = () => setOpenErrorsDialog(true);
  const isMobile = useMobileSize();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).closest('a')) {
      toggleDrawer(false); // Закрываем меню
    }
  };

  return (
    <>
      <NavbarLinksWrapper
        isMobile={isMobile}
        onClick={handleClick}
      >
        <AccessController access={[AccessEnum.ShowReports]}>
          <CustomLink
            to={URL}
            type={LinkType.OutMainLink}
          >
            Отчеты
          </CustomLink>
        </AccessController>

        <AccessController access={[AccessEnum.Map, AccessEnum.MapUspd, AccessEnum.MapUspdOnly]}>
          <NavDropdownItem
            type={'text'}
            parentTitle={'ПНР'}
          >
            <AccessController access={[AccessEnum.MapUspd, AccessEnum.MapUspdOnly]}>
              <CustomLink
                to={'/map/network'}
                type={LinkType.ItemLink}
                // onClick={() => toggleDrawer(false)}
              >
                Карта УСПД
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.MapUspd]}>
              <CustomLink
                to={'/task/map'}
                type={LinkType.ItemLink}
              >
                Карта
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.MapUspd]}>
              <CustomLink
                to={'/pnr-entrance'}
                type={LinkType.ItemLink}
              >
                Дома
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>

        <AccessController
          access={[AccessEnum.TasksBrowse, AccessEnum.CallingMapOperator, AccessEnum.PUManaging]}
        >
          <NavDropdownItem
            type={'text'}
            parentTitle={'Заявки'}
          >
            <AccessController access={[AccessEnum.TasksBrowse]}>
              <CustomLink
                to={`tasks/browse`}
                type={LinkType.ItemLink}
              >
                Заявки
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.TasksBrowse]}>
              <CustomLink
                to={`/task/zoned-map`}
                type={LinkType.ItemLink}
              >
                Карта (назначить)
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.TasksBrowse, AccessEnum.CallingMapOperator]}>
              <CustomLink
                to={`/task/calling-map`}
                type={LinkType.ItemLink}
              >
                Карта (обзвона)
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.TasksBrowse, AccessEnum.CallingMapOperator]}>
              <CustomLink
                to={`/tasks/ppo-map`}
                type={LinkType.ItemLink}
              >
                Карта ППО
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.PUManaging]}>
              <CustomLink
                to='mcRework'
                type={LinkType.ItemLink}
              >
                Замены ПУ на доработку
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>
        <AccessController access={[AccessEnum.ComplaintsPage]}>
          <CustomLink to='complaints'>Жалобы</CustomLink>
        </AccessController>
        <AccessController access={[AccessEnum.Catalogs, AccessEnum.CatalogsMeter]}>
          <NavDropdownItem
            type={'text'}
            parentTitle={'Справочники'}
          >
            <AccessController access={[AccessEnum.CatalogsMeter]}>
              <CustomLink
                to={`/catalogs/${CatalogTypes.PU}`}
                type={LinkType.ItemLink}
              >
                Приборы учета
              </CustomLink>
            </AccessController>
            <CustomLink
              to={`/catalogs/${CatalogTypes.STAMP}`}
              type={LinkType.ItemLink}
            >
              Пломбы
            </CustomLink>
            <CustomLink
              to={`/catalogs/${CatalogTypes.KOO}`}
              type={LinkType.ItemLink}
            >
              Каналообразующее оборудование
            </CustomLink>
            <CustomLink
              to={`/catalogs/${CatalogTypes.SIM}`}
              type={LinkType.ItemLink}
            >
              SIM карты
            </CustomLink>
          </NavDropdownItem>
        </AccessController>

        <AccessController access={[AccessEnum.Storehouse, AccessEnum.StorehouseInfo]}>
          <CustomLink to='/storehouse'>Склад</CustomLink>
        </AccessController>

        <AccessController access={[AccessEnum.Registry, AccessEnum.Drive]}>
          <NavDropdownItem
            type={'text'}
            parentTitle={'Реестры'}
          >
            <AccessController access={[AccessEnum.Registry]}>
              <CustomLink
                to='registry/browse'
                type={LinkType.ItemLink}
              >
                Реестр
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.Registry]}>
              <CustomLink
                to='registry/enforse'
                type={LinkType.ItemLink}
              >
                Реестр Энфорс
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.Drive]}>
              <CustomLink
                to='registry/drive'
                type={LinkType.ItemLink}
              >
                ИД ТехПрис
              </CustomLink>
            </AccessController>
            {/*<AccessController access={[AccessEnum.Drive]}>*/}
            <CustomLink
              to='registry/mail-notifications'
              type={LinkType.ItemLink}
            >
              Почтовые уведомления
            </CustomLink>
            {/*</AccessController>*/}
          </NavDropdownItem>
        </AccessController>

        <AccessController access={[AccessEnum.CallCenterIncoming]}>
          <NavDropdownItem
            type={'text'}
            parentTitle={'Call-центр'}
          >
            <AccessController access={[AccessEnum.CallCenterIncoming]}>
              <CustomLink
                to='/callcenter'
                type={LinkType.ItemLink}
              >
                Входящие заявки
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.CallCenterResolved]}>
              <CustomLink
                to='/callcenterrequests'
                type={LinkType.ItemLink}
              >
                Обработанные заявки
              </CustomLink>
            </AccessController>
            <AccessController access={[AccessEnum.CalLCenterResolvedBashkortostan]}>
              <CustomLink
                to='/callcenterrequestsbashkortostan'
                type={LinkType.ItemLink}
              >
                Башкортостан
              </CustomLink>
            </AccessController>
          </NavDropdownItem>
        </AccessController>

        <AccessController
          access={[
            AccessEnum.Statistic,
            AccessEnum.CallingMapOperator,
            AccessEnum.TeamManagerVoormanAndExecutor,
          ]}
        >
          <CustomLink to='/dashboard'>Статистика</CustomLink>
        </AccessController>
        <NavExitWrapper>
          <AccessController access={[AccessEnum.YUNGA, AccessEnum.StorehouseChief]}>
            <NavDropdownItem
              type={'icon'}
              parentTitle={<AdminPanelSettingsRoundedIcon htmlColor={color('fontGray')} />}
              tooltipText={'Администрирование'}
            >
              <AccessController access={[AccessEnum.YUNGA]}>
                <CustomLink
                  to='/yunga'
                  type={LinkType.ItemLink}
                >
                  Yunga
                </CustomLink>
              </AccessController>
              <AccessController access={[AccessEnum.YUNGA]}>
                <CustomLink
                  to='/roles'
                  type={LinkType.ItemLink}
                >
                  Роли
                </CustomLink>
              </AccessController>
              <AccessController access={[AccessEnum.YUNGA, AccessEnum.StorehouseChief]}>
                <CustomLink
                  to='/storehouse_admin'
                  type={LinkType.ItemLink}
                >
                  Склад
                </CustomLink>
              </AccessController>
              <AccessController access={[AccessEnum.YUNGA]}>
                <CustomLink
                  to='https://sentry.sec2.ru/organizations/tpplab/issues/'
                  type={LinkType.OutLink}
                >
                  Sentry
                </CustomLink>
              </AccessController>
            </NavDropdownItem>
          </AccessController>

          <NavDropdownItem
            type={'icon'}
            parentTitle={<HelpOutlineRoundedIcon htmlColor={color('fontGray')} />}
            tooltipText={'Инструкции и помощь'}
          >
            <CustomLink
              to='https://docs.sec2.ru'
              type={LinkType.OutLink}
            >
              Инструкция
            </CustomLink>
            <CustomLink
              to='https://web.telegram.org/a/#697643914'
              type={LinkType.OutLink}
            >
              Техподдержка
            </CustomLink>
            <CustomLink
              to='https://www.youtube.com/channel/UCL8vrVbPasmK8T4y3nl1q0w'
              type={LinkType.OutLink}
            >
              Видео-инструкции
            </CustomLink>
          </NavDropdownItem>

          <Tooltip title='Журнал событий'>
            <IconButton
              onClick={handleOpenErrorsDialog}
              disabled={actionLogList.length === 0}
              sx={{ '&:hover *': { color: color('aBlue') } }}
            >
              <ReportGmailerrorredIcon htmlColor={color('fontGray')} />
            </IconButton>
          </Tooltip>

          <StorehouseUserName />

          <Tooltip title='Выйти'>
            <IconButton
              onClick={() => logOut()}
              sx={{ '&:hover *': { color: color('aBlue') } }}
            >
              <ExitToAppRoundedIcon htmlColor={color('fontGray')} />
            </IconButton>
          </Tooltip>
        </NavExitWrapper>
      </NavbarLinksWrapper>
    </>
  );
};
