import React, { FC, useState } from 'react';
import { Popover, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

const TypographyId = styled(Typography)(() => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));

interface IIdPopup {
  title?: string | React.ReactNode;
  children: React.ReactNode[] | React.ReactNode;
  sx?: SxProps<Theme>;
  horizontalPosition?: number | 'right' | 'left' | 'center';
}

export const IdPopup: FC<IIdPopup> = ({
  title = 'id',
  sx,
  children,
  horizontalPosition = 'right',
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <TypographyId
        aria-describedby={'id-popover'}
        onClick={handleClick}
        sx={sx}
      >
        {title}
      </TypographyId>
      <Popover
        id={'id-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalPosition,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontalPosition,
        }}
      >
        <Typography sx={{ p: 2 }}>
          {Array.isArray(children) ? children?.map((elem, index) => elem) : children}
        </Typography>
      </Popover>
    </>
  );
};
