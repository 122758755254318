import shallow from 'zustand/shallow';
import { StateSelector } from 'zustand';
import { createStore } from '../../utils/createStore';
import {
  ConsumableEquipment,
  NetworkElementRequest,
  NetworkElementResponse,
} from '@/dto/NetworkDto';
import {
  cancelNetworkElement,
  deleteEquippedSimNetworkElement,
  getConsumableEquipment,
  getNetworkElements,
  repeatNetworkElement,
  updateConsumableEquipment,
  updateNetworkElement,
} from '@/services/NetworkService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';

interface INetworkStore {
  loading: boolean;
  networkData?: NetworkElementResponse;
  consumableEquipment: { [key: string]: ConsumableEquipment[] };
  networkDataN: { [key: string]: NetworkElementResponse };
  getNetworkData: (taskId: string) => void;
  updateNetworkElement: (id: string, data: NetworkElementRequest) => void;
  repeatNetworkElement: (taskId: string, data: NetworkElementRequest) => void;
  deleteNetworkElementSim: (simId: string) => void;
  cancelNetworkElement: (id: string) => void;
  getConsumableEquipment: (id: string) => void;
  updateConsumableEquipment: (id: string, dataToSend: { id: string; quantity: number }[]) => void;
}

const useNetworkStoreZus = createStore<INetworkStore>(
  (set, get) => ({
    loading: false,
    networkData: undefined,
    networkDataN: {},
    consumableEquipment: {},

    getNetworkData: (taskId) => {
      set(() => ({ loading: true }));
      getNetworkElements(taskId)
        .then(({ data }) => {
          data.forEach((elem) =>
            set(() => ({ networkDataN: { ...get().networkDataN, [elem.id]: elem } }))
          );
        })
        .catch((err) => {
          useActionLog.getState().catchError(ActionLogType.ERROR, err);
        })
        .finally(() => set(() => ({ loading: false })));
    },

    updateNetworkElement: (id, data) => {
      set(() => ({ loading: true }));
      updateNetworkElement(id, data)
        .then(({ data }) => {
          set(() => ({ networkDataN: { ...get().networkDataN, [data.id]: data } }));
          useActionLog
            .getState()
            .addActionLog(ActionLogType.SUCCESS, 'Данные блока Network обновлены');
        })
        .catch((err) => {
          useActionLog.getState().catchError(ActionLogType.ERROR, err);
        })
        .finally(() => set(() => ({ loading: false })));
    },

    repeatNetworkElement: (id, data) => {
      set(() => ({ loading: true }));
      repeatNetworkElement(id, data)
        .then(({ data }) => {
          set(() => ({ networkDataN: { ...get().networkDataN, [data.id]: data } }));
          useActionLog.getState().addActionLog(ActionLogType.SUCCESS, 'Успешно');
        })
        .catch((err) => {
          useActionLog.getState().catchError(ActionLogType.ERROR, err);
        })
        .finally(() => set(() => ({ loading: false })));
    },

    deleteNetworkElementSim: (simId) => {
      set(() => ({ loading: true }));
      deleteEquippedSimNetworkElement(simId)
        .then(({ data }) => {
          set(() => ({ networkDataN: { ...get().networkDataN, [data.id]: data } }));
          useActionLog.getState().addActionLog(ActionLogType.SUCCESS, 'SIM удалена');
        })
        .catch((err) => {
          useActionLog.getState().catchError(ActionLogType.ERROR, err);
        })
        .finally(() => set(() => ({ loading: false })));
    },
    cancelNetworkElement: (id) => {
      set(() => ({ loading: true }));
      cancelNetworkElement(id)
        .then(({ data }) => {
          set(() => ({ networkDataN: { ...get().networkDataN, [data.id]: data } }));
          useActionLog.getState().addActionLog(ActionLogType.SUCCESS, 'Блок Network отменен');
        })
        .catch((err) => {
          useActionLog.getState().catchError(ActionLogType.ERROR, err);
        })
        .finally(() => set(() => ({ loading: false })));
    },
    getConsumableEquipment: (id) => {
      set(() => ({ loading: true }));
      getConsumableEquipment(id)
        .then(({ data }) => {
          set(() => ({ consumableEquipment: { ...get().consumableEquipment, [id]: data } }));
        })
        .catch((err) => {
          useActionLog.getState().catchError(ActionLogType.ERROR, err);
        })
        .finally(() => set(() => ({ loading: false })));
    },
    updateConsumableEquipment: (id, dataToSend) => {
      set(() => ({ loading: true }));
      updateConsumableEquipment(dataToSend)
        .then(() => {
          get().getConsumableEquipment(id);
          useActionLog
            .getState()
            .addActionLog(ActionLogType.SUCCESS, 'Расходное оборудование обновлено');
        })
        .catch((err) => {
          useActionLog.getState().catchError(ActionLogType.ERROR, err);
        })
        .finally(() => set(() => ({ loading: false })));
    },
  }),
  'Network'
);

export const useNetworkStore: <T>(selector: StateSelector<INetworkStore, T>) => T = (selector) =>
  useNetworkStoreZus(selector, shallow);
