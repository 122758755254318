import { Table } from 'antd';
import { IncomingCallAfterSms, SubscriberWhomImpossibleSendSmsCount } from '@/types/Types';
import {
  getSmsStatusStatisticsSecondTableColumns,
  useSmsStatusStatisticsSecondTableExtraColumns,
} from './utils';
import { useMemo } from 'react';

interface SmsStatusStatisticsSecondTableProps {
  data: IncomingCallAfterSms[] | SubscriberWhomImpossibleSendSmsCount[];
  withExtraColumns?: boolean;
  className: string;
}

export const SmsStatusStatisticsSecondTable = ({
  data,
  className,
  withExtraColumns,
}: SmsStatusStatisticsSecondTableProps) => {
  const extraColumns = useSmsStatusStatisticsSecondTableExtraColumns();
  const columns = useMemo(() => getSmsStatusStatisticsSecondTableColumns(className), [className]);
  return (
    <Table
      columns={[...columns, ...(withExtraColumns ? extraColumns : [])]}
      dataSource={data}
      scroll={{ y: 300, x: 1500 }}
      style={{ tableLayout: 'fixed', width: '89%', margin: 'auto', marginTop: 50 }}
      bordered
      locale={{ emptyText: 'Нет данных' }}
      size='small'
      pagination={{
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `Показано ${range[0]}-${range[1]} из ${total} записей`,
        // pageSizeOptions: ['10', '20', '50', '100'], // Опции для выбора количества записей на странице
        locale: {
          items_per_page: 'записей на странице',
          jump_to: 'Перейти',
          jump_to_confirm: 'Подтвердить',
          page: 'стр.',
          prev_page: 'Предыдущая страница',
          next_page: 'Следующая страница',
          prev_5: 'Предыдущие 5 страниц',
          next_5: 'Следующие 5 страниц',
          prev_3: 'Предыдущие 3 страницы',
          next_3: 'Следующие 3 страницы',
        },
      }}
    />
  );
};
