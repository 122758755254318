import { BaseUI } from '@/types/Types';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import { IconButton } from '@mui/material';
import { ZoomUIScaleContainer, ZoomUIButtonContainer } from './ZoomUI.styled';

export interface ZoomUIProps extends BaseUI {
  withRotation?: boolean;
  zoomStep?: number;
}

const ZoomUI = ({ children, withRotation, className, zoomStep = 0.1 }: ZoomUIProps) => {
  const minScale = 0.5;
  const maxScale = 10;
  const defaultScale = 1;
  const defaultRotate = 0;

  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [version, setVersion] = useState(0);
  const isDraggable = true;

  const zoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const zoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale <= minScale ? minScale : newScale);
  };
  const reset = () => {
    setScale(defaultScale);
    setRotate(0);
    setVersion(version + 1);
  };

  const onRotate = () => {
    setRotate(rotate - 90);
  };

  return (
    <div className={className}>
      <Draggable
        disabled={!isDraggable}
        key={version}
      >
        <div>
          <ZoomUIScaleContainer
            rotate={rotate}
            scale={scale}
            onDoubleClick={zoomIn}
          >
            {children}
          </ZoomUIScaleContainer>
        </div>
      </Draggable>

      <ZoomUIButtonContainer className='ZoomUIButtonContainer'>
        <IconButton onClick={zoomIn}>
          <ZoomInIcon color='primary' />
        </IconButton>
        <IconButton onClick={zoomOut}>
          <ZoomOutIcon />
        </IconButton>
        {withRotation && (
          <IconButton onClick={onRotate}>
            <RotateLeftRoundedIcon />
          </IconButton>
        )}
        <IconButton onClick={reset}>Сброс</IconButton>
      </ZoomUIButtonContainer>
    </div>
  );
};

export default ZoomUI;
