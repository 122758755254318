import { Paper } from '@mui/material';
import React from 'react';
import { CallCenterBashkortostanComponent } from '@/components/callcenter/bashkortostan/CallCenterBashkortostanComponent';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const CallCenterBashkortostanPage = () => {
  useAccess({
    access: [AccessEnum.CalLCenterResolvedBashkortostan],
  });
  useMetaTitle(`Колл-центр Башкортостан`);

  return (
    <Paper className='main-container'>
      <CallCenterBashkortostanComponent />
    </Paper>
  );
};
export default CallCenterBashkortostanPage;
