import { Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { ExPuInfoResponse } from '@/dto/TransformerDto';
import DateField from '@/components/DateField';
import { useStatus } from '@/components/features/taskEditor/panel/store';

interface TransPuProps {
  data: ExPuInfoResponse;
}

const GRID5 = 12 / 5;

export const TransPu: FC<TransPuProps> = ({ data }) => {
  const { register, setValue } = useFormContext();
  const { isDisabledAll } = useStatus(
    (state) => ({
      isDisabledAll: state.isDisabledAll,
    }),
    shallow
  );

  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography variant='inherit'>Существующий ПУ:</Typography>
          <TextField
            id='pu-type'
            label=''
            variant='standard'
            sx={{ width: '200px' }}
            {...register(`exPuInfoResponse.type`, {
              value: data?.type,
            })}
            disabled
            // disabled={isDisabledAll()}
          />
        </Grid>
        <Grid
          item
          xs={GRID5}
        >
          <TextField
            id='pu-number'
            label='Номер'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`exPuInfoResponse.number`, {
              value: data?.number,
            })}
            disabled
            // disabled={isDisabledAll()}
          />
        </Grid>
        <Grid
          item
          xs={GRID5}
        >
          <TextField
            id='pu-editionYear'
            label='Год выпуска'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`exPuInfoResponse.editionYear`, {
              value: data?.editionYear,
            })}
            disabled
            // disabled={isDisabledAll()}
          />
        </Grid>
        <Grid
          item
          xs={GRID5}
        >
          <DateField
            label={'Дата проверки'}
            defaultValue={data?.verificationDate}
            disabled
            // disabled={isDisabledAll()}
            hookFormString={`exPuInfoResponse.verificationDate`}
          />
        </Grid>
        <Grid
          item
          xs={GRID5}
        >
          <DateField
            label={'Дата проверки'}
            defaultValue={data?.nextVerificationDate}
            disabled
            // disabled={isDisabledAll()}
            hookFormString={`exPuInfoResponse.nextCheckDate`}
          />
        </Grid>
        <Grid
          item
          xs={GRID5}
        />
        <Grid
          item
          xs={GRID5}
        >
          <TextField
            id='pu-stampNumber'
            label='№ пломбы'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`exPuInfoResponse.stampNumber`, {
              value: data?.stampNumber,
            })}
            disabled
            // disabled={isDisabledAll()}
          />
        </Grid>
        <Grid
          item
          xs={GRID5}
        >
          <TextField
            id='pu-kkiStamp'
            label='№ пломбы ККИ'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`exPuInfoResponse.kkiStamp`, {
              value: data?.kkiStamp,
            })}
            disabled
            // disabled={isDisabledAll()}
          />
        </Grid>
        <Grid
          item
          xs={GRID5}
        >
          <TextField
            id='pu-aiNumber'
            label='№ антимагнитного индикатора'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`exPuInfoResponse.aiNumber`, {
              value: data?.aiNumber,
            })}
            disabled
            // disabled={isDisabledAll()}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
