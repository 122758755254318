import { useCatalog } from '@/hooks/CatalogHook';
import { IComplaintItem } from '../types';
import { useComplaintStore } from '../store';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { color } from '@/styles/mixins';
import { InfoComplaintBlockLabel } from './InfoComplaintBlock.styled';

type ResponsibleProps = {
  selectedCompliant: IComplaintItem;
  disableEdit?: boolean;
};

const ResponsibleBlock = ({ selectedCompliant, disableEdit }: ResponsibleProps) => {
  const taskGroupMap = useCatalog((state) => state.taskGroupMap);

  const setIsAddTaskToComplaintOpen = useComplaintStore(
    (state) => state.setIsAddTaskToComplaintOpen
  );
  return (
    <Stack
      style={{ borderStyle: 'dashed', borderRadius: 10, color: color('gray'), borderWidth: '1px' }}
      p={2}
      mt={5}
    >
      <Grid
        xs={12}
        container
      >
        <Grid
          item
          xs={7}
        >
          <InfoComplaintBlockLabel>Заявка в YODA на которую создана жалоба</InfoComplaintBlockLabel>
        </Grid>

        <Grid
          item
          xs={5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'end'}
          gap={1}
        >
          {selectedCompliant?.complaint.reasonTaskNumber ? (
            <>
              <span
                style={{
                  cursor: 'pointer',
                  color: color('muiBlue'),
                }}
                onClick={() =>
                  window.open(`/task/edit/${selectedCompliant?.complaint.reasonTaskId}`)
                }
              >
                {selectedCompliant?.complaint.reasonTaskNumber}
              </span>
              <IconButton
                disabled={disableEdit}
                onClick={() => setIsAddTaskToComplaintOpen(true)}
              >
                <EditIcon color={disableEdit ? undefined : 'primary'} />
              </IconButton>
            </>
          ) : (
            <Button
              disabled={disableEdit}
              onClick={() => setIsAddTaskToComplaintOpen(true)}
            >
              внести
            </Button>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ResponsibleBlock;
