import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import OdpuStatistic from '@/components/statistics/OdpuStatistic/OdpuStatistic';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const OdpuStatisticPage = () => {
  useAccess({
    access: [AccessEnum.Statistic],
  });
  useMetaTitle(`Статистика ОДПУ`);
  return <OdpuStatistic />;
};

export default OdpuStatisticPage;
