import React, { useEffect } from 'react';
import { AccessEnum } from '@/types/roles';
import { IconButton, Tooltip } from '@mui/material';
import { AccessController } from '@/components/common/AccessController';
import BoltIcon from '@mui/icons-material/Bolt';
import { syncErrorCatch } from '@/hooks/ActionLogHook';
import { checkUUID, PNR_PAGES, TASK_UUID_REG_EXP } from './utils';
import { useLocation } from 'react-router-dom';
import { useAccess } from '@/hooks/useAccess';

const NavbarBolt = () => {
  const pathName = useLocation()?.pathname;
  const hasAccess = useAccess({
    access: [AccessEnum.EvreyoneExceptContractors],
    stopRedirect: true,
  });

  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      if (ev.key === 'F4') handleEditNavigation();
    };
    document.addEventListener('keydown', keydownHandler, false);
    return () => {
      document.removeEventListener('keydown', keydownHandler, false);
    };
  }, [pathName, hasAccess]);

  const handleEditNavigation = () => {
    if (!hasAccess) return;

    navigator.clipboard.readText().then((data) => {
      const validUUID = checkUUID(data);
      if (validUUID) {
        let editUrl = `/task/edit/`;

        if (pathName.includes(PNR_PAGES)) {
          editUrl = `/pnr-entrance/edit/`;
        }
        window.open(editUrl + validUUID);
      } else {
        syncErrorCatch(
          `Переход на страницу редактирования не возможен. Текст в буфере обмена не подходит под формат UUID`,
          {
            bufferText: data,
            testRegExp: TASK_UUID_REG_EXP,
          }
        );
      }
    });
  };

  return (
    <AccessController access={[AccessEnum.EvreyoneExceptContractors]}>
      <Tooltip title='Горячая клавиша - f4'>
        <IconButton onClick={handleEditNavigation}>
          <BoltIcon color='info' />
        </IconButton>
      </Tooltip>
    </AccessController>
  );
};

export default NavbarBolt;
