import { Button } from '@mui/material';
import React from 'react';
import { useActionLog } from '@/hooks/ActionLogHook';

interface Props {
  openHandler?: (elem: boolean) => void;
  checkError?: boolean;
  children?: any;
  disabled?: boolean;
  onClick?: () => void;
}

export const PopupOpenButton: React.FC<Props> = (props) => {
  const { openHandler, children, checkError = true, disabled = false, onClick } = props;
  const { catchError } = useActionLog();

  const showActionError = () => catchError('Необходимо выбрать заявки в таблице', {});

  const showPopup = () => {
    if (checkError) {
      openHandler && openHandler(true);
      onClick && onClick();
    } else {
      showActionError();
    }
  };

  return (
    <div>
      <Button
        onClick={showPopup}
        disabled={disabled}
      >
        {children}
      </Button>
    </div>
  );
};
