import React from 'react';
import StatisticCard from '../ui/StatisticCard/StatisticCard';
import { Grid } from '@mui/material';
import { AccessController } from '../common/AccessController';
import { AccessEnum } from '@/types/roles';

const DashBoard = () => {
  return (
    <Grid
      container
      spacing={4}
    >
      <AccessController access={[AccessEnum.Statistic]}>
        <StatisticCard
          title={'АСУМБ'}
          img={'asumb'}
          navigateTo={'asumb'}
        />
      </AccessController>

      <AccessController access={[AccessEnum.Statistic, AccessEnum.TeamManagerVoormanAndExecutor]}>
        <StatisticCard
          title={'Статистика Заявок'}
          img={'statistic'}
          navigateTo={'requests'}
        />
      </AccessController>

      <AccessController
        access={[
          AccessEnum.Statistic,
          AccessEnum.CallingMapOperator,
          AccessEnum.TeamManagerVoormanAndExecutor,
        ]}
      >
        <StatisticCard
          title={'Статистика взаимодействия с абонентами'}
          img={'callCenter'}
          navigateTo={'interaction'}
        />
      </AccessController>

      <AccessController access={[AccessEnum.Statistic]}>
        <StatisticCard
          title={'ОДПУ'}
          img={'analysis'}
          navigateTo={'odpu'}
        />
      </AccessController>
    </Grid>
  );
};
export default DashBoard;
