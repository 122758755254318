import { TextField } from '@mui/material';

export const METER_CHANGE_REWORK_STATIC_OPTIONS = [
  {
    field: 'oldTariff',
    minWidth: 50,
    editable: true,
    renderHeader: () => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тариф</div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>сущ.Пу</div>
      </div>
    ),
  },
  {
    field: 'exT1',
    headerName: 'Сущ. Т1',
    minWidth: 130,
    editable: true,
  },
  {
    field: 'exT2',
    headerName: 'Сущ. Т2',
    minWidth: 130,
    editable: true,
  },
  {
    field: 'exT3',
    headerName: 'Сущ. Т3',
    minWidth: 130,
    editable: true,
  },
  {
    field: 'breakerTechDesicion',
    minWidth: 150,
    editable: false,
    renderHeader: () => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Автомат</div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тех.решение</div>
      </div>
    ),
  },
  {
    field: 'newTariff',
    minWidth: 50,
    editable: true,
    renderHeader: () => (
      <div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>Тариф</div>
        <div style={{ maxHeight: '15px', position: 'relative', top: '-23px' }}>нов.ПУ</div>
      </div>
    ),
  },
  {
    field: 'newT1',
    headerName: 'Т1',
    minWidth: 80,
    editable: true,
  },
  {
    field: 'newT2',
    headerName: 'Т2',
    minWidth: 80,
    editable: true,
  },
  {
    field: 'newT3',
    headerName: 'Т3',
    minWidth: 80,
    editable: true,
  },

  {
    field: 'newPUMac',
    headerName: 'МАС адр.',
    width: 170,
    editable: true,
  },
  {
    field: 'newPUNumber',
    headerName: 'Номер ПУ',
    minWidth: 150,
    editable: true,
  },
  {
    field: 'newPUType',
    headerName: 'Тип ПУ',
    minWidth: 150,
    editable: true,
  },
  {
    field: 'newPUStamp',
    headerName: 'Номер пломба ПУ',
    minWidth: 150,
    editable: true,
  },
  {
    field: 'vldMsg',
    minWidth: 450,
    editable: false,
    headerName: 'Валидационное сообщение',
    renderCell: (params: any) => {
      return (
        <TextField
          style={{ textAlign: 'left' }}
          value={params.row.vldMsg}
          multiline
          fullWidth={true}
          maxRows={2}
        />
      );
    },
  },
];
