import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, Grid, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';

import { useExpendableToolsPopup } from './store';
import { PpoInputExpendabResponse } from '@/dto/taskmap/task/TaskResultDto';
import {
  editVruExpendableToolsList,
  addVruExpendableTool,
  deletePpoVruExpendableTool,
} from '@/services/TaskService';
import { AutocompleteValue } from '../../inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { useActionLog, ActionLogType } from '@/hooks/ActionLogHook';
import {
  ExpendableToolsPopupFormInputWrapper,
  ExpendableToolsPopupFormWrapper,
  ExpendableToolsPopupIconButton,
  ExpendableToolsPopupFormButtonsWrapper,
  ExpendableToolsPopupFormRow,
} from './ExpendableToolsPopup.styled';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useDisabledAll';

interface ExpendableToolsFormData {
  [x: string]: PpoInputExpendabResponse;
}

const ExpendableToolsPopupForm = () => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const methods = useForm();
  const { register, handleSubmit, reset } = methods;

  const {
    expendableTools,
    ppoVruInputId,
    forceReload,
    addExpendableTool,
    deleteExpendableTool,
    setIsExpendableToolsPopupOpen,
  } = useExpendableToolsPopup(
    ({
      forceReload,
      expendableTools,
      ppoVruInputId,
      addExpendableTool,
      deleteExpendableTool,
      setIsExpendableToolsPopupOpen,
    }) => ({
      forceReload,
      expendableTools,
      ppoVruInputId,
      addExpendableTool,
      deleteExpendableTool,
      setIsExpendableToolsPopupOpen,
    })
  );

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { catchError, addActionLog } = useActionLog();
  const [isLoading, setIsLoading] = useState(false);

  const successActions = (successText: string, withClose = false) => {
    forceReload();
    addActionLog(ActionLogType.SUCCESS, successText);
    withClose && handleClose();
  };

  const handleClose = () => setIsExpendableToolsPopupOpen(false);

  const handleEdit = (data: ExpendableToolsFormData) => {
    const expendableTools = [];
    for (const expendableToolId in data) {
      const expendableTool = data[expendableToolId];
      expendableTool.id = expendableToolId;
      expendableTool.ppoInputId = ppoVruInputId;
      expendableTool.count = expendableTool.count
        ? Number(expendableTool.count)
        : expendableTool.count;
      expendableTools.push(expendableTool);
    }

    setIsLoading(true);
    editVruExpendableToolsList(expendableTools)
      .then(() =>
        successActions('Успешное редактирование списка дополнительное оборудование', true)
      )
      .catch((err) => catchError('Ошибка редактирования списка дополнительное оборудование', err))
      .finally(() => setIsLoading(false));
  };

  const handleAdd = async () => {
    setIsLoading(true);
    addVruExpendableTool({ ppoInputId: ppoVruInputId })
      .then(({ data }) => {
        addExpendableTool(data);
        successActions('Успешное добавление дополнительного оборудования');
      })
      .catch((err) => catchError('Ошибка дополнительного оборудования', err))
      .finally(() => setIsLoading(false));
  };

  const handleDelete = async (expendableToolId: string) => {
    setIsLoading(true);
    deletePpoVruExpendableTool(expendableToolId)
      .then(() => {
        deleteExpendableTool(expendableToolId);
        successActions('Успешное удаления дополнительного оборудования');
        reset();
      })
      .catch((err) => catchError('Ошибка удаления дополнительного оборудования', err))
      .finally(() => setIsLoading(false));
  };

  return (
    <FormProvider {...methods}>
      <ExpendableToolsPopupFormWrapper disabled={isLoading}>
        {expendableTools?.map((expendableTool, index) => (
          <ExpendableToolsPopupFormRow key={expendableTool?.id + index}>
            <ExpendableToolsPopupIconButton
              onClick={() => handleDelete(expendableTool.id)}
              disabled={disabledAll || onlyShow}
            >
              <DeleteForeverOutlinedIcon />
            </ExpendableToolsPopupIconButton>

            <ExpendableToolsPopupFormInputWrapper width={'400px'}>
              <AutocompleteValue
                title='Тип оборудования'
                defaultValue={expendableTool?.expendableId}
                fieldName={`${expendableTool?.id}.expendableId`}
                values={getCatalogMapWithErr('expendable')}
              />
            </ExpendableToolsPopupFormInputWrapper>
            <ExpendableToolsPopupFormInputWrapper width={'100px'}>
              <TextField
                type='number'
                id='count'
                label='Количество'
                variant='standard'
                defaultValue={expendableTool?.count}
                {...register(`${expendableTool?.id}.count`, {
                  value: expendableTool?.count,
                })}
              />
            </ExpendableToolsPopupFormInputWrapper>
          </ExpendableToolsPopupFormRow>
        ))}

        <Grid>
          <Button
            onClick={handleAdd}
            startIcon={<AddIcon />}
            disabled={disabledAll || onlyShow}
          >
            Добавить
          </Button>
        </Grid>

        <Grid>
          <ExpendableToolsPopupFormButtonsWrapper>
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(handleEdit)}
              disabled={disabledAll || onlyShow}
            >
              Сохранить
            </LoadingButton>
            <Button onClick={handleClose}>Отменить</Button>
          </ExpendableToolsPopupFormButtonsWrapper>
        </Grid>
      </ExpendableToolsPopupFormWrapper>
    </FormProvider>
  );
};

export default ExpendableToolsPopupForm;
