import { useEffect } from 'react';
import '@/components/maps/callingMap/utils/webRTC';
import { useCallingMapOperator } from '@/hooks/useQuery/useCallingMapOperator';

import { getWebRTCClient } from '@/components/maps/callingMap/utils/vats';

export const useWebRTCClient = () => {
  useCallingMapOperator();
  useEffect(() => {
    const client = getWebRTCClient();

    if (client?.isInitialized() && !client?.isConnected()) {
      client?.connect();
    }

    return () => {
      if (client?.isInitialized()) client?.disconnect();
    };
  }, []);
};
