import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { useMemo } from 'react';

export const useDisabledAll = (): boolean => {
  const disabled = useAccess({
    access: [AccessEnum.DisabledAll],
    stopRedirect: true,
  });

  return useMemo(() => disabled, [disabled]);
};
