import { Typography } from '@mui/material';
import React from 'react';
import { frontendVersion } from './utils';
import { useSession } from '@/app/sentry';
import CopyButton from '@/components/button/CopyButton';
import { BaseUI } from '@/types/Types';

export interface FrontendVersionProps extends BaseUI {
  withSessionId?: boolean;
}

const FrontendVersion = ({ withSessionId, className }: FrontendVersionProps) => {
  const { sessionId } = useSession();

  return (
    <div className={className}>
      <Typography
        textAlign={'center'}
        variant={'subtitle1'}
      >
        Версия: {frontendVersion()}
      </Typography>
      {withSessionId && (
        <Typography
          textAlign={'center'}
          variant={'subtitle1'}
        >
          <span style={{ marginRight: 5 }}>Сессия: {sessionId}</span>
          <CopyButton
            textToCopy={sessionId}
            onlyIcon
            showToolTip
          />
        </Typography>
      )}
    </div>
  );
};

export default FrontendVersion;
