export const KEYS = {
  allContract: ['allContract'],
  callingMapOperator: ['callingMapOperator'],
  allResponsables: ['allResponsables'],
  allBillTypes: ['allBillTypes'],
  allStorehouses: ['allStorehouses'],
  allContractors: ['storehouse', 'allContractors'],
  allStorehouseScanFields: ['storehouse', 'allMeterTypeScanField'],
  allStorehouseOsScanFields: ['storehouse', 'allOsScanField'],
  allPuTypes: ['allPuTypes'],
  allNetworkElementTypes: ['allNetworkElementTypes'],
  storehouseCacheNames: ['storehouse', 'cacheNames'],
  storehouseSupplyGroups: ['storehouse', 'supplyGroups'],
  storehouseUserSupplyGroup: ['storehouse', 'userSupplyGroup'],
  storehouseTransitBills: ['storehouse', 'transitBills'],
  storehouseAcceptedTransitBills: ['storehouse', 'acceptedTransitBills'],
  storehouseFactories: ['storehouse', 'factories'],
  storehouseStatisticStates: ['storehouse', 'statistic', 'states'],

  allCreators: ['allCreators'],
  puCompleteness: ['storehouse', 'puCompleteness'],
  equipmentState: ['storehouse', 'equipmentState'],
  puDefectCause: ['storehouse', 'puDefectCause'],
  neDefectCause: ['storehouse', 'neDefectCause'],
  checkTaskNumber: ['checkTaskNumber'],
  puOwner: ['storehouse', 'puOwner'],
  houseOdpuReplacement: ['houseOdpuReplacement'],
  allHermes: ['allHermes'],
  pnrPlannings: ['pnr', 'plannings'],
  pnrHouseData: ['pnr', 'houseData'],
  pnrHouseEntranceData: ['pnr', 'houseEntranceData'],
  pnrHouseOrders: ['pnr', 'houseOrders'],
  taskSiblings: ['taskEdit', 'siblings'],
  transformerAmperage: ['taskEdit', 'transformerAmperage'],
};
