import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { ApiFindAllContract } from '@/services/YodaRestService';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { ApiFindAllContractResponse } from '@/types/Types';
import { catchError } from '../ActionLogHook';
import { KEYS } from './Keys';
import { useMemo } from 'react';

function convertIntoFilter(data: ApiFindAllContractResponse): SelectFilterOptType[] {
  return data.map((gr: any) => ({ value: gr.id, label: gr.title }));
}

function convertIntoArray(data: ApiFindAllContractResponse) {
  return data.map((item) => [item.id, item.title]);
}

type ReturnFilterData = 'convertIntoFilter';
type ReturnArrayData = 'convertIntoArray';
type ReturnMapData = 'convertIntoMap';
type useAllContractQueryReturnData = ReturnFilterData | ReturnArrayData | ReturnMapData;

export function useAllContractQuery<B extends useAllContractQueryReturnData = ReturnFilterData>(
  returnDataType?: B
): B extends ReturnFilterData
  ? SelectFilterOptType[]
  : B extends ReturnArrayData
  ? string[][]
  : Map<string, string>;
export function useAllContractQuery(
  returnDataType: useAllContractQueryReturnData = 'convertIntoFilter'
) {
  const { data: axiosResponse } = useQuery({
    queryKey: KEYS.allContract,
    staleTime: Infinity,
    queryFn: ApiFindAllContract,
    onError: (error: AxiosError<{ message: string }> | Error) => {
      if (axios.isAxiosError(error)) {
        catchError(error?.response?.data?.message ?? 'Непредвиденная ошибка', {});
      } else {
        catchError('Непредвиденная ошибка', {});
      }
    },
  });

  const filterData = useMemo(
    () => (axiosResponse?.data ? convertIntoFilter(axiosResponse.data) : []),
    [axiosResponse]
  );

  const arrayData = useMemo(
    () => (axiosResponse?.data ? convertIntoArray(axiosResponse?.data) : [[]]),
    [axiosResponse]
  );

  const mapData = useMemo(
    () =>
      axiosResponse?.data
        ? new Map(axiosResponse?.data?.map((item) => [item.id, item.title]))
        : new Map(),
    [axiosResponse]
  );

  if (returnDataType === 'convertIntoFilter') {
    return filterData;
  }

  if (returnDataType === 'convertIntoArray') {
    return arrayData;
  }

  if (returnDataType === 'convertIntoMap') {
    return mapData;
  }
}
