import React, { useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import {
  MailNotificationsFileResponse,
  MailNotificationsFolderResponse,
} from '@/dto/MailNotificationsDto';
import {
  GetMailNotificationsFiles,
  GetMailNotificationsFolders,
  UploadZipFile,
} from '@/services/MailNotificationsService';
import FolderItem from './FolderItem';
import FileItem from './FileItem';
import {
  ButtonWrapper,
  MailNotificationsWrapper,
  VerticalLineWrapper,
} from '@/components/MailNotifications/MailNotifications.styled';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';

export const MailNotifications = () => {
  const [folders, setFolders] = useState<MailNotificationsFolderResponse[]>([]);
  const [files, setFiles] = useState<MailNotificationsFileResponse[]>([]);
  const { catchError, addActionLog } = useActionLog();

  const getFolders = () => {
    GetMailNotificationsFolders()
      .then((res) => {
        setFolders(res.data);
      })
      .catch((err) => {
        catchError(err?.message ?? 'Ошибка получения списка архивов', err);
      });
  };

  useEffect(getFolders, []);

  const handleClickFolder = (id: string) => {
    GetMailNotificationsFiles(id)
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        catchError(err?.message ?? 'Ошибка загрузки списка файлов', err);
      });
  };

  return (
    <MailNotificationsWrapper>
      <BoxShadow sx={{ width: '100%', mr: 6, mb: 3, mt: 6 }}>
        {folders.map((elem, index) => (
          <FolderItem
            getFolders={getFolders}
            onClick={() => handleClickFolder(elem.id)}
            {...elem}
            rowIndex={index + 1}
            key={elem.id}
          />
        ))}
      </BoxShadow>
      <BoxShadow sx={{ width: '100%', ml: 6, mb: 3, mt: 6 }}>
        {files.map((elem) => (
          <FileItem
            {...elem}
            key={elem.id}
          />
        ))}
      </BoxShadow>
      <VerticalLineWrapper />

      <label htmlFor='input__file'>
        <input
          type='file'
          accept='.zip'
          id='input__file'
          className='input input__file'
          hidden={true}
          onChange={(e) => {
            e.target.files &&
              UploadZipFile(e.target.files[0])
                .then(() => addActionLog(ActionLogType.SUCCESS, 'Успешная загрузка архива'))
                .catch((err) => {
                  catchError(err?.message ?? 'Ошибка загрузки архива', err);
                })
                .finally(getFolders);
          }}
          required={true}
          multiple={false}
        />

        <ButtonWrapper>
          <AddRoundedIcon />
          Загрузить архив
        </ButtonWrapper>
      </label>
    </MailNotificationsWrapper>
  );
};
