import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import {
  CatalogsBrowse,
  ICatalogsBrowse,
} from '@/components/sections/CatalogsBrowse/CatalogBrowse';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { makeTitle } from './utils';

type CatalogsPageProps = ICatalogsBrowse;

const CatalogsPage = (props: CatalogsPageProps) => {
  if (props.type === CatalogTypes.PU) {
    useAccess({
      access: [AccessEnum.CatalogsMeter],
    });
  } else {
    useAccess({
      access: [AccessEnum.Catalogs],
    });
  }
  useMetaTitle(makeTitle(props.type));
  return <CatalogsBrowse {...props} />;
};

export default CatalogsPage;
