import axios, { AxiosResponse } from 'axios';
import { ReplacementRequest } from '@/dto/taskmap/task/TaskResultDto';
import { HOST, yodaGetAccessToken } from './YodaRestService';
import { axiosGet, axiosPost, axiosPut } from './helpers';

export async function saveReplacementOld(
  id: string,
  replacementRequest: ReplacementRequest
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}`;
  return axiosPost(url, replacementRequest);
}

export async function saveReplacement(
  id: string,
  replacementRequest: ReplacementRequest
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/odpu/${id}/smr`;
  return axiosPut(url, replacementRequest);
}

export async function toCancelReplacement(
  id: string,
  replacementReason: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}/cancel`;
  const token = await yodaGetAccessToken();
  return axios.post(url, replacementReason, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
  });
}

export async function toRepeatInstallationReplacement(
  id: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}/repeat-installation`;
  return axiosPost(url, null);
}

export async function toSwapReplacement(
  currentId: string,
  targetId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${currentId}/swap/${targetId}`;
  return axiosPost(url, null);
}

export async function toInstalledIncorrect(
  id: string,
  reason: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}/installed-incorrect/${reason}`;
  return axiosPost(url, null);
}
export async function removeByguarantee(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}/removed-by-guarantee`;
  return axiosPost(url, null);
}

export async function oldPUIndicationsIncorrect(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/measurement/old/${id}`;
  return axiosPost(url, null);
}

export async function findYungaAnalyzeLogByReplacementId(
  replacementId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${replacementId}/analyzer`;
  return axiosGet(url);
}

export async function getLastYungaAnalyzeValidationMessage(
  taskId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/task/${taskId}/last-yunga-analyze-validation-message`;
  return axiosGet(url);
}

export async function yungaAnalyzeByReplacementId(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}/analyzer`;
  return axiosPost(url, null);
}

export async function changeStatus(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}/actual`;
  return axiosPost(url, {});
}

export async function replacementSendAct(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${id}/act/status/next`;
  return axiosPost(url, {});
}
export async function fidnReplacementById(id: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement?id=${id}`;
  return axiosGet(url);
}
export async function toSwapReplacementByTaskId(
  replacementId: string,
  taskId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/replacement/${replacementId}/move/${taskId}`;
  return axiosPost(url, null);
}
