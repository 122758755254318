import { Box, InputLabel, Modal, TextField } from '@mui/material';
import React, { FC } from 'react';
import { usePNRStore } from '../store';
import { Button } from '@material-ui/core';
import { ApiSaveListHouseEntranceData } from '@/services/YodaRestService';
import { useActionLog } from '@/hooks/ActionLogHook';

interface IEntranceEnumerationModal {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateHouseEntranceData?: () => void;
}

export const EntranceEnumerationModal: FC<IEntranceEnumerationModal> = ({
  open,
  setOpen,
  updateHouseEntranceData,
}) => {
  const { houseEntranceData, houseData, entranceIdx } = usePNRStore((state) => ({
    houseEntranceData: state.houseEntranceData,
    houseData: state.houseData,
    entranceIdx: state.entranceIdx,
  }));

  const { catchError } = useActionLog();

  const [startWithNumber, setStartWithNumber] = React.useState<string>('');
  const [endWithNumber, setEndWithNumber] = React.useState<string>('');

  const clearAndCloseModal = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (entranceIdx && entranceIdx.length > 0) {
      updateValues(Math.min(...entranceIdx).toString());
    }
  }, [entranceIdx]);

  const saveAndCloseModal = () => {
    if (startWithNumber && endWithNumber) {
      let firstNumber = parseInt(startWithNumber);
      const lastNumber = parseInt(endWithNumber);
      if (houseEntranceData && houseData) {
        let i = 0;
        while (firstNumber <= lastNumber) {
          houseEntranceData[i].entranceNumber = firstNumber;
          firstNumber++;
          i++;
        }
        ApiSaveListHouseEntranceData(houseData.houseId, houseEntranceData)
          .then((res) => {
            setOpen(false);
            if (updateHouseEntranceData) {
              updateHouseEntranceData();
            }
          })
          .catch((error) => {
            catchError('Ошибка сохранения данных', error);
          });
      }
    } else {
      catchError('Введите значение', {});
    }
  };

  const updateValues = (startWithValue: string) => {
    setStartWithNumber(startWithValue);
    const firstNumber = parseInt(startWithValue);
    if (!isNaN(firstNumber)) {
      if (houseData && houseData.entranceCount) {
        setEndWithNumber((firstNumber + (houseData.entranceCount - 1)).toString());
      } else {
        catchError('Нет данных о количестве подъездов', {});
      }
    } else {
      catchError('Введите числовое значение', {});
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={clearAndCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 20,
            p: 4,
          }}
        >
          <div
            style={{
              marginBottom: '20px',
              fontSize: '20px',
              fontWeight: '500',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            Внесите изменения
          </div>
          <InputLabel
            id='entrance-num-label'
            sx={{ fontWeight: '600', marginLeft: '35px' }}
          >
            Номера подъездов
          </InputLabel>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              marginLeft: '20px',
              marginTop: '10px',
            }}
          >
            <InputLabel
              id='entrance-num-label'
              sx={{ fontWeight: '600', marginLeft: '20px' }}
            >
              с
            </InputLabel>
            <TextField
              sx={{ marginLeft: '15px' }}
              value={startWithNumber}
              variant='standard'
              onChange={(e) => updateValues(e.target.value)}
            />
            <InputLabel
              id='entrance-num-label'
              sx={{ fontWeight: '600', marginLeft: '20px' }}
            >
              по
            </InputLabel>
            <TextField
              sx={{ marginLeft: '15px' }}
              disabled
              value={endWithNumber}
              variant='standard'
            />
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              variant='contained'
              onClick={saveAndCloseModal}
            >
              Сохранить
            </Button>
            <Button
              style={{ marginLeft: '20px' }}
              onClick={clearAndCloseModal}
            >
              Отмена
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
