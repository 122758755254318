import { useQuery } from 'react-query';
import { KEYS } from './Keys';
import { useActionLog } from '@/hooks/ActionLogHook';
import { FetchCathError } from '@/types/Types';
import { SupplyBillApiGetBillTypes } from '@/services/SupplyBillService';

export function useAllBillTypes() {
  const { fetchCatch } = useActionLog();
  return useQuery({
    queryKey: KEYS.allBillTypes,
    staleTime: Infinity,
    queryFn: SupplyBillApiGetBillTypes,
    onError: (error: FetchCathError) => fetchCatch(error, 'Ошибка загрузки типов накладных'),
  });
}
