import React from 'react';
import { Roles } from '../../components/features/roles/Roles';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const RolesPage = () => {
  useAccess({
    access: [AccessEnum.YUNGA],
  });
  useMetaTitle(`Роли`);
  return <Roles />;
};

export default RolesPage;
