import { Button, Grid } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { PpoVruTaskResponse } from '@/dto/taskmap/task/TaskResultDto';
import { PpoVruInput } from './PpoVruInput';
import { PpoEnterItem } from './PpoEnterItem';
import { VruStatusPopup } from '@/components/popups/vruStatusPopup';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { AccessController } from '@/components/common/AccessController';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';

interface PpoVruTaskItemProps {
  task: TaskResponse;
  vru: PpoVruTaskResponse;
  index: number;
  taskId: string;
  loadAll: () => void;
}

export const PpoVruItem = (props: PpoVruTaskItemProps) => {
  const { vru, index, taskId, loadAll, task } = props;

  return (
    <BoxShadow>
      <Grid
        container
        spacing={2}
        item
      >
        <VruStatusPopup
          task={task}
          vruTaskId={taskId}
          vruInputId={vru?.id}
          status={vru.ppoOdpuVruResponse?.ppoState}
          loadAll={loadAll}
          comment={vru.ppoOdpuVruResponse?.comment}
        />
        {vru && (
          <PpoVruInput
            {...vru}
            index={index}
            loadAll={loadAll}
          />
        )}
        {vru?.inputResponseList &&
          vru.inputResponseList
            .sort((a, b) => Number(a.inputNumber) - Number(b.inputNumber))
            .map((t, i) => (
              <Grid
                key={`grid-${i}`}
                item
                xs={12}
                container
              >
                <PpoEnterItem
                  enter={t}
                  vruId={`vruRequestList.${index}`}
                  index={i}
                  loadAll={loadAll}
                />
              </Grid>
            ))}
      </Grid>
    </BoxShadow>
  );
};
