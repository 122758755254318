import { Tooltip } from '@mui/material';
import { YungaAnalyzeResponse } from '@/dto/taskmap/task/YungaDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { yungaAnalyzeByReplacementId } from '@/services/ReplacementService';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useEffect, useState } from 'react';
import { useRole } from '@/hooks/useRole';
import { RolesEnum } from '@/types/roles';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface YungaAnalyzeButtonProps {
  id?: string;
  onAction: (response: YungaAnalyzeResponse) => void;
  sx?: SxProps<Theme>;
}

export const YungaAnalyzeButton = (props: YungaAnalyzeButtonProps) => {
  const { id, onAction, sx } = props;
  const { catchError } = useActionLog();
  const [show, setShow] = useState(false);
  const { role } = useRole();

  const handleExpand = () => {
    if (id) {
      yungaAnalyzeByReplacementId(id)
        .then((res) => onAction(res.data))
        .catch((err) => catchError);
    }
  };

  useEffect(() => {
    if (role) {
      setShow(role.includes(RolesEnum.Administrators));
    }
  }, [role]);

  return (
    <>
      {show && id && (
        <Tooltip
          title="Анализировать в сервисе 'Юнга'"
          placement='top'
        >
          <DocumentScannerIcon
            sx={{ color: 'gray', width: 18, ml: 0, cursor: 'pointer', ...sx }}
            onClick={handleExpand}
            data-tip
            data-for='registerTip'
          />
        </Tooltip>
      )}
    </>
  );
};
