import React, { FC } from 'react';
import { Colors } from '@/styles/colors';
import moment from 'moment';
import { MailNotificationsFileResponse } from '@/dto/MailNotificationsDto';
import { ChronologyRowDiv } from '@/components/features/taskEditor/item/chronology/Chronology.styled';
import ChronologyItem from '@/components/features/taskEditor/item/chronology/ChronologyItem';

const FileItem: FC<MailNotificationsFileResponse & { borderColor?: Colors }> = ({
  createTs,
  fileName,
  borderColor,
  isLoaded = false,
  error,
}) => {
  return (
    <>
      <ChronologyRowDiv borderColor={borderColor}>
        <ChronologyItem
          label={'Имя'}
          value={fileName}
        />
        <ChronologyItem
          label={'Статус'}
          value={isLoaded ? 'Загружен' : 'Не загружен'}
          isWarning={!isLoaded}
        />
        {isLoaded && (
          <ChronologyItem
            label={'Дата'}
            value={createTs ? moment(createTs).format('DD.MM.YYYY') : '-'}
          />
        )}
        {!isLoaded && (
          <ChronologyItem
            label={'Валидационное сообщение'}
            value={error}
          />
        )}
      </ChronologyRowDiv>
    </>
  );
};

export default FileItem;
