import React, { useState } from 'react';
import styles from './Roles.module.scss';
import { useCatalog } from '@/hooks/CatalogHook';
import { AccessEnum } from '@/types/roles';
import { Accesses } from '@/constants/accesses';

export const Roles = () => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const accessesMap = Object.entries(AccessEnum);
  const slicedAccessMap = accessesMap
    .slice(accessesMap.length / 2)
    .reduce((acc, [value, key]) => ({ ...acc, [key]: value }), {});

  const rolesAndAccesses = Object.entries(Accesses).reduce(
    (acc, [key, value]) => ({
      ...acc,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [key]: value.map((item) => slicedAccessMap[item]),
    }),
    {}
  );

  const rolesTitles = [...getCatalogMapWithErr('roles')].reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );

  const [activeRole, setActiveRole] = useState('Administrators');
  return (
    <div className={styles.container}>
      <h1>Роли системы</h1>
      <div className={styles.content}>
        <div>
          {Object.entries(rolesTitles).map(([key, title]) => (
            <div
              onClick={() => setActiveRole(key)}
              key={key}
              className={styles.item}
            >
              {title as string}
            </div>
          ))}
        </div>
        <div>
          {(rolesAndAccesses as any)[activeRole as any]?.map((item: any) => (
            <div
              key={item}
              className={styles.item}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
