import { GridColDef } from '@mui/x-data-grid';
import { createStore } from '@/utils/createStore';
import { getColumns } from './constants';

interface Col {
  columns: {
    taskBrowseCol: GridColDef[];
  };

  setCurrentColumns: (columns: GridColDef[]) => void;
}

export const useCol = createStore<Col>(
  (set, get) => ({
    columns: {
      taskBrowseCol: [...getColumns()],
    },

    setCurrentColumns: (newColumns) =>
      set(() => ({
        columns: { ...get().columns, taskBrowseCol: newColumns },
      })),
  }),
  'Col',
  'dataGridCol',
  {
    version: 5,
    migrate: (persistedState: unknown, version: number) => {
      if (version !== 5) {
        if ((persistedState as Col)?.columns) {
          (persistedState as Col).columns = {
            taskBrowseCol: [...getColumns()],
          };
        }
      }

      return persistedState as Col;
    },
  }
);
