import { Grid, Stack, Typography } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { PpoVruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { PpoGeneralReplacement } from './PpoGeneralReplacement';
import { ModeViewerResult } from '@/constants/modeViewer';
import { useCatalog } from '@/hooks/CatalogHook';

interface OdpuItemProps {
  enter: PpoVruInputResponse;
  prevString: string;
}

export const PpoOdpuItem = (props: OdpuItemProps) => {
  const { enter, prevString } = props;

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  useEffect(() => {
    getCatalogMapWithErr('referenceMeterType');
    getCatalogMapWithErr('ppoLocationMeter');
    getCatalogMapWithErr('protectionDeviceRating');
    getCatalogMapWithErr('protectionType');
    getCatalogMapWithErr('kttEnum');
    getCatalogMapWithErr('ppoCurrentRateRange');
    getCatalogMapWithErr('ppoLocationTt');
    getCatalogMapWithErr('amperageTransformerType');
    getCatalogMapWithErr('ppoLocationNewMeter');
  }, []);

  const getPreview = (): ReactNode => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <Grid item>
          <Stack
            direction={'row'}
            spacing={5}
          >
            <Typography>
              ПУ: {enter.meterResponse?.type}: {enter.meterResponse?.number}
            </Typography>
            <Typography>
              {enter.meterResponse?.verificationYear}г.{' '}
              {enter?.lockerResponse?.puCount
                ? ` Шкаф на ${enter.lockerResponse.puCount}  ПУ`
                : ' Шкаф не нужен'}
            </Typography>
            <Typography>
              TT: {enter?.ppoTransformerResponseList?.map((t) => t.phaseName).concat(' ')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <PpoGeneralReplacement
      preview={getPreview()}
      mode={ModeViewerResult.LARGE}
      vruInput={enter}
      prevString={prevString}
    />
  );
};
