import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { useMemo } from 'react';

export const useTaskEditorOnlyShow = () => {
  const isEdit = useAccess({
    access: [AccessEnum.TasksBrowse],
    stopRedirect: true,
  });
  const isShow = useAccess({
    access: [AccessEnum.DeliveryPointScreen],
    stopRedirect: true,
  });
  return useMemo(() => isShow && !isEdit, [isEdit, isShow]);
};
