import { syncErrorCatch } from '@/hooks/ActionLogHook';
import { DropResult } from 'react-beautiful-dnd';
import { usePNRStore } from '../../store';
import shallow from 'zustand/shallow';
import { PnrDndEditModalFormValues } from './PnrDndEditModalForm';

export enum UpdateDnDFloorFunc {
  CLEAR = 'clear',
  ADD = 'add',
  BLOCK = 'block',
}
export enum PnrDndEditModalFormType {
  ENTRANCE = 'entrance',
  HOUSE = 'house',
}

export interface ExistedFlat {
  flatNumber: string;
  entranceNumber: number;
  floorNumber: number;
}

export const existedFlatsByNumber = (flatNumber: string) => {
  const houseEntranceData = usePNRStore.getState().houseEntranceData;

  if (!houseEntranceData) return [];
  const existedFlats: ExistedFlat[] = [];
  for (const entrance of houseEntranceData) {
    if (!entrance.floors) continue;
    for (const floor of entrance.floors) {
      if (floor.flats.includes(flatNumber)) {
        existedFlats.push({
          flatNumber,
          entranceNumber: entrance.entranceNumber,
          floorNumber: floor.floorNumber,
        });
      }
    }
  }
  return existedFlats;
};

export const generateExistedFlatsErrorMessage = (existedFlats: ExistedFlat[]) => {
  if (!existedFlats.length) return;
  if (existedFlats.length === 1) {
    syncErrorCatch(
      `Квартира ${existedFlats[0].flatNumber} уже существует. Подъезд: ${existedFlats[0].entranceNumber}, этаж: ${existedFlats[0].floorNumber}`,
      {}
    );
  }
  if (existedFlats.length > 1) {
    syncErrorCatch('Ошибка добавления нескольких квартир. Подробности в журнале событий', [
      'Существующие квартиры',
      ...existedFlats.map((it) => ({
        ['Номер_квартиры']: it.flatNumber,
        ['Подъезд']: it.entranceNumber,
        ['Этаж']: it.floorNumber,
      })),
    ]);
  }
};

export const existedStaffRoomsByNumber = (flatNumber: string) => {
  const houseEntranceData = usePNRStore.getState().houseEntranceData;

  if (!houseEntranceData) return [];
  const existedStaffRooms: ExistedFlat[] = [];
  for (const entrance of houseEntranceData) {
    if (!entrance.staffRooms) continue;
    for (const staffRoom of entrance.staffRooms) {
      if (staffRoom.number === flatNumber) {
        existedStaffRooms.push({
          flatNumber,
          entranceNumber: entrance.entranceNumber,
          floorNumber: parseInt(staffRoom.floorNumber),
        });
      }
    }
  }
  return existedStaffRooms;
};

export const generateExistedStaffRoomsErrorMessage = (existedStaffRooms: ExistedFlat[]) => {
  if (!existedStaffRooms.length) return;
  if (existedStaffRooms.length === 1) {
    syncErrorCatch(
      `Юр.лицо с помещением ${existedStaffRooms[0].flatNumber} уже существует. Подъезд: ${existedStaffRooms[0].entranceNumber}, этаж: ${existedStaffRooms[0].floorNumber}`,
      {}
    );
  }
  if (existedStaffRooms.length > 1) {
    syncErrorCatch('Ошибка добавления нескольких юр. лиц. Подробности в журнале событий', [
      'Существующие помещения юр.лиц',
      ...existedStaffRooms.map((it) => ({
        ['Номер_квартиры']: it.flatNumber,
        ['Подъезд']: it.entranceNumber,
        ['Этаж']: it.floorNumber,
      })),
    ]);
  }
};

export enum STAFF_ROOMS {
  PREFIX = 'STAFF',
  DELIMITER = '!',
}

export const useHouseDnDEdit = () => {
  const { houseEntranceData, setHouseEntranceData, backendHouseEntranceData } = usePNRStore(
    (state) => ({
      houseEntranceData: state.houseEntranceData,
      setHouseEntranceData: state.setHouseEntranceData,
      backendHouseEntranceData: state.backendHouseEntranceData,
    }),
    shallow
  );

  const handleDragEnd = (end: DropResult) => {
    if (!houseEntranceData || !end.destination) {
      return;
    }
    const [sourceEntranceNumber, sourceEntranceFloor] = end.source.droppableId
      .split('-')
      .map(Number);
    const sourceEntrance = houseEntranceData.find(
      (entrance) => entrance.entranceNumber === sourceEntranceNumber
    );
    const sourceFloor = sourceEntrance?.floors?.find(
      (floor) => floor.floorNumber === sourceEntranceFloor
    );

    const [destinationEntranceNumber, destinationEntranceFloor] = end.destination.droppableId
      .split('-')
      .map(Number);
    const destinationEntrance = houseEntranceData.find(
      (entrance) => entrance.entranceNumber === destinationEntranceNumber
    );
    const destinationFloor = destinationEntrance?.floors?.find(
      (floor) => floor.floorNumber === destinationEntranceFloor
    );

    // При дропе staff-room(юр. лицо)
    if (end.draggableId.includes(STAFF_ROOMS.PREFIX)) {
      const staffRoomNumber = end.draggableId.split(STAFF_ROOMS.DELIMITER)[1];
      if (sourceEntrance?.staffRooms) {
        sourceEntrance.staffRooms = sourceEntrance?.staffRooms?.filter(
          (room) => room.number !== staffRoomNumber
        );
      }
      const newStaffRoom = {
        number: staffRoomNumber,
        floorNumber: destinationEntranceFloor?.toString() ?? '1',
      };
      if (destinationEntrance) {
        if (destinationEntrance?.staffRooms) {
          destinationEntrance?.staffRooms?.push(newStaffRoom);
        } else {
          destinationEntrance.staffRooms = [newStaffRoom];
        }
      }
    }
    // При дропе квартиры
    else {
      const flatIndex = sourceFloor?.flats?.indexOf(end.draggableId);

      if (destinationFloor?.isNonResidentialFloor) {
        syncErrorCatch('Этаж заблокирован', {});
      } else {
        if (flatIndex !== undefined && flatIndex !== -1 && sourceFloor) {
          sourceFloor.flats.splice(flatIndex, 1);
        }

        if (destinationFloor) {
          destinationFloor.flats.push(end.draggableId);
        }
      }
    }

    const newHouseEntranceData = [...houseEntranceData];
    setHouseEntranceData(newHouseEntranceData);
  };

  const updateFloorEntry = (
    entranceNumber: number,
    floorNumber: number,
    updateFunc: UpdateDnDFloorFunc,
    addNumber?: string
  ) => {
    if (!houseEntranceData) return;
    const entrance = houseEntranceData.find((it) => it.entranceNumber === entranceNumber);
    if (!entrance) return;
    const floor = entrance.floors?.find((it) => it.floorNumber === floorNumber);
    if (!floor) return;
    if (updateFunc === UpdateDnDFloorFunc.ADD && addNumber) {
      floor.flats.push(addNumber);
    }
    if (updateFunc === UpdateDnDFloorFunc.CLEAR) {
      floor.flats = [];
      if (entrance.staffRooms?.length) {
        entrance.staffRooms = entrance.staffRooms?.filter(
          (room) => Number(room.floorNumber) !== floorNumber
        );
      }
    }
    if (updateFunc === UpdateDnDFloorFunc.BLOCK) {
      floor.flats = [];
      floor.isNonResidentialFloor = !floor?.isNonResidentialFloor;
    }
    setHouseEntranceData([...houseEntranceData]);
  };

  const handleHouseEditSubmit = ({
    flatByEntranceRange,
    flatSinceWith,
    floorCount,
  }: PnrDndEditModalFormValues) => {
    if (!houseEntranceData) {
      return;
    }
    let flatNumber = flatSinceWith;

    const allEntrances = houseEntranceData.map((entrance) => {
      const floors = [];
      for (let i = 1; i <= floorCount; i++) {
        const neededId = backendHouseEntranceData
          ?.find((it) => it.entranceNumber === entrance.entranceNumber)
          ?.floors?.find((it) => it.floorNumber === i)?.id;

        const isNonResidential = houseEntranceData
          ?.find((it) => it.entranceNumber === entrance.entranceNumber)
          ?.floors?.find((it) => it.floorNumber === i)?.isNonResidentialFloor;

        let flats: string[] = [];
        if (!isNonResidential) {
          flats = Array.from({ length: flatByEntranceRange }, () => {
            const currentFlatNumber = String(flatNumber);
            flatNumber++;

            return currentFlatNumber;
          });
        }

        floors.push({
          floorNumber: i,
          flats,
          isNonResidentialFloor: !!isNonResidential,
          ...(neededId ? { id: neededId } : {}),
        });
      }
      return {
        ...entrance,
        floorCount: floorCount,
        floors: floors.sort((a, b) => b.floorNumber - a.floorNumber),
      };
    });

    setHouseEntranceData(allEntrances);
  };

  const resetDnDModalEditions = () => {
    setHouseEntranceData(backendHouseEntranceData ?? []);
  };

  const handleHouseReset = () => {
    if (!houseEntranceData) {
      return;
    }
    const allEntrances = houseEntranceData.map((entrance) => ({
      ...entrance,
      floors: entrance.floors
        ? entrance.floors?.map((floor) => ({
            ...floor,
            flats: [],
            isNonResidentialFloor: false,
          }))
        : [],
    }));

    setHouseEntranceData(allEntrances);
  };

  return {
    handleDragEnd,
    updateFloorEntry,
    handleHouseEditSubmit,
    resetDnDModalEditions,
    handleHouseReset,
  };
};
