import { Grid, Pagination, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { YungaLogResponse } from '@/dto/Yunga';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useYunga } from '@/hooks/YungaHook';
import { count, findAllByParam } from '@/services/YungaService';
import { YungaLogItem } from './YungaLogItem';
import { YungaStatusPane } from './YungaStatusPane';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { ImageViewItem } from '@/components/features/taskEditor/item/ImageItem/ImageItem';

export const YungaBrows = () => {
  const limit = 5;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [yungaLogRepsonseList, setYungaLogRepsonseList] = useState<YungaLogResponse[]>([]);
  const { fileId, setFileId } = useYunga();
  const { setIsInProgress } = useScreenHoldHook();
  const { catchError } = useActionLog();
  const [searchId, setSaearchId] = useState('');

  useEffect(() => {
    findAll();
  }, []);

  const handleChagePage = (event: any, page: number) => {
    setPage(page);
    setSaearchId('');
  };

  useEffect(() => {
    findAll();
  }, [page, count]);

  const handleFinaAll = () => {
    setPage(1);
    findAll();
  };

  const handleClean = () => {
    setPage(1);
    setSaearchId('');
  };

  const findAll = () => {
    setFileId(undefined);
    setIsInProgress(true);
    count(searchId).then((res) => setTotal(res.data));
    findAllByParam(searchId, page - 1, limit)
      .then((res) => setYungaLogRepsonseList(res.data))
      .catch((err) => catchError('Ошибка при загрузке лога Юнги', err))
      .finally(() => setIsInProgress(false));
  };

  return (
    <Grid
      container
      spacing={1}
      p={2}
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <Grid
        item
        container
        width={'100%'}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <YungaStatusPane></YungaStatusPane>
        <Stack
          direction={'row'}
          spacing={2}
          alignItems='center'
          alignContent={'center'}
        >
          <TextField
            id='tf-logId'
            label='log/file id'
            variant='standard'
            value={searchId}
            onChange={(t) => setSaearchId(t.target?.value)}
          />
          <FilterButtons
            onCloseClick={handleClean}
            onSearchClick={handleFinaAll}
          />
        </Stack>
      </Grid>
      <Grid
        item
        container
        spacing={2}
      >
        <Grid
          item
          xs={8}
          sx={{ minHeight: '75vh', maxHeight: '75vh', overflow: 'auto' }}
          p={2}
        >
          <Stack spacing={2}>
            {yungaLogRepsonseList &&
              yungaLogRepsonseList.map((t, ingex) => (
                <YungaLogItem
                  key={t.id}
                  index={(page - 1) * limit + ingex + 1}
                  yungaLogResponse={t}
                ></YungaLogItem>
              ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
          container
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {(fileId && (
            <ImageViewItem
              obj={'YungaBrows'}
              imageId={fileId}
              titles={[fileId]}
              withDelete={false}
              createdOn={new Date()}
              // afterDelete={() => {}}
            />
          )) || <Typography>(пусто)</Typography>}
        </Grid>
      </Grid>
      <Grid item>
        <Pagination
          page={page}
          count={Math.ceil(total / limit)}
          size='large'
          onChange={handleChagePage}
        />
      </Grid>
    </Grid>
  );
};
