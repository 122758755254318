import React from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

import { CallCenterStatisticsDto, CallCenterStatisticsFilter } from '@/types/Types';
import { getDataLines, getFirstHeaderLine, getSecondHeaderLine } from './utils';
import './CallCenterStatisticsStyle.css';

export interface CallCenterStatisticsTableProps {
  data: CallCenterStatisticsDto[];
  showOnlyTotals?: boolean;
  setFilter?: (filter: CallCenterStatisticsFilter) => void;
  isReadyToDownload?: boolean;
  setIsReadyToDownload?: (flag: boolean) => void;
  filter?: CallCenterStatisticsFilter;
}
const CallCenterStatisticsTable = ({
  data,
  showOnlyTotals,
  setFilter,
  setIsReadyToDownload,
  filter,
}: CallCenterStatisticsTableProps) => {
  return (
    <TableContainer className={'rootTableContainer'}>
      <Table
        stickyHeader
        aria-label='sticky table'
      >
        <TableHead>
          <TableRow key={new Date().getDate() + '' + Math.random()}>
            {getFirstHeaderLine(data)}
          </TableRow>
          <TableRow key={new Date().getDate() + '' + Math.random()}>
            {getSecondHeaderLine(data)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && getDataLines(data, !!showOnlyTotals, setFilter, setIsReadyToDownload, filter)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CallCenterStatisticsTable;
