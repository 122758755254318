import { useState, useMemo } from 'react';
import ButtonsOdpu from './ButtonsOdpu';
import { IComplaintItem } from '../types';
import { useCatalog } from '@/hooks/CatalogHook';
import { Grid, TextField } from '@mui/material';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import ButtonsEnergy from './ButtonsEnergy';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { useComplaintStore } from '../store';
import { useComplaintsEditAccess } from '../roles';

const CommonTextFields = ({
  selectedCompliant,
  isEnergyType,
}: {
  selectedCompliant: IComplaintItem;
  isEnergyType: boolean;
}) => {
  const hasAccessToEdit = useComplaintsEditAccess();
  const [executorId, setExecutorId] = useState('');
  const handleExecutorId = (id: string) => {
    setExecutorId(id);
  };

  const compalintWorkTypeList = useComplaintStore((state) => state.compalintWorkTypeList);
  const workTypeOptions = useMemo(() => {
    const workTypeOptionsMap = new Map<string, string>();
    compalintWorkTypeList.forEach((item) => {
      workTypeOptionsMap.set(item.name, item.title);
    });
    return workTypeOptionsMap;
  }, [compalintWorkTypeList]);
  const [responsibleId, setResponsibleId] = useState(
    () => selectedCompliant.complaint.responsibleId
  );

  const [response, setResponse] = useState('');
  const [error, setError] = useState(false);
  const [workType, setWorkType] = useState(() => selectedCompliant?.workType?.name);

  const { taskGroupMap } = useCatalog((state) => ({
    taskGroupMap: state.taskGroupMap,
  }));

  const handleResponsible = (id: string) => {
    setResponsibleId(id);
  };

  const isReturnedODPU =
    selectedCompliant?.complaint.stage.name === ComplaintStage.RETURNED ||
    (selectedCompliant?.complaint.stage.name === ComplaintStage.DUPLICATED &&
      selectedCompliant.complaint.type === 'ODPU');

  return (
    <>
      {isEnergyType && (
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            label='Текст ответа на жалобу'
            multiline
            rows={2}
            variant='standard'
            value={(response || selectedCompliant.complaint?.response) ?? ''}
            onChange={(e) => setResponse(e.target.value)}
            disabled={!hasAccessToEdit}
            // error={!!fieldState.error}
            // helperText={fieldState.error?.message}
          />
        </Grid>
      )}
      {!isReturnedODPU && (
        <Grid
          item
          xs={12}
          container
          sx={{ gap: '10px' }}
        >
          <Grid
            item
            xs={isEnergyType ? 5.8 : 12}
          >
            <AutocompleteValue
              title='Ответственный подрядчик'
              required={
                // selectedCompliant.complaint.stage.name === ComplaintStage.MODERATED_AFTER_CC ||
                // selectedCompliant.complaint.stage.name === ComplaintStage.MODERATED ||
                error
              }
              fieldName={'responsibleId'}
              defaultValue={responsibleId}
              values={taskGroupMap}
              optionOnChangeValue={handleResponsible}
              disabled={!hasAccessToEdit}
            />
          </Grid>
          {!isEnergyType && (
            <Grid
              item
              xs={12}
            >
              <AutocompleteValue
                title='Исполнитель'
                fieldName={'executorId'}
                defaultValue={executorId}
                values={taskGroupMap}
                optionOnChangeValue={handleExecutorId}
              />
            </Grid>
          )}
        </Grid>
      )}

      {isEnergyType && selectedCompliant && (
        <ButtonsEnergy
          isEnergyType={isEnergyType}
          responsibleId={responsibleId}
          response={response}
          workType={workType}
          setError={setError}
        />
      )}
      {!isEnergyType &&
        selectedCompliant &&
        selectedCompliant?.complaint.stage.name !== ComplaintStage.DUPLICATED &&
        selectedCompliant?.complaint.stage.name !== ComplaintStage.COMPLETED && (
          <ButtonsOdpu
            responsibleId={responsibleId}
            executor={executorId}
            setError={setError}
          />
        )}
    </>
  );
};

export default CommonTextFields;
