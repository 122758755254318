import React, { useMemo } from 'react';
import { Accesses } from '../../constants/accesses';
import { useRole } from '@/hooks/useRole';
import { AccessEnum, RolesEnum } from '../../types/roles';

interface IProps {
  children: React.ReactNode;
  access: AccessEnum[];
}

export const AccessController: React.FC<IProps> = ({ children, access }) => {
  const { role } = useRole();

  const hasAccess = useMemo(() => {
    if (access && role) {
      return role?.some(
        (item: RolesEnum) =>
          !!Accesses[item] &&
          Accesses[item].find(
            (accessItem) => !!access.find((innerAcces) => innerAcces === accessItem)
          )
      );
    }
  }, [access, role]);

  if (!role || !hasAccess) {
    return null;
  }

  return <>{children}</>;
};
