import { styled } from '@mui/material';
import { color } from '@/styles/mixins';

interface HouseEntranceFlatCountMatchedWrapperProps {
  matched?: boolean | null;
}

export const HouseEntranceFlatCountMatchedWrapper = styled(
  'div'
)<HouseEntranceFlatCountMatchedWrapperProps>(({ matched }) => ({
  borderRadius: 15,
  padding: 8,
  ...(matched !== null &&
    matched !== undefined && {
      border: `2px solid ${matched ? color('muiSuccess') : color('muiWarning')}`,
    }),
}));
