import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accesses } from '@/constants/accesses';
import { AccessEnum, RolesEnum } from '@/types/roles';
import { useRole } from './useRole';

interface IParams {
  access: AccessEnum[];
  stopRedirect?: boolean;
}

export const useAccess = ({ access, stopRedirect }: IParams) => {
  const navigate = useNavigate();

  const { role } = useRole();

  const hasAccess = useMemo(() => {
    if (access && role) {
      return role?.some(
        (item: RolesEnum) =>
          !!Accesses[item] &&
          Accesses[item].find(
            (accessItem) => !!access.find((innerAcces) => innerAcces === accessItem)
          )
      );
    }
  }, [access, role]);

  if (role && !hasAccess && !stopRedirect) {
    navigate('/');
  }

  return !!hasAccess;
};

interface DisableAccessParams {
  disabledRoles: RolesEnum[];
}
interface AllowAccessParams {
  allowRoles: RolesEnum[];
}

/**
 * Возвращает логическое значение, указывающее, имеет ли пользователь доступ, на основе предоставленных запрещенных ролей.
 *
 * @param {RolesEnum[]} params.disabledRoles - Массив ролей, доступ которым запрещён
 */
export const useDisabledAccess = ({ disabledRoles }: DisableAccessParams) => {
  const { role } = useRole();
  const [disableAccess, setDisableAccess] = useState(true);
  const memoizedRoles = useMemo(() => disabledRoles, []);

  useEffect(() => {
    if (role) {
      role.some((item: RolesEnum) => {
        if (memoizedRoles.includes(item)) {
          setDisableAccess(true);
        } else {
          setDisableAccess(false);
        }
      });
    }
  }, [role, memoizedRoles]);

  return disableAccess;
};

export const useAllowedAccessByRole = ({ allowRoles }: AllowAccessParams) => {
  const { role } = useRole();
  const [allow, setAllowAccess] = useState(true);
  const memoizedRoles = useMemo(() => allowRoles, []);

  useEffect(() => {
    if (role) {
      setAllowAccess(role.some((item: RolesEnum) => memoizedRoles.includes(item)));
    }
  }, [role, memoizedRoles]);

  return useMemo(() => allow, [allow]);
};
