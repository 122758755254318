import { Button, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useActionLog } from '@/hooks/ActionLogHook';
import { TextField } from '@/components/fixUI';
import { useDisabledAll } from '@/hooks/useDisabledAll';
import { useForm } from 'react-hook-form';
import { color } from '@/styles/mixins';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { editeAddress } from '@/components/maps/ppoMap/service';
import { useDeliveryPoints } from '@/components/maps/ppoMap/placemark/delivery-point/store';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';

interface ReplacementPuDialogProps {
  update?: () => void;
  address: {
    id: string;
    region?: string | null;
    areaName?: string | null;
    locality?: string | null;
    street?: string | null;
    house?: string | null;
    apartmant?: string | null;
  };
}

export const EditAddressPopup: FC<ReplacementPuDialogProps> = ({ update, address }) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { catchError } = useActionLog();
  const [open, setOpen] = useState<boolean>(false);
  const { register, handleSubmit, setValue } = useForm();

  const { updateSub } = useDeliveryPoints();

  const onSubmit = (data: any) => {
    editeAddress(address.id, data.newAddress)
      .then((res) => {
        updateSub(res.data);
      })
      .catch((err) => {
        catchError('Ошибка сохранения адреса', err);
      })
      .finally(() => setOpen(false));
  };

  useEffect(() => {
    setValue(`newAddress.house`, address?.house);
    setValue(`newAddress.apartment`, address?.apartmant);
    setValue(`newAddress.street`, address?.street);
    setValue(`newAddress.region`, address?.region);
    setValue(`newAddress.areaName`, address?.areaName);
    setValue(`newAddress.locality`, address?.locality);
  }, [address, open]);

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <BorderColorIcon htmlColor={color('muiBlue')} />
      </IconButton>
      <Dialog
        open={open}
        onClose={onCancel}
        fullWidth
        maxWidth={'lg'}
      >
        <Stack
          spacing={3}
          padding={4}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              <Typography variant={'h4'}>Внесите изменения по адресу</Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                label='Регион'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`newAddress.region`)}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                label='Район'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`newAddress.areaName`)}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                label='Населенный пункт'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`newAddress.locality`)}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <TextField
                label='Улица'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`newAddress.street`)}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <TextField
                label='Дом'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`newAddress.house`)}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <TextField
                label='Квартира'
                disabled={disabledAll || onlyShow}
                variant='standard'
                sx={{ width: '100%' }}
                {...register(`newAddress.apartment`)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              <Button
                onClick={handleSubmit(onSubmit)}
                autoFocus
                sx={{ mr: 4 }}
              >
                Сохранить
              </Button>
              <Button onClick={onCancel}>Отмена</Button>
            </Grid>
          </Grid>
        </Stack>
      </Dialog>
    </>
  );
};
