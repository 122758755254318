import Drive from '@/components/features/drive/Drive';
import { useAccess } from '@/hooks/useAccess';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { AccessEnum } from '@/types/roles';
import React from 'react';

const DrivePage = () => {
  useAccess({
    access: [AccessEnum.Drive],
  });
  useMetaTitle(`ИД ТехПрис`);
  return <Drive />;
};

export default DrivePage;
