import { Button, Paper } from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScatterDataPoint,
  Title,
  Tooltip as TooltipT,
} from 'chart.js';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { Bar, Line } from 'react-chartjs-2';
import { MeterChangeForRevisionData } from '@/types/Types';
import './MeterChangeForRevisionStyle.css';
import { FormProvider, useForm } from 'react-hook-form';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import { DateRange } from '@/components/inputs/DateRange';

import { ApiFindCurrentGroup, ApiFindGroupByChildName } from '@/services/YodaRestService';
import { Tooltip } from '@material-ui/core';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import { METER_CHANGE_FOR_REVISION_OPTIONS } from './utils';
import {
  MeterChangeForRevisionButton,
  MeterChangeForRevisionFiltersWrapper,
} from './MeterChangeForRevision.styled';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { useStatisticTeamManagerVoormanAndExecutorAcces } from '../hooks/useStatisticTeamManagerVoormanAndExecutorAcces';

interface MeterChangeForRevisionProps {
  data: MeterChangeForRevisionData;
  header: string;
  load: boolean;

  updateFunction: (
    standardFilter: TaskFilterParam,
    dateFrom: string,
    dateTo: string,
    datparentContractorName: string
  ) => void;
  setParentContractorName: (parentContractorName: string) => void;
  groupName: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TooltipT,
  Legend,
  BarElement
);

const FILTERS_LABELS = {
  contracts: 'Контракт',
  responsables: 'Ответственный подрядчик',
};

const MeterChangeForRevision: React.FC<MeterChangeForRevisionProps> = ({
  data,
  header,
  load,
  updateFunction,
  setParentContractorName,
  groupName,
}) => {
  let filterParam: TaskFilterParam = {};
  const [previousGroupName, setPreviousGroupName] = React.useState('ПОДРЯДЧИКИ');
  const [currentGrupName, setCurrentGrupName] = React.useState('');
  const taskGroupMap = useCatalog((state) => state.taskGroupMap);

  let selectedGroupName: string = groupName;
  const setSelectedGroupName = (groupName: string) => {
    selectedGroupName = groupName;
    setParentContractorName(selectedGroupName);
  };

  const barOptionsArray: ChartOptions<'bar'>[] =
    data.dataBar === null
      ? new Array<ChartOptions<'bar'>>()
      : Object.assign(
          [],
          data.dataBar.map((item) => ({
            responsive: true,
            maintainAspectRatio: false,
            width: 150,
            height: 1000,
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              x: {
                display: false,
                stacked: true,
              },
              y: {
                stacked: true,
                display: false,
              },
            },
            onClick: (event: any, elements: any) => {
              if (disableButton) return;
              if (elements.length != 0) {
                setSelectedGroupName('' + item.datasets[elements[0].datasetIndex].label);
                updateFunction(
                  filterParam,
                  new Date(dataInRange[0])?.toISOString()?.split('T')[0],
                  new Date(dataInRange[1])?.toISOString()?.split('T')[0],
                  selectedGroupName
                );
              }
            },
          }))
        );

  const [dataInRange, setDateInRange] = useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 14),
    new Date(),
  ]);
  const itemWidth: string = (63 / (15 * data.dataBar?.length - 1)) * 10 + 'vw';

  const itemMarginLeft: string = (63 / (15 * data.dataBar?.length - 1)) * 3 + 'vw';
  const methods = useForm();
  const { handleSubmit } = methods;

  const responsables = useMemo(() => taskMapToArray(taskGroupMap), [taskGroupMap]);
  const [selectedResponsables, setSelectedResponsables] = React.useState<SelectFilterOptType[]>([]);
  const [responsablesArg, setResponsablesArg] = React.useState(0);

  const contractListResponse = useAllContractQuery();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = React.useState(0);

  const onSubmitSearch = (data: any) => {
    filterParam = data;
    if (selectedContractList.length) {
      filterParam.contractIds = getDataFromFilter(selectedContractList, 'value');
      filterParam.contractIdsArg = contractListArg;
    }
    if (selectedResponsables.length) {
      filterParam.contractorIds = getDataFromFilter(selectedResponsables, 'value');
      filterParam.contractorIdsArg = responsablesArg;
    }
    updateFunction(
      filterParam,
      new Date(dataInRange[0])?.toISOString()?.split('T')[0],
      new Date(dataInRange[1])?.toISOString()?.split('T')[0],
      currentGrupName || selectedGroupName
    );
  };

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedResponsables([]);
    setParentContractorName('ПОДРЯДЧИКИ');
  };

  React.useEffect(() => {
    ApiFindCurrentGroup().then((res) => {
      if (res.data.title === null) setPreviousGroupName('ПОДРЯДЧИКИ');
      else {
        setPreviousGroupName(res.data.title + '');
        setParentContractorName(selectedGroupName);
        setCurrentGrupName(res.data.title);
      }
    });
  }, [data, selectedGroupName, previousGroupName]);

  const getMaxDataSetSize = () => {
    if (data.dataBar != null) {
      let maxSize = 0;
      data.dataBar.map((item) => {
        if (maxSize < item.datasets.length) maxSize = item.datasets.length;
      });
      return maxSize;
    }
    return 0;
  };
  getMaxDataSetSize();
  const getMaxSizeDataBar = () => {
    if (data.dataBar != null) {
      let maxSize = 0;
      let maxSizeDataBar: ChartData<
        'bar',
        (number | ScatterDataPoint | BubbleDataPoint | null)[],
        unknown
      > = data.dataBar[0];
      data.dataBar.map((item) => {
        if (maxSize < item.datasets.length) {
          maxSize = item.datasets.length;
          maxSizeDataBar = item;
        }
      });
      return maxSizeDataBar;
    }
    return null;
  };
  const isInRange = dataInRange.length !== 2;
  const disableButton = dataInRange.length < 2;

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [selectedResponsables, 'Ответственный подрядчик']
  );
  const isTeamManagerVoormanAndExecutor = useStatisticTeamManagerVoormanAndExecutorAcces();

  const disableButtonSearch = () => {
    if (isTeamManagerVoormanAndExecutor && !selectedResponsables.length) {
      return true;
    }
    return false;
  };

  return (
    <Paper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitSearch)}>
          <MeterChangeForRevisionFiltersWrapper>
            <MultiSelect
              label={FILTERS_LABELS.contracts}
              options={contractListResponse ?? []}
              value={selectedContractList}
              argValue={contractListArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
              onArgChange={(arg: number) => setContractListArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.responsables}
              options={responsables}
              value={selectedResponsables}
              argValue={responsablesArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedResponsables(value)}
              onArgChange={(arg: number) => setResponsablesArg(arg)}
            />

            <FilterButtons
              info={detailedInfo}
              onSearchClick={() => handleSubmit(onSubmitSearch)}
              onCloseClick={onResetClick}
              disable={disableButtonSearch() || load}
            />
            <Tooltip title={'Нажмите обновить для отображения изменений'}>
              <MeterChangeForRevisionButton
                type={'reset'}
                onClick={() => setSelectedGroupName('ПОДРЯДЧИКИ')}
              >
                Сбросить фильтр групп
              </MeterChangeForRevisionButton>
            </Tooltip>
            <DateRange
              label={'выбрать диапазон'}
              isInRange={isInRange}
              onChange={setDateInRange}
              value={dataInRange}
              argValue={8} // set interval by default
              showSelect={false} // hide select option
            />
          </MeterChangeForRevisionFiltersWrapper>
        </form>
      </FormProvider>

      {load ? (
        <LoadSpinner />
      ) : (
        <>
          <div style={{ display: 'table-row', width: '100%' }}>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: '1.5vh 0 0', textAlign: 'center', padding: '5px 0' }}>
                Текущая група: {currentGrupName}
              </p>
              <button
                onClick={(event) => {
                  setSelectedGroupName(previousGroupName);
                  updateFunction(
                    filterParam,
                    new Date(dataInRange[0]).toISOString().split('T')[0],
                    new Date(dataInRange[1]).toISOString().split('T')[0],
                    previousGroupName
                  );
                }}
                className={'getGroupBtn'}
              >
                Вернуться к предыдущей группе
              </button>
              <ul className={'staticList'}>
                {getMaxSizeDataBar()?.datasets.map((item) => (
                  <li
                    style={{ display: 'block' }}
                    key={Math.random()}
                  >
                    <Button
                      onClick={(event) => {
                        setSelectedGroupName('' + item.label);
                        updateFunction(
                          filterParam,
                          new Date(dataInRange[0]).toISOString().split('T')[0],
                          new Date(dataInRange[1]).toISOString().split('T')[0],
                          selectedGroupName
                        );
                      }}
                      style={{ textAlign: 'left', fontSize: '12', padding: 0 }}
                      disabled={disableButton}
                    >
                      <div
                        className={'barLegendColorRectangle'}
                        style={{ backgroundColor: '' + item.backgroundColor }}
                      ></div>
                      <p style={{ margin: 0, lineHeight: '115%' }}>
                        {item.data[0] + ': ' + item.label}
                      </p>
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={'meterChangeFoкRevisionChartStyle'}
              style={{ display: 'table-cell', verticalAlign: 'top' }}
            >
              <Line
                options={METER_CHANGE_FOR_REVISION_OPTIONS}
                data={data.all}
              />
            </div>
          </div>
          <ul style={{ padding: 0, marginLeft: '17.4vw' }}>
            {data.dataBar != null ? (
              data.dataBar.map((item, number) => (
                <li
                  className='two'
                  style={{ marginLeft: itemMarginLeft }}
                  key={Math.random()}
                >
                  <div
                    className='content'
                    style={{ width: itemWidth }}
                  >
                    <Tooltip
                      id='registerTip'
                      placement='top'
                      arrow={true}
                      key={Math.random()}
                      title={item.datasets.map((label, count) => (
                        <div key={Math.random()}>
                          {count < 24 ? (
                            <div key={Math.random()}>
                              <div style={{ padding: '1px' }}>
                                <div
                                  className={'barLegendColorRectangle'}
                                  style={{ backgroundColor: '' + label.backgroundColor }}
                                ></div>
                                <div style={{ display: 'table-cell' }}>
                                  <p
                                    style={{
                                      margin: ' 0px 6px 0px 6px',
                                      padding: 0,
                                    }}
                                  >
                                    {label.data[0] + ': ' + label.label}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ))}
                    >
                      <div>
                        <Bar
                          data={item}
                          options={barOptionsArray[number]}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </>
      )}
    </Paper>
  );
};
export default React.memo(MeterChangeForRevision);
