import { HOST } from './YodaRestService';
import { axiosGet } from './helpers';
import { AxiosResponse } from 'axios';
import { AdpuResponse } from '@/dto/AdpuDto';

export async function getAdpuById(adpuId: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/adpu/${adpuId}`;
  return axiosGet(url);
}

export async function getAdpuByTaskId(taskId: string): Promise<AxiosResponse<AdpuResponse[], any>> {
  const url = HOST + `/rest/adpu/task/${taskId}`;
  return axiosGet(url);
}

export async function getAdpuOrder(adpuOrderId: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/adpu/adpu-order/${adpuOrderId}`;
  return axiosGet(url);
}
