import { RegistryBrowse } from '@/components/features/registryBrowse/RegistryBrowse';
import { useAccess } from '@/hooks/useAccess';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { AccessEnum } from '@/types/roles';
import React from 'react';

const RegistryBrowsePage = () => {
  useAccess({
    access: [AccessEnum.Registry],
  });
  useMetaTitle('Реестр');
  return <RegistryBrowse />;
};

export default RegistryBrowsePage;
