import { DataTypeConvert } from '@/components/inputs/DynamicFilter/utils';

type FILTERS_LABELS_TYPE = {
  [key: string]: string;
};
export const FILTERS_LABELS: FILTERS_LABELS_TYPE = {
  address: 'Адрес',
  settlement: 'Населенный пункт',
  street: 'Улица',
  house: 'Дом',
  tpName: 'ТП',
  cipher: 'Шифр',
  korpus: 'Корпус',
  appartment: 'Квартира',
  contractIds: 'Контракт',
  contractorIds: 'Ответственный',
  executorIds: 'Бригада исполнителя',
  taskType: 'Тип заявки',
  taskPriority: 'Приоритет',
  taskStatus: 'Статус заявки',
  schemeBYT: 'Схема BYT',
  regionalElectricNetworkId: 'РЭС',
  managingOrganization: 'Управляющая организация',
  clientOffice: 'Клиентский офис',
  electricNetworksEnterpriseId: 'ПЭС',
  territorialBranch: 'Территориальное отделение',
  customerId: 'СТДО / Договор Тех.Прис',
  tkoStatus: 'Статус ТКО',
  registrationNum: 'Рег. № SAP',
  executionDateOnList: 'Дата монтажа',
  plannedDateOnList: 'Плановая дата',
  actStatus: 'Статус акта',
  odpuStage: 'Этап',
  subscriberFio: 'ФИО потребителя',
  exPuNum: 'Номер Сущ. ПУ',
  accountingNum: 'Лицевой счет',
  stampNum: 'Номер пломбы',
  existingPuNum: '№ демонтированого пу',
  revisionOnList: 'Дата ППО ОДПУ',
  ppoOdpuTechDecisionIdList: 'ППО ОДПУ Тех.решение',
  ppoState: 'ППО ОДПУ Статус провереки',
};

const data: { id: string; dType: string }[] = [
  {
    id: 'address',
    dType: 'TEXT',
  },
  {
    id: 'settlement',
    dType: 'TEXT',
  },
  {
    id: 'street',
    dType: 'TEXT',
  },
  {
    id: 'house',
    dType: 'TEXT',
  },
  {
    id: 'korpus',
    dType: 'TEXT',
  },
  {
    id: 'appartment',
    dType: 'TEXT',
  },
  {
    id: 'tpName',
    dType: 'TEXT',
  },
  {
    id: 'cipher',
    dType: 'TEXT',
  },

  {
    id: 'contractIds',
    dType: 'TAG',
  },
  {
    id: 'contractorIds',
    dType: 'TAG',
  },
  {
    id: 'executorIds',
    dType: 'TAG',
  },
  {
    id: 'taskType',
    dType: 'TAG',
  },
  {
    id: 'taskPriority',
    dType: 'TAG',
  },
  {
    id: 'taskStatus',
    dType: 'TAG',
  },
  {
    id: 'schemeBYT',
    dType: 'TEXT',
  },
  {
    id: 'regionalElectricNetworkId',
    dType: 'TAG',
  },
  {
    id: 'managingOrganization',
    dType: 'TAG',
  },
  {
    id: 'clientOffice',
    dType: 'TAG',
  },
  {
    id: 'electricNetworksEnterpriseId',
    dType: 'TAG',
  },
  {
    id: 'territorialBranch',
    dType: 'TAG',
  },
  {
    id: 'customerId',
    dType: 'TEXT',
  },
  {
    id: 'tkoStatus',
    dType: 'TAG',
  },
  {
    id: 'registrationNum',
    dType: 'TEXT',
  },
  {
    id: 'executionDateOnList',
    dType: 'DATE',
  },
  {
    id: 'plannedDateOnList',
    dType: 'DATE',
  },
  {
    id: 'actStatus',
    dType: 'TAG',
  },
  {
    id: 'odpuStage',
    dType: 'TAG',
  },
  {
    id: 'subscriberFio',
    dType: 'TEXT',
  },
  {
    id: 'exPuNum',
    dType: 'TEXT',
  },
  {
    id: 'stampNum',
    dType: 'TEXT',
  },
  {
    id: 'existingPuNum',
    dType: 'TEXT',
  },
  {
    id: 'accountingNum',
    dType: 'TEXT',
  },
  {
    id: 'revisionOnList',
    dType: 'DATE',
  },
  {
    id: 'ppoOdpuTechDecisionIdList',
    dType: 'TAG',
  },
  {
    id: 'ppoState',
    dType: 'TAG',
  },
];

export const taskBrowseFilterData: DataTypeConvert[] = data
  .map((elem) => ({
    ...elem,
    value: elem.id,
    label: FILTERS_LABELS[elem.id],
    isActive: false,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));
// .filter((elem) => elem.dType === 'TAG');
