import create from 'zustand';
import { createStore } from '../utils/createStore';

export interface YungaStore {
  fileId?: string;
  setFileId: (fileId?: string) => void;
}

export const useYunga = createStore<YungaStore>(
  (set: any, get: any) => ({
    setFileId: (fileId?: string) => {
      set({ fileId: fileId });
    },
  }),
  'Yunga'
);
