import { useEffect } from 'react';
import { Circle } from 'react-yandex-maps';
import { GeoPoint } from '@/components/maps/GeoPoint';
import { GeoCircle, useMap } from '@/hooks/MapHook';

export const CircleLayout = () => {
  const { geoCircleChanged, geoCircleItems, searchCircleCovered, setGeoCircleRef } = useMap();

  const handleCirecleRefList = (geoPoint: GeoPoint, ref: any) => {
    setGeoCircleRef(geoPoint, ref);
  };

  useEffect(() => {
    searchCircleCovered();
  }, [geoCircleChanged]);

  return (
    <>
      {geoCircleItems &&
        Array.from(geoCircleItems.values()).map((t: GeoCircle, i: number) => {
          return (
            <Circle
              key={'circle' + i}
              instanceRef={(ref: any) => handleCirecleRefList(t.target, ref)}
              geometry={[t.coords, parseInt(t.radius + '')]}
            />
          );
        })}
    </>
  );
};
