import { Link } from 'react-router-dom';

import { ActionaLogDialog } from '@/components/widgets/Navbar/ActionLogDialog/ActionLogDialog';

import { useNavbar } from './store';
import React from 'react';
import { useHackedDataGridPro } from '@/hooks/useHackedDataGridPro';
import { NavWrapper } from '@/components/widgets/Navbar/NavBar.styled';
import YODALogo from '@/images/icons/YODAlogo.svg';

import NavbarBolt from './NavbarBolt';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { NavbarLinks } from './NavbarLinks/NavbarLinks';
import { MobileNavbar } from './MobileNavbar/MobileNavbar';

export default function Navbar() {
  const { openErrorsDialog, setOpenErrorsDialog } = useNavbar();
  useHackedDataGridPro();
  const handleCloseErrorsDialog = () => setOpenErrorsDialog(false);
  const isMobile = useMobileSize();

  return (
    <>
      <NavWrapper>
        <Link to='/'>
          <img
            src={YODALogo}
            style={{ marginLeft: '8px' }}
          />
        </Link>
        {!isMobile && <NavbarBolt />}
        <div style={{ flex: '1 1 auto' }} />
        {isMobile && <MobileNavbar />}
        {!isMobile && <NavbarLinks />}

        <ActionaLogDialog
          open={openErrorsDialog}
          onClose={handleCloseErrorsDialog}
        />
      </NavWrapper>
    </>
  );
}
