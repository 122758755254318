import { FC, ReactNode, useState } from 'react';
import { AdpuResponse } from '@/dto/AdpuDto';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Grid, Stack, Typography } from '@mui/material';
import CopyButton from '@/components/button/CopyButton';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';
import { ExpandButton } from '@/components/button/ExpandButton';
import { ModeViewerResult } from '@/constants/modeViewer';

import moment from 'moment';
import { useGalleryData } from '@/components/Gallery/store';
import { TASK_EDITOR_BIG_PREVIEW } from '../item/TaskEditorItem.styled';
import { IdPopup } from '../panel/IdPopup';

interface IItem extends AdpuResponse {
  mode?: ModeViewerResult;
}

export const Item: FC<IItem> = ({
  actStatus,
  actOn,
  comment,
  newMeasurementId,
  taskId,
  montageName,
  meterChangeId,
  imageList,
  mode = ModeViewerResult.LARGE,
}) => {
  const { register } = useFormContext();
  const { setImageGroups } = useGalleryData();

  const [innerMode, setInnerMode] = useState<ModeViewerResult>(mode);
  const handleMode = () => {
    setInnerMode(
      innerMode === ModeViewerResult.SMALL ? ModeViewerResult.LARGE : ModeViewerResult.SMALL
    );
  };

  const handleDoubleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups([{ title: 'АДПУ', imageList: imageList }]);
  };

  const getPreview = (): ReactNode => {
    return (
      <Grid {...TASK_EDITOR_BIG_PREVIEW.inner}>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.iconItem}>
          {/*<ReplacementStateIcon status={replacementResponse?.replacementState} />*/}
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.firstItem}>
          {/*<Typography>{toFormateDate(replacementResponse?.replacedOn)}</Typography>*/}
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.secondItem}>
          {/*<TaskEditorOverflowText>{replacementResponse?.montageName}</TaskEditorOverflowText>*/}
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.thirdItem}>
          {/*<Typography>MAC: {replacementResponse?.mounted?.mac || '-'}</Typography>*/}
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.fourthItem}>
          {/*<Typography>ПУ: {replacementResponse?.mounted?.number || '-'}</Typography>*/}
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.lastItem}>
          <Stack
            direction={'row'}
            justifyContent='flex-end'
          >
            <Typography>АДПУ</Typography>
            <ExpandButton
              onAction={handleMode}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
    >
      <BoxShadow onDoubleClick={handleDoubleClick}>
        {innerMode === ModeViewerResult.SMALL && <>{getPreview()}</>}
        {(innerMode === ModeViewerResult.MEDIUM || innerMode === ModeViewerResult.LARGE) && (
          <>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Stack
                  direction={'row'}
                  sx={{ alignItems: 'center' }}
                >
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>Статус:</span>{' '}
                    {actStatus === 'signed'
                      ? 'Подписан'
                      : actStatus === 'notSigned'
                      ? 'Не подписан'
                      : ''}
                  </Typography>
                  <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                    <IdPopup sx={{ color: 'gray' }}>
                      {taskId && (
                        <div>
                          <CopyButton textToCopy={taskId} />
                          id заявки {taskId}
                        </div>
                      )}
                    </IdPopup>
                  </div>
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  id='actOn'
                  label='Дата подписания акта'
                  variant='standard'
                  {...register(`actOn`, {
                    value: moment(actOn).format('DD.MM.YYYY'),
                  })}
                  disabled
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <TextField
                  id='montageName'
                  label='Подписано кем'
                  variant='standard'
                  {...register(`montageName`, {
                    value: montageName,
                  })}
                  disabled
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', flexWrap: 'wrap' }}
            >
              <Grid sx={{ ml: 'auto' }}></Grid>
              <Grid>
                <ExpandButton
                  expand={innerMode === ModeViewerResult.LARGE}
                  onAction={handleMode}
                />
              </Grid>
            </Stack>
          </>
        )}
      </BoxShadow>
    </Grid>
  );
};
