import React from 'react';
import { Button, Snackbar, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

type CopyButtonProps = {
  textToCopy?: string;
  onlyIcon?: boolean;
  verticalPosition?: 'bottom' | 'top';
  showToolTip?: boolean;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant='filled'
      {...props}
    />
  );
});
const CopyButton = ({
  textToCopy,
  onlyIcon,
  verticalPosition = 'bottom',
  showToolTip = false,
}: CopyButtonProps) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopyClick = () => {
    if (textToCopy)
      navigator.clipboard.writeText(textToCopy).then(() => {
        setIsCopied(true);
      });
  };

  const handleCloseSnackbar = () => {
    setIsCopied(false);
  };

  return (
    <>
      {showToolTip ? (
        <Tooltip
          title='копировать'
          placement='top'
        >
          {onlyIcon ? (
            <FileCopyIcon
              onClick={handleCopyClick}
              color='primary'
              fontSize='inherit'
              sx={{ cursor: 'pointer' }}
            />
          ) : (
            <Button
              variant='text'
              color='primary'
              startIcon={<FileCopyIcon />}
              onClick={handleCopyClick}
            >
              копировать
            </Button>
          )}
        </Tooltip>
      ) : (
        <>
          {onlyIcon ? (
            <FileCopyIcon
              onClick={handleCopyClick}
              color='primary'
              fontSize='inherit'
              sx={{ cursor: 'pointer' }}
            />
          ) : (
            <Button
              variant='text'
              color='primary'
              startIcon={<FileCopyIcon />}
              onClick={handleCopyClick}
            >
              копировать
            </Button>
          )}
        </>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: 'right',
        }}
        open={isCopied}
        autoHideDuration={700}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity='info'
          sx={{ width: '100%' }}
        >
          Текст скопирован
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyButton;
