import { addTKO, getTKOStausByIdTask } from '@/services/TaskService';
import { GetCurrentUser } from '@/services/YodaRestService';
import { Button, Stack, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCallingMapState } from '../store';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { responseToMap } from '@/utils/heplers';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import shallow from 'zustand/shallow';

import moment from 'moment';

interface TkoFormData {
  tkoEnumId: string;
  comment: string;
  executor: string | null;
  tkoDate: string;
}

export default function CreatTko() {
  const [openTkoForm, setOpenTkoForm] = useState(false);
  const [loadingTko, setLoadingTko] = useState(false);
  const [statusCatalog, setStatusCatalog] = useState<Map<string, string>>(new Map());
  const { register, setValue, handleSubmit, reset, watch } = useFormContext<TkoFormData>();
  const { singleGeoPoint, taskId } = useCallingMapState(
    (state) => ({
      taskId: state.taskId,
      singleGeoPoint: state.singleGeoPoint,
    }),
    shallow
  );

  const { addActionLog, fetchCatch } = useActionLog();
  const isStatusExist = watch().tkoEnumId;
  useEffect(() => {
    getTKOStausByIdTask(singleGeoPoint?.contractId!!).then(({ data }) =>
      setStatusCatalog(responseToMap(data))
    );
    GetCurrentUser()
      .then(({ data }) => {
        setValue('executor', data?.fullName ?? null);
        setValue(`tkoDate`, moment().format('YYYY-MM-DD'));
      })
      .catch((err) => fetchCatch(err, 'Ошибка получения данных о пользователе'));
  }, []);

  const handleTkoCreating = (data: TkoFormData) => {
    setLoadingTko(true);
    addTKO(data, taskId!!)
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
        reset();
        setOpenTkoForm(false);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка создания ТКО');
      })
      .finally(() => setLoadingTko(false));
  };
  const disableCreateTkoBtn = statusCatalog.size === 0;

  return (
    <Stack>
      <Grid
        container
        spacing={2}
      >
        <Grid
          xs={12}
          item
        >
          <Button
            variant='outlined'
            fullWidth
            type='submit'
            color='error'
            disabled={disableCreateTkoBtn}
            onClick={() => setOpenTkoForm((prev) => !prev)}
          >
            {statusCatalog.size ? `создать тко` : `нет статусов для создания тко`}
          </Button>
        </Grid>
        {openTkoForm && (
          <>
            <Grid
              item
              xs={6}
            >
              <AutocompleteValue
                title='Статус ТКО'
                defaultValue={''}
                fieldName={`tkoEnumId`}
                values={statusCatalog}
                required={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='outlined-basic'
                label='Коментарий'
                variant='outlined'
                multiline
                fullWidth
                rows={2}
                {...register(`comment`, {
                  value: '',
                })}
              />
            </Grid>
            <Grid
              xs={12}
              item
            >
              <Button
                variant='outlined'
                fullWidth
                type='submit'
                disabled={!isStatusExist || loadingTko}
                onClick={handleSubmit(handleTkoCreating)}
              >
                Сохранить ТКО
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
