import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { LightTooltip } from './HousePnrBrowse.styled';
import { Link } from 'react-router-dom';

export const columns: GridColDef[] = [
  {
    field: 'address',
    width: 500,
    filterable: false,
    sortable: false,
    headerName: 'Адрес',
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Link
          style={{ widows: '100%' }}
          target='_blank'
          to={`/pnr-entrance/edit/${params.row.houseId}`}
          tabIndex={params.hasFocus ? 0 : -1}
        >
          {params.value + ''}
        </Link>
      </LightTooltip>
    ),
  },
  {
    field: 'floorCount',
    width: 130,
    filterable: false,
    sortable: false,
    headerName: 'Кол-во этажей',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        variant='caption'
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'flatCount',
    width: 130,
    filterable: false,
    sortable: false,
    headerName: 'Кол-во квартир',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        variant='caption'
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'entranceCount',
    width: 160,
    filterable: false,
    sortable: false,
    headerName: 'Кол-во подъездов',
    renderCell: (params: GridRenderCellParams<string>) => (
      <Typography
        fontSize={14}
        variant='caption'
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'smrTasksSize',
    width: 180,
    filterable: false,
    sortable: false,
    headerName: 'Процент монтажа ИПУ',
    renderCell: (params: GridRenderCellParams<string>) => {
      const montagePercent =
        params.row?.smrTasksSize && params.row?.flatCount
          ? Math.round((params.row?.smrTasksSize / params.row?.flatCount) * 100)
          : 0;
      return (
        <Typography
          fontSize={14}
          variant='caption'
        >
          {montagePercent}%
        </Typography>
      );
    },
  },
  {
    field: 'odpuManagementCompanyName',
    filterable: false,
    sortable: false,
    flex: 1,
    headerName: 'Управляющая компания',
    renderCell: (params: GridRenderCellParams<string>) => (
      <LightTooltip title={params.value + ''}>
        <Typography
          fontSize={14}
          variant='caption'
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {params.value ?? ''}
        </Typography>
      </LightTooltip>
    ),
  },
];
