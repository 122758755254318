import { Alert, AlertTitle, Typography } from '@mui/material';
import moment from 'moment';
import { CopiedButton } from '@/components/button/CopiedButton';
import { useState } from 'react';
import { ActionLog } from '@/hooks/ActionLogHook';
import { ActionLogItemPre, ActionLogItemExpandIcon } from './ActionLogItem.styled';

interface ActionLogItemProps {
  item: ActionLog;
}

export const ActionLogItem = (props: ActionLogItemProps) => {
  const { item } = props;
  const [hide, setHide] = useState(true);
  const handleHide = () => {
    setHide(!hide);
  };

  const stringifiedError = JSON.stringify(item?.errorBody, null, ' ');

  return (
    <Alert
      severity={item.type}
      sx={{ width: '100%' }}
      action={
        <>
          {item?.errorBody && (
            <>
              <CopiedButton
                title='Скопировать описание ошибки'
                getValue={() => Promise.resolve(stringifiedError)}
              />
              <ActionLogItemExpandIcon
                onClick={handleHide}
                hide={hide}
              />
            </>
          )}
        </>
      }
    >
      <AlertTitle sx={{ m: 0 }}>
        <strong>{item.message}</strong>
      </AlertTitle>
      <Typography variant='subtitle2'>
        {moment(item.eventAt).format('HH:mm:ss / DD.MM.yyyy')}
      </Typography>
      {!hide && <ActionLogItemPre>{stringifiedError}</ActionLogItemPre>}
    </Alert>
  );
};
