import { TaskFilterParam } from '@/dto/taskmap/Dto';
import React from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import './SMROdpuStatisticsStyle.css';
import { SMROdpuStatisticsTable, SMROdpuStatisticsTableItem } from '@/types/Types';

import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '../../button/DetailedInfo/utils';
import { getDataFromFilter } from '../../inputs/MultiSelectNew/utils';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import { getRowValues, getResultRow } from './utils';
import {
  SMROdpuStatisticsFiltersWrapper,
  SMROdpuStatisticsWrapper,
} from './SMROdpuStatistics.styled';

interface SMROdpuStatisticsProps {
  header: string;
  load: boolean;
  data?: SMROdpuStatisticsTable;
  updateFunction: (taskFilterParam: TaskFilterParam, mouthNum: number) => void;
}

export const SMROdpuStatistics: React.FC<SMROdpuStatisticsProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const contractListResponse = useAllContractQuery();
  const [contractIds, setContractIds] = React.useState<SelectFilterOptType[]>([]);

  const onSubmitSearch = () => {
    const filterParam = {
      contractIds: getDataFromFilter(contractIds, 'value'),
    };
    updateFunction(filterParam, 0);
  };

  const onResetClick = () => setContractIds([]);

  const detailedInfo = generateDetailedInfo([contractIds, 'Контракт']);

  const handleChange = (newValues: SelectFilterOptType[]) => setContractIds(newValues);

  return (
    <SMROdpuStatisticsWrapper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>

      <Grid>
        <SMROdpuStatisticsFiltersWrapper>
          <MultiSelect
            options={contractListResponse}
            value={contractIds}
            onlyInListArgument
            label={'Контракт'}
            onChange={handleChange}
          />

          <FilterButtons
            info={detailedInfo}
            onSearchClick={onSubmitSearch}
            onCloseClick={onResetClick}
            disable={load}
          />
        </SMROdpuStatisticsFiltersWrapper>
        {load ? (
          <LoadSpinner />
        ) : (
          <TableContainer className={'rootTableContainer'}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    key={'Дата'}
                    align={'center'}
                    className={'leftHeaderCell'}
                  >
                    Дата
                  </TableCell>
                  {data?.previousMonth?.map((item) => (
                    <TableCell
                      key={item}
                      className={'regularHeaderCell'}
                      colSpan={3}
                    >
                      {item}
                    </TableCell>
                  ))}
                  {data?.allDays?.map((item) => (
                    <TableCell
                      key={item}
                      className={'regularHeaderCell'}
                      colSpan={3}
                    >
                      {new Date(item).toJSON().slice(0, 10).split('-').reverse().join('.')}
                    </TableCell>
                  ))}
                  <TableCell
                    key={'Итого'}
                    className={'regularHeaderCell'}
                    colSpan={3}
                  >
                    Итого:
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.smrOdpu && data?.smrOdpu.length != 0 ? (
                  <TableRow
                    key={new Date().getDate() + '' + Math.random()}
                    style={{ borderTop: '1px solid black' }}
                  >
                    <TableCell
                      key={new Date().getDate() + '' + Math.random()}
                      className={'intervalCell'}
                    >
                      ОДПУ
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={new Date().getDate() + '' + Math.random()}></TableRow>
                )}
                {data?.smrOdpu?.map((item: SMROdpuStatisticsTableItem) => (
                  <TableRow
                    key={new Date().getDate() + '' + Math.random()}
                    hover
                    role={'checkbox'}
                  >
                    {getRowValues(item)}
                  </TableRow>
                ))}
                <TableRow
                  key={new Date().getDate() + '' + Math.random()}
                  hover
                  role={'checkbox'}
                  className={'smrTableDayCell'}
                >
                  {getResultRow(data?.smrOdpu, data)}
                </TableRow>
                {data?.smrOdpuEntity && data?.smrOdpuEntity.length != 0 ? (
                  <TableRow key={new Date().getDate() + '' + Math.random()}>
                    <TableCell
                      key={new Date().getDate() + '' + Math.random()}
                      className={'intervalCell'}
                    >
                      ОДПУ Юр.лица
                    </TableCell>
                    {data?.previousMonth.map((item) => (
                      <TableCell
                        key={new Date().getDate() + '' + Math.random()}
                        className={'intervalCell'}
                        colSpan={3}
                      />
                    ))}
                    {data?.allDays.map((item) => (
                      <TableCell
                        key={new Date().getDate() + '' + Math.random()}
                        className={'intervalCell'}
                        colSpan={3}
                      />
                    ))}
                    <TableCell
                      key={new Date().getDate() + '' + Math.random()}
                      className={'intervalCell'}
                      colSpan={3}
                    />
                  </TableRow>
                ) : (
                  <TableRow key={new Date().getDate() + '' + Math.random()}></TableRow>
                )}

                {data?.smrOdpuEntity?.map((item: SMROdpuStatisticsTableItem) => (
                  <TableRow
                    key={new Date().getDate() + '' + Math.random()}
                    className={'smrTableDayCell'}
                  >
                    {getRowValues(item)}
                  </TableRow>
                ))}
                <TableRow className={'smrTableDayCell'}>
                  {getResultRow(data?.smrOdpuEntity, data)}
                </TableRow>
                {data?.smrOdpuMoek && data?.smrOdpuMoek.length != 0 ? (
                  <TableRow key={new Date().getDate() + '' + Math.random()}>
                    <TableCell
                      key={new Date().getDate() + '' + Math.random()}
                      className={'intervalCell'}
                    >
                      ОДПУ МОЕК
                    </TableCell>
                    {data?.previousMonth.map((item) => (
                      <TableCell
                        key={new Date().getDate() + '' + Math.random()}
                        className={'intervalCell'}
                        colSpan={3}
                      />
                    ))}
                    {data?.allDays.map((item) => (
                      <TableCell
                        key={new Date().getDate() + '' + Math.random()}
                        className={'intervalCell'}
                        colSpan={3}
                      />
                    ))}
                    <TableCell
                      key={new Date().getDate() + '' + Math.random()}
                      className={'intervalCell'}
                      colSpan={3}
                    />
                  </TableRow>
                ) : (
                  <TableRow key={new Date().getDate() + '' + Math.random()} />
                )}
                {data?.smrOdpuMoek?.map((item: SMROdpuStatisticsTableItem) => (
                  <TableRow
                    key={new Date().getDate() + '' + Math.random()}
                    className={'smrTableDayCell'}
                  >
                    {getRowValues(item)}
                  </TableRow>
                ))}
                <TableRow
                  key={new Date().getDate() + '' + Math.random()}
                  className={'smrTableDayCell'}
                >
                  {getResultRow(data?.smrOdpuMoek, data)}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </SMROdpuStatisticsWrapper>
  );
};
