import {
  HouseLevelFloorType,
  NetworkPlanningTypes,
  NOT_DEFINED_MONTAGE_PLACE,
} from '@/components/blocks/PnrElementsInfo/utils';
import { MONTAGE_PLACE_VALUES } from '@/components/blocks/PnrPlanningModalSteps/util';
import {
  NetworkEquipmentIds,
  NO_DATA_MAC_VALUE,
} from '@/components/sections/HouseEntranceSheme/utils';
import { getFilteredNetworkOptions } from '@/components/sections/PnrPlanning/utils';
import {
  CreateHouseOrderResponseDto,
  HouseDataDto,
  HouseOrderDto,
  MKDNetworkElementHouseDto,
  PlannedInstalationsDto,
} from '@/dto/taskmap/Dto';
import { syncErrorCatch } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useMemo } from 'react';

const isNumeric = (value: string): boolean => {
  return /^-?\d+$/.test(value);
};

const regex = /^(?=.*[0-9])(?=.*[а-яА-Яa-zA-Z])/;

export const fillFlatsWithNumberAndLetter = (flats: string[], wholeFlats: string[]): string[] => {
  const flatsWithLetter: string[] = [];
  const flatsToRemove: string[] = [];
  for (let i = 0; i < flats.length; i++) {
    let isFlatExists = false;
    const filteredWholeFlats = wholeFlats.filter(
      (it) => it.replace(/[а-яa-zА-ЯA-Z]/g, '') === flats[i]
    );
    filteredWholeFlats.forEach((flatNum) => {
      if (flatNum === flats[i]) {
        isFlatExists = true;
      }
      if (!isNumeric(flatNum)) {
        flatsWithLetter.push(flatNum);
      }
    });
    if (!isFlatExists && filteredWholeFlats.length > 0) {
      flatsToRemove.push(flats[i]);
    }
  }
  flatsToRemove.forEach((flatToRemove) => {
    const scanIndex = flats.indexOf(flatToRemove);
    if (scanIndex !== -1) {
      flats.splice(scanIndex, 1);
    }
  });
  return flats.concat(flatsWithLetter).sort((a, b) => parseInt(a!) - parseInt(b!));
};

export const fillArrayOfAppartments = (houseData: HouseDataDto, flatCount?: number) => {
  let firstIdx = 1;
  let secondIdx = 0;
  const result = [];
  const arr = houseData
    ?.smrTasks!.map((smrTask) => smrTask.apartment)
    ?.filter((el) => el && !!parseInt(el));

  const erroredTasks = houseData?.smrTasks
    ?.filter((el) => !el?.apartment || !parseInt(el?.apartment))
    ?.map((task) => ({ id: task.id, apartment: task.apartment }));

  if (erroredTasks?.length) {
    syncErrorCatch(`Найдены квартиры с некорректным заполнением поля "квартира"`, erroredTasks);
  }
  if (arr.length == 0) {
    return Array.from({ length: 80 }, (_, i) => (i + 1).toString());
  }
  arr.sort((a, b) => parseInt(a!) - parseInt(b!));

  while (firstIdx <= flatCount! && result.length < flatCount!) {
    if (secondIdx < arr.length) {
      const secondVal = parseInt(arr[secondIdx]!);
      if (firstIdx < secondVal) {
        result.push(firstIdx.toString());
        firstIdx++;
      } else if (firstIdx == secondVal) {
        result.push(firstIdx.toString());
        if (regex.test(arr[secondIdx]!)) {
          result.push(arr[secondIdx]!);
        }
        firstIdx++;
        secondIdx++;
      } else {
        result.push(arr[secondIdx]!.toString());
        secondIdx++;
      }
    } else {
      result.push(firstIdx.toString());
      firstIdx++;
    }
  }
  return result;
};

const getMontagePlace = (networkElement: MKDNetworkElementHouseDto) => {
  const montagePlace = networkElement?.houseLevel?.floorType as MONTAGE_PLACE_VALUES;
  const montageFloor =
    networkElement?.houseLevel?.floorNumber?.toString() ?? NOT_DEFINED_MONTAGE_PLACE;

  switch (montagePlace) {
    case HouseLevelFloorType.ATTIC:
    case HouseLevelFloorType.BASEMENT:
      return montagePlace;

    default:
      return montageFloor;
  }
};
/**
 * Генерация схемы установки СО на основе houseData
 *
 * @return {Map<number, Map<string, MKDNetworkElementHouseDto[]>>} Map, представляющая схему сетевых элементов, где ключ - номер подъезда, а значение - map мест установки и соответствующих им сетевых элементов.
 */
export const getNetworkElementsSheme = (houseData: HouseDataDto) => {
  const houseNetworkElementsSheme = new Map<number, Map<string, MKDNetworkElementHouseDto[]>>();
  try {
    const allNetworkElements = houseData.osTasks?.map((task) => task.networkElements)?.flat();

    if (!allNetworkElements) return houseNetworkElementsSheme;

    for (let i = 0; i < allNetworkElements.length; i++) {
      const element = allNetworkElements[i];

      if (!element?.houseLevel?.entranceNumber || !element?.type) continue;

      const montagePlace = getMontagePlace(element);

      if (houseNetworkElementsSheme.has(element?.houseLevel?.entranceNumber)) {
        const entranceMap = houseNetworkElementsSheme.get(element?.houseLevel?.entranceNumber);
        const floorMap = new Map(entranceMap);
        if (entranceMap?.has(montagePlace)) {
          // Добавление элементов в существующее место установки
          const currentNetworkElements = entranceMap?.get(montagePlace) ?? [];
          floorMap.set(montagePlace, [...currentNetworkElements, element]);
        } else {
          // Новое место установки
          floorMap.set(montagePlace, [element]);
        }

        houseNetworkElementsSheme.set(element?.houseLevel?.entranceNumber, floorMap);
      } else {
        // Новый подъезд
        const floorMap: Map<string, MKDNetworkElementHouseDto[]> = new Map();
        floorMap.set(montagePlace, [element]);
        houseNetworkElementsSheme.set(element?.houseLevel?.entranceNumber, floorMap);
      }
    }

    return houseNetworkElementsSheme;
  } catch (error) {
    return houseNetworkElementsSheme;
  }
};

export interface NetworkElementsWithoutEntranceCount {
  router: number;
  gateway: number;
}
export const getNetworkElementsWithoutEntranceCount = (houseData: HouseDataDto) => {
  const networkElementsWithoutEntranceCount: NetworkElementsWithoutEntranceCount = {
    router: 0,
    gateway: 0,
  };
  const allNetworkElementsWithoutEntrance = houseData.osTasks
    ?.map((task) => task.networkElements)
    ?.flat()
    ?.filter((el) => !el?.houseLevel?.entranceNumber);

  if (!allNetworkElementsWithoutEntrance || !allNetworkElementsWithoutEntrance.length)
    return networkElementsWithoutEntranceCount;

  const allRoutersWithoutEntrance = allNetworkElementsWithoutEntrance?.filter(
    (element) => element?.typeId === NetworkEquipmentIds.router
  )?.length;

  const allGatewaysWithoutEntrance = allNetworkElementsWithoutEntrance?.filter(
    (element) => element?.typeId === NetworkEquipmentIds.gateway
  )?.length;

  networkElementsWithoutEntranceCount.router = allRoutersWithoutEntrance;
  networkElementsWithoutEntranceCount.gateway = allGatewaysWithoutEntrance;

  return networkElementsWithoutEntranceCount;
};

export const getPlannedOrdersByEntrance = (
  plannedOrders: HouseOrderDto[]
): Map<number, HouseOrderDto[]> => {
  const plannedOrdersMap: Map<number, HouseOrderDto[]> = new Map();

  for (let i = 0; i < plannedOrders.length; i++) {
    const element = plannedOrders[i];
    if (!element?.houseLevel?.entranceNumber) continue;
    if (plannedOrdersMap?.has(element.houseLevel.entranceNumber)) {
      // Добавление элементов в существующее место установки
      const currentOrders = plannedOrdersMap?.get(element.houseLevel.entranceNumber) ?? [];
      plannedOrdersMap.set(element.houseLevel.entranceNumber, [...currentOrders, element]);
    } else {
      // Новое место установки
      plannedOrdersMap.set(element.houseLevel.entranceNumber, [element]);
    }
  }

  return plannedOrdersMap;
};

export const getAllPuWithMacApartments = (houseData?: HouseDataDto) => {
  if (houseData) {
    return houseData.smrTasks
      ?.filter(
        (smrTask) =>
          smrTask.apartment?.length &&
          smrTask.mac?.length &&
          smrTask?.mac?.toLowerCase() !== NO_DATA_MAC_VALUE
      )
      ?.map((smrTask) => smrTask?.apartment) as string[];
  } else return [];
};

export const useNetworkEquipmentTypes = () => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const networkEquipmentTypes = getCatalogMapWithErr('networkEquipmentType');
  const filteredNetworkEquipment = useMemo(
    () => getFilteredNetworkOptions(networkEquipmentTypes),
    [networkEquipmentTypes]
  );

  return { networkEquipmentTypes, filteredNetworkEquipment };
};

export const useNetworkElementPlanningType = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const networkElementPlanningTypes = getCatalogMapWithErr('networkElementPlanningType');

  const networkElementPlanningOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('networkElementPlanningType')),
    [networkElementPlanningTypes]
  );

  return { networkElementPlanningOptions, networkElementPlanningTypes };
};

export const useHouseLevelFloorType = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const floorTypes = getCatalogMapWithErr('floorType');

  const floorTypesOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('floorType')),
    [floorTypes]
  );

  return { floorTypesOptions, floorTypes };
};

export const generateMockPlanings = (amount: number): PlannedInstalationsDto[] => {
  return Array(amount)
    .fill(1)
    ?.map((plan, index) => {
      return {
        id: null,
        houseLevel: {
          id: index,
          entranceNumber: 1,
          floorNumber: 1,
          floorType: 'ATTIC',
        },
        comment: `test_comment_${index}`,
        hasConflict: false,
        conflictComment: null,
        equipmentTypeId: '8034dd00-f1fb-8f52-eed7-13b2724854bb',
        userEdited: false,
        type: NetworkPlanningTypes.FIRSTLY,
      };
    });
};
