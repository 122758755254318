import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/types/roles';
import { StoreHouseBillItem } from '@/components/storehouse/StoreHouseBillItem/StoreHouseBillItem';

const StorehouseBillItem = () => {
  useAccess({
    access: [AccessEnum.Storehouse, AccessEnum.StorehouseInfo],
  });

  return <StoreHouseBillItem />;
};
export default StorehouseBillItem;
