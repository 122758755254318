import { AxiosResponse } from 'axios';
import {
  AsumbTaskStatisticsForCheckChartType,
  AsumbTaskStatisticsType,
  CallCenterStatisticsDto,
  CallCenterStatisticsFilter,
  MeterChangeForRevisionData,
  SMROdpuStatisticsTable,
  SMRStatisticsTableItem,
  SmsStatusStatisticsItem,
  StatisticByInstallationLocationData,
  StatisticMoreThanOneApealDto,
  StatisticsResponsebleDto,
} from '@/types/Types';
import {
  ApiFindAsumbTaskStatistics,
  ApiGetAsumbTaskStatisticsCheckStatus,
  ApiGetAsumbTaskStatusStatisticsForDate,
  HOST,
} from './YodaRestService';
import { BubbleDataPoint, ChartData, ScatterDataPoint } from 'chart.js';
import {
  FilterSmsStatisitc,
  FilterStatisticReportByContractPerDay,
  FilterStatisticUrgentAndSuperUrgent,
  MeterChangeForRevisionFilterParam,
  SMROdpuStatisticsFilter,
  SMRStatisticsFilter,
  StatisticByInstallPuDto,
  StatisticDistributionDto,
  StatisticReportByContractPerDayDto,
  StatisticUrgentAndSuperUrgentTasksDto,
  TaskFilterParam,
  filterStatisticByInstallPu,
} from '@/dto/taskmap/Dto';
import { axiosGet, axiosPost } from './helpers';
import { Filter } from '@/components/statistics/StatisticMoreThanOneApeal/StatisticMoreThanOneApeal';

const TAG = 'StatisticsService';
const ID_CALLING_MAP_STATISTIC = 'db1b8102-b6a3-f28d-b2c8-54ebd37432a4';

export function GetASUMBTaskStatistics(): Promise<AxiosResponse<AsumbTaskStatisticsType[], any>> {
  return ApiFindAsumbTaskStatistics();
}
export function GetASUMBTaskStatisticsForDate(
  dateFrom: string,
  dateTo: string
): Promise<
  AxiosResponse<
    ChartData<'line', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>,
    any
  >
> {
  return ApiGetAsumbTaskStatusStatisticsForDate(dateFrom, dateTo);
}

export function GetASUMBTaskStatisticsCheckStatus(): Promise<
  AxiosResponse<AsumbTaskStatisticsForCheckChartType>
> {
  return ApiGetAsumbTaskStatisticsCheckStatus();
}

export async function GetASUMBTaskStatisticsByFilter(
  filter: TaskFilterParam
): Promise<AxiosResponse<AsumbTaskStatisticsType[], any>> {
  const url = HOST + `/rest/statistics/asumb/task`;
  return axiosPost(url, filter);
}

export async function GetASUMBTaskStatisticsCheckStatusByFilter(
  filter: TaskFilterParam
): Promise<AxiosResponse<AsumbTaskStatisticsForCheckChartType, any>> {
  const url = HOST + `/rest/statistics/asumb/check`;
  return axiosPost(url, filter);
}

export async function GetMeterChangeForRevisionByFilter(
  filter: MeterChangeForRevisionFilterParam
): Promise<AxiosResponse<MeterChangeForRevisionData, any>> {
  const url = HOST + `/rest/statistics/meter-change/revision`;
  return axiosPost(url, filter);
}

export async function GetSMRStatisticsTableByFilter(
  filter: SMRStatisticsFilter
): Promise<AxiosResponse<SMRStatisticsTableItem[], any>> {
  const url = HOST + '/rest/statistics/smr';
  return axiosPost(url, filter);
}

export async function GetSMROdpuStatisticsTableByFilter(
  filter: SMROdpuStatisticsFilter
): Promise<AxiosResponse<SMROdpuStatisticsTable, any>> {
  const url = HOST + '/rest/statistics/smr/odpu';
  return axiosPost(url, filter);
}

export async function GetCallCenterStatisticsTableByFilter(
  filter: CallCenterStatisticsFilter
): Promise<AxiosResponse<CallCenterStatisticsDto[], any>> {
  const url = HOST + '/rest/statistics/call-center';
  return axiosPost(url, filter);
}

export async function GetCallCenterStatisticsXlsx(filter: {
  taskIdList: string[];
  template: string;
}): Promise<AxiosResponse<any, any>> {
  const url = HOST + '/rest/report/task';
  return axiosPost(url, filter, true);
}
export async function GetStatisticMoreThanOneApealByFilter(
  filter: Filter
): Promise<AxiosResponse<StatisticMoreThanOneApealDto[], any>> {
  const { pageSize, currentPage, ...payload } = filter;
  const url =
    HOST + `/rest/statistics/task-with-calls?offset=${currentPage * pageSize}&limit=${pageSize}`;
  return axiosPost(url, payload);
}

export async function GetSmsStatusStatistics(
  contractIdList: FilterSmsStatisitc
): Promise<AxiosResponse<SmsStatusStatisticsItem[], any>> {
  const url = HOST + '/rest/statistics/sms/status/contracts';

  return axiosPost(url, contractIdList);
}
export async function GetStatisticsResponsebles(
  contractIdList: any
): Promise<AxiosResponse<StatisticsResponsebleDto[], any>> {
  const url = HOST + '/rest/statistics/call-center/contractor';
  return axiosPost(url, contractIdList);
}
export async function GetStatisticByInstallationLocation(
  contractIdList: any
): Promise<AxiosResponse<StatisticByInstallationLocationData[], any>> {
  const url = HOST + '/rest/statistics/montage-place';
  return axiosPost(url, contractIdList);
}
export async function GetStatisticUrgentAndSuperUrgentTasks(
  filter: FilterStatisticUrgentAndSuperUrgent
): Promise<AxiosResponse<StatisticUrgentAndSuperUrgentTasksDto[], any>> {
  const url = HOST + '/rest/statistics/task-urgent';
  return axiosPost(url, filter);
}
export async function GetStatisticReportByContract(
  filter: FilterStatisticReportByContractPerDay
): Promise<AxiosResponse<StatisticReportByContractPerDayDto[], any>> {
  const url = HOST + '/rest/statistics/execution/contract/day';
  return axiosPost(url, filter);
}
export async function GetStatisticByInstallPu(
  filter: filterStatisticByInstallPu
): Promise<AxiosResponse<StatisticByInstallPuDto[], any>> {
  const url = HOST + '/rest/statistics/montage-places-compare';
  return axiosPost(url, filter);
}

export async function GetStatisticDistribution(
  filter: any
): Promise<AxiosResponse<StatisticDistributionDto[], any>> {
  const url = HOST + '/rest/statistics/distribution/contract/month';
  return axiosPost(url, filter);
}
export async function GetStatisticCallingMap(filter: any): Promise<AxiosResponse<any[], any>> {
  const url = HOST + '/rest/statistics/installation-call-status';
  return axiosPost(url, filter);
}
export async function GetStatisticCallingMapResponsible(): Promise<AxiosResponse<any[], any>> {
  const url = HOST + `/rest/group/${ID_CALLING_MAP_STATISTIC}/child `;
  return axiosGet(url);
}
export async function GetStatisticCallingMapExecutors(
  contractId: string
): Promise<AxiosResponse<any[], any>> {
  const url = HOST + `/rest/group/${contractId}/child-embedded`;
  return axiosGet(url);
}

export async function GetStatisticCallingMapOperators(
  contractId: string
): Promise<AxiosResponse<any[], any>> {
  const url = HOST + `/rest/group/${contractId}/role?role-name=ContractorOperatorRole `;
  return axiosGet(url);
}

export async function GetAttributesRp(): Promise<AxiosResponse<any, any>> {
  const url = HOST + '/rest/statistics/rp-report/attributes';
  return axiosGet(url);
}
export async function GetCurrentGroup(): Promise<AxiosResponse<any, any>> {
  const url = HOST + '/rest/user/current/group';
  return axiosGet(url);
}
