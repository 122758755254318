import { SmsStatusStatisticsItem } from '@/types/Types';
import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { FilterSmsStatisitc } from '@/dto/taskmap/Dto';
import { FormProvider, useForm } from 'react-hook-form';
import { MultiSelect } from '../../inputs/MultiSelectNew';

import { SmsStatusStatisticsMainTable } from './SmsStatusStatisticsMainTable';
import { SmsStatusStatisticsSecondTable } from './SmsStatusStatisticsSecondTable';
import { useCatalog } from '@/hooks/CatalogHook';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import { getDataFromFilter } from '../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../button/DetailedInfo/utils';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useAllContractQuery } from '@/hooks/useQuery/useAllContractQuery';
import { useSaveFilterButton } from '../../filter/FilterButtons/utils';
import {
  SmsStatusStatisticsFiltersWrapper,
  SmsStatusStatisticsWrapper,
} from './SmsStatusStatistics.styled';

interface SmsStatusStatisticsProps {
  header: string;
  load: boolean;
  data: SmsStatusStatisticsItem[];
  updateFunction: (filter: FilterSmsStatisitc) => void;
}

const SAVE_FILTER_KEY = 'SmsStatusStatistics';

export const SmsStatusStatistics: React.FC<SmsStatusStatisticsProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const taskGroupMap = useCatalog((state) => state.taskGroupMap);
  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const methods = useForm();
  const { handleSubmit } = methods;
  const contractListResponse = useAllContractQuery();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [selectedResponsables, setSelectedResponsables] = React.useState<SelectFilterOptType[]>([]);
  const responsables = useMemo(() => taskMapToArray(taskGroupMap), [taskGroupMap]);
  const [extraDataTables, setExtraDataTables] = React.useState({ first: [], second: [] });

  const onSubmitSearch = () => {
    const newFilter: FilterSmsStatisitc = { contractIds: [] };
    if (selectedContractList.length) {
      newFilter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }
    if (selectedResponsables.length) {
      newFilter.contractorIds = getDataFromFilter(selectedResponsables, 'value');
    }
    updateFunction(newFilter);
  };

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedResponsables([]);
    updateFunction({ contractIds: [] });
    setExtraDataTables({ ...extraDataTables, first: [], second: [] });
  };

  const renderExtradDataTables = (data: any, tableNumber: number) => {
    if (tableNumber === 1) {
      setExtraDataTables({ ...extraDataTables, first: data });
    } else {
      setExtraDataTables({ ...extraDataTables, second: data });
    }
  };

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [selectedResponsables, 'Ответственный подрядчик']
  );

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContractList && setSelectedContractList(savedFilters.selectedContractList);
    savedFilters.selectedResponsables && setSelectedResponsables(savedFilters.selectedResponsables);
  };

  return (
    <SmsStatusStatisticsWrapper elevation={6}>
      <p className={'headerPageStatistics'}>{header}</p>

      <Grid>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <SmsStatusStatisticsFiltersWrapper>
              <MultiSelect
                label={'Контракт'}
                value={selectedContractList}
                onlyInListArgument
                options={contractListResponse}
                onChange={(sel: SelectFilterOptType[]) => setSelectedContractList(sel)}
              />
              <MultiSelect
                label={'Ответственный'}
                value={selectedResponsables}
                onlyInListArgument
                options={responsables}
                onChange={(sel: SelectFilterOptType[]) => setSelectedResponsables(sel)}
              />
              <FilterButtons
                info={detailedInfo}
                onSearchClick={() => handleSubmit(onSubmitSearch)}
                onCloseClick={onResetClick}
                disable={load}
                saveFilter={{
                  filterType: SAVE_FILTER_KEY,
                  infoToSave: { selectedContractList, selectedResponsables },
                  selectCallback: onSelectSavedFilter,
                }}
              />
            </SmsStatusStatisticsFiltersWrapper>
          </form>
        </FormProvider>

        {load ? (
          <LoadSpinner />
        ) : (
          <>
            <SmsStatusStatisticsMainTable
              data={data}
              renderExtradDataTables={renderExtradDataTables}
            />
            {!!extraDataTables.first.length && (
              <SmsStatusStatisticsSecondTable
                className={'custom-column-class-yellow'}
                data={extraDataTables.first}
              />
            )}
            {!!extraDataTables.second.length && (
              <SmsStatusStatisticsSecondTable
                className={'custom-column-class-blue'}
                data={extraDataTables.second}
                withExtraColumns
              />
            )}
          </>
        )}
      </Grid>
    </SmsStatusStatisticsWrapper>
  );
};
export default SmsStatusStatistics;
