import { Button, Grid, Stack, Typography } from '@mui/material';
import { DesktopDatePicker, TextField } from '@/components/fixUI';
import React, { FC, useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { ReplacementResponseList } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { ReplacementCancelDialog } from '@/components/features/taskEditor/dialog/ReplacementCancelDialog';
import { ReplacementIncorrect } from '@/components/features/taskEditor/dialog/ReplacementIncorrect';
import {
  changeStatus,
  findYungaAnalyzeLogByReplacementId,
  removeByguarantee,
  replacementSendAct,
  saveReplacement,
  toRepeatInstallationReplacement,
  toSwapReplacement,
  toSwapReplacementByTaskId,
  oldPUIndicationsIncorrect,
} from '@/services/ReplacementService';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { ExpandButton } from '@/components/button/ExpandButton';
import SaveIcon from '@mui/icons-material/Save';
import { useFormContext } from 'react-hook-form';
import { toFormateDate } from '@/utils/heplers';
import { ReplacementViewDialog } from '@/components/features/taskEditor/dialog/ReplacemetnViewDialog';
import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { MeterItem, Types } from './MeterItem';
import { IdPopup } from '../panel/IdPopup';
import { YungaAnalyzeResponse } from '@/dto/taskmap/task/YungaDto';
import { YungaAnalyzeButton } from '@/components/features/taskEditor/item/YungaAnalyzeButton';
import { ModeViewerResult } from '@/constants/modeViewer';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { DownloadPopup } from '@/components/popups/DownloadPopup/DownloadPopup';
import { ChangeActStatus } from '@/components/ChangeActStatus';
import { DisabledTextField } from '@/components/fixUI/DisabledTextField';
import CopyButton from '@/components/button/CopyButton';
import { AccessEnum } from '@/types/roles';
import { AccessController } from '@/components/common/AccessController';
import { FetchCathError } from '@/types/Types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import {
  getColorByStatus,
  ReplacementStateIcon,
} from '@/components/ReplacementStateIcon/ReplacementStateIcon';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useStatus } from '@/components/features/taskEditor/panel/store';

import { useDisabledAll } from '@/hooks/useDisabledAll';
import { ReplacementPuDialog } from '@/components/features/taskEditor/dialog/ReplacemetnPuDialog';
import { MeterNewItem } from './MeterNewItem';
import DateInfoString from '@/components/DateInfoString';

interface GeneralReplacementProps {
  replacementResponse: ReplacementResponseList;
  mode: ModeViewerResult;
  modeCallback: () => (isExpanded: boolean) => void;
  preview: React.ReactNode;
  children?: React.ReactNode;
  ids?: { executorId?: string; id?: string; contractorId?: string; vruId?: string };
  taskType?: string;
  updateReplacementResponse?: () => void;
  simStatus?: string;
}
const checkIfValueIsYes = (value: string) => {
  return value === 'да';
};

export const GeneralReplacement: FC<GeneralReplacementProps> = (props) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const {
    preview,
    children,
    ids,
    taskType,
    updateReplacementResponse,
    simStatus,
    mode = ModeViewerResult.SMALL,
    modeCallback,
  } = props;
  const methods = useFormContext();
  const [replacementResponse, setReplacementResponse] = useState<ReplacementResponseList>(
    props.replacementResponse
  );
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const [openReplacementReasonDialog, setOpenReplacementReasonDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openOldPUIndicationIncorrect, setOpenOldPUIndicationIncorrect] = useState(false);
  const [openRepeatInstallationDialog, setOpenRepeatInstallationDialog] = useState(false);
  const [openReplacementViewDialog, setOpenReplacementViewDialog] = useState(false);
  const [openReplacementIncorrect, setOpenReplacementIncorrect] = useState(false);
  const { setIsInProgress } = useScreenHoldHook();
  const { addActionLog, catchError, fetchCatch } = useActionLog();
  const [analyzeLog, setAnalyzeLog] = useState<YungaAnalyzeResponse>();
  const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false);
  const [actStatus, setActStatus] = useState<string | undefined>(
    props.replacementResponse.actStatus
  );
  const [isChangeMainFields, setIsChangeMainFields] = useState<boolean>(false);

  const [isActual, setIsActual] = useState<boolean>(!!props.replacementResponse.actual);

  useEffect(() => {
    if (props?.replacementResponse?.id) {
      findYungaAnalyzeLogByReplacementId(props.replacementResponse?.id)
        .then((res) => setAnalyzeLog(res.data))
        .catch((err) => catchError);
    }
  }, [props.replacementResponse]);

  const toSwap = (id?: string) => {
    if (replacementResponse && id) {
      setIsInProgress(true);
      toSwapReplacement(replacementResponse?.id, id)
        .then((res) => {
          setReplacementResponse(res.data);
          addActionLog(ActionLogType.SUCCESS, 'Замены переключены');
        })
        .catch((err) => catchError('Ошибка при переключении замен', err))
        .finally(() => setIsInProgress(false));
    }
    setOpenReplacementViewDialog(false);
  };
  const swapByTaskId = (taskId?: string) => {
    if (replacementResponse && taskId) {
      setIsInProgress(true);
      toSwapReplacementByTaskId(replacementResponse?.id, taskId)
        .then((res) => {
          setReplacementResponse(res.data);
          addActionLog(ActionLogType.SUCCESS, 'Замены переключены');
        })
        .catch((err) => {
          catchError(`Ошибка при переключении замен ${err?.response?.data}`, err);
        })
        .finally(() => setIsInProgress(false));
    }
    setOpenReplacementViewDialog(false);
  };

  const toRepeatInstallation = () => {
    if (replacementResponse) {
      setIsInProgress(true);
      toRepeatInstallationReplacement(replacementResponse.id)
        .then((res) => {
          setReplacementResponse(res.data);
          addActionLog(ActionLogType.SUCCESS, 'Повторная установка произведена');
          updateReplacementResponse && updateReplacementResponse();
        })
        .catch((err) => catchError('Ошибка при повторной установке', err))
        .finally(() => setIsInProgress(false));
    }
    setOpenRepeatInstallationDialog(false);
  };

  const handleSendAct = () => {
    if (replacementResponse) {
      setIsInProgress(true);
      replacementSendAct(replacementResponse.id)
        .then((res) => {
          setActStatus(res.data);
          addActionLog(ActionLogType.SUCCESS, 'Акт успешно отправлен');
          updateReplacementResponse && updateReplacementResponse();
        })
        .catch((err) => catchError('Ошибка при отправке акта', err))
        .finally(() => setIsInProgress(false));
    }
  };

  const onSave = (data: any) => {
    if (!disabledAll! && !onlyShow && replacementResponse) {
      const id = replacementResponse.id;
      if (id && data) {
        const tmpData = data[id];
        const saveData = {
          ...tmpData,
          replacementRequest: {
            ...tmpData.replacementRequest,
            isPickUpOldMeter: checkIfValueIsYes(tmpData.replacementRequest.isPickUpOldMeter),
            isSignedTransferredToMesCalculation: checkIfValueIsYes(
              tmpData.replacementRequest.isSignedTransferredToMesCalculation
            ),
            mounted: {
              ...tmpData.replacementRequest.mounted,
              type: getCatalogMapWithErr('referenceMeterType').get(
                tmpData.replacementRequest.mounted.type
              ),
            },
          },
        };
        setIsInProgress(true);
        saveReplacement(id, saveData)
          .then(() => {
            addActionLog(ActionLogType.SUCCESS, `Замена успешно сохранена`);
            updateReplacementResponse && updateReplacementResponse();
          })
          .catch((err) => {
            catchError(`Ошибка при сохранении замены. ${err?.response?.data?.message ?? ''}`, err);
          })
          .finally(() => setIsInProgress(false));
      } else {
        throw new Error('Ошибка при сохранении замены, данные некорректны');
      }
    }
  };

  const handleChangeStatus = () => {
    if (replacementResponse) {
      changeStatus(replacementResponse.id)
        .then((res) => {
          setIsActual(!!res.data?.actual);
          addActionLog(ActionLogType.SUCCESS, 'Статус изменен');
          if (taskType !== 'SMR_ODPU') {
            updateReplacementResponse && updateReplacementResponse();
          }
        })
        .catch((err) => catchError('Ошибка при изменении статуса', err))
        .finally(() => setIsInProgress(false));
    }
  };

  const handleRemoveByGuarantee = () => {
    if (replacementResponse) {
      removeByguarantee(replacementResponse.id)
        .then((res) => {
          addActionLog(ActionLogType.SUCCESS, 'Статус изменен');
          if (taskType !== 'SMR_ODPU') {
            updateReplacementResponse && updateReplacementResponse();
          }
        })
        .catch((err) => catchError('Ошибка при изменении статуса', err))
        .finally(() => setIsInProgress(false));
    }
  };

  const handleOldPUIndicationsIncorrect = () => {
    if (replacementResponse) {
      setIsInProgress(true);
      oldPUIndicationsIncorrect(replacementResponse.id)
        .then(() => {
          addActionLog(
            ActionLogType.SUCCESS,
            `Показания старого ПУ заменены на "некорректная индикация"`
          );
          updateReplacementResponse && updateReplacementResponse();
        })
        .catch((err: FetchCathError) => fetchCatch(err))
        .finally(() => setIsInProgress(false));
    }
  };

  const [replacedOn, setReplacedOn] = useState<Date | null>(
    replacementResponse?.replacedOn ?? null
  );

  useEffect(() => {
    if (!replacedOn) {
      setReplacedOn(null);
      methods.setValue(`${replacementResponse.id}.replacementRequest.changeDate`, null);
    }
  }, [replacedOn]);

  const onHandleEventOn = (newValue: Date | null) => {
    if (newValue) {
      setReplacedOn(newValue);
      methods.setValue(
        `${replacementResponse.id}.replacementRequest.changeDate`,
        moment(newValue).valueOf()
      );
    }
  };

  return (
    <BoxShadow {...getColorByStatus(replacementResponse?.replacementState)}>
      <DownloadPopup
        id={replacementResponse?.id ? replacementResponse.id : ''}
        isOpen={isOpenDownload}
        onClose={() => setIsOpenDownload(false)}
      />
      {openReplacementReasonDialog && (
        <ReplacementCancelDialog
          replacementId={replacementResponse?.id}
          openDialog={openReplacementReasonDialog}
          onCLose={() => setOpenReplacementReasonDialog(false)}
          onReplacementChange={setReplacementResponse}
        />
      )}
      {mode === ModeViewerResult.SMALL && <>{preview}</>}
      {(mode === ModeViewerResult.MEDIUM || mode === ModeViewerResult.LARGE) && (
        <Stack
          width={'100%'}
          spacing={2}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center' }}
              >
                <ReplacementStateIcon status={replacementResponse?.replacementState} />
                {/*<ActualStatus*/}
                {/*  isActual={isActual}*/}
                {/*  onSuccess={handleChangeStatus}*/}
                {/*/>*/}
                {/* TODO галочка статуса */}
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>
                    {getCatalogMapWithErr('changeStatus')?.get(replacementResponse?.status || '')}
                  </span>
                </Typography>
                <Typography ml={4}>
                  <span style={{ fontWeight: 'bold' }}>Акт:</span>{' '}
                  {getCatalogMapWithErr('actStatus')?.get(replacementResponse?.actStatus || '')}
                </Typography>
                <Typography ml={4}>
                  <span style={{ fontWeight: 'bold' }}>Пломба:</span>{' '}
                  {getCatalogMapWithErr('stampInstallStatus')?.get(
                    replacementResponse?.stampInstallStatus || ''
                  )}
                </Typography>
                {simStatus && (
                  <Typography ml={4}>
                    <span style={{ fontWeight: 'bold' }}>SIM:</span> {simStatus}
                  </Typography>
                )}
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                  <FileDownloadRoundedIcon
                    fontSize={'small'}
                    sx={{ mr: 1, color: 'gray', cursor: 'pointer' }}
                    onClick={() => setIsOpenDownload(true)}
                  />
                  <YungaAnalyzeButton
                    id={replacementResponse?.id}
                    sx={{ mr: 1 }}
                    onAction={(response) => setAnalyzeLog(response)}
                  />
                  <IdPopup sx={{ color: 'gray' }}>
                    {ids?.id && (
                      <div>
                        <CopyButton textToCopy={ids?.id} />
                        id заявки {ids?.id}
                      </div>
                    )}
                    {ids?.vruId && (
                      <div>
                        <CopyButton textToCopy={ids?.vruId} />
                        id ВРУ {ids?.vruId}
                      </div>
                    )}
                    {replacementResponse?.id && (
                      <div>
                        <CopyButton textToCopy={replacementResponse?.id} />
                        id замены {replacementResponse?.id}
                      </div>
                    )}
                  </IdPopup>
                </div>
              </Stack>
            </Grid>
            <Grid
              xs={12}
              item
              sx={{ textAlign: 'right' }}
            >
              <DateInfoString
                date={replacementResponse?.ksSmr}
                title={'Дата акт. СМР: '}
              />{' '}
              <DateInfoString
                date={replacementResponse?.ksPnr}
                title={'ПНР: '}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='outlined-basic'
                label='Последнее сообщение'
                variant='outlined'
                sx={{ mb: 0.3, width: '100%' }}
                disabled
                multiline
                rows={3}
                defaultValue={replacementResponse?.lastValidMessage}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label='Дата замены'
                  inputFormat='dd.MM.yyyy'
                  value={replacedOn}
                  onChange={onHandleEventOn}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%' }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <AccessController access={[AccessEnum.CastomPskClose]}>
              <Grid
                item
                xs={3}
              >
                <DisabledTextField
                  id={'tf-montage-name'}
                  label={'Заменено кем'}
                  defaultValue={replacementResponse?.montageName}
                  // keyString={`${replacementResponse.id}.replacementRequest.montageName`}
                />
              </Grid>
            </AccessController>
            <Grid
              item
              xs={3}
            >
              <DisabledTextField
                id={'tf-replace-date'}
                label={'Дата возврата'}
                defaultValue={toFormateDate(replacementResponse?.actReturnOn)}
                // keyString={`${replacementResponse.id}.replacementRequest.actReturnOn`}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <DisabledTextField
                id='tf-replace-date'
                label={'Дата передачи заказчику'}
                defaultValue={toFormateDate(replacementResponse?.actTransferOn)}
                // keyString={`${replacementResponse.id}.replacementRequest.actTransferOn`}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <DisabledTextField
                id='tf-act-create-on'
                label={'АДПУ оформлен'}
                // keyString={`${replacementResponse.id}.replacementRequest.actCreateOn`}
                defaultValue={toFormateDate(replacementResponse?.actCreateOn)}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <DisabledTextField
                id='tf-rejection-reason'
                label={'Причина отказа от подписи'}
                // keyString={`${replacementResponse.id}.replacementRequest.actRejectionReason`}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <DisabledTextField
                id='tf-fio'
                label={'ФИО'}
                // keyString={`${replacementResponse.id}.replacementRequest.actAgentName`}
                defaultValue={replacementResponse?.actAgentName}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <DisabledTextField
                id='tf-phone'
                label={'Телефон'}
                // keyString={`${replacementResponse.id}.replacementRequest.actAgentPhone`}
                defaultValue={replacementResponse?.actAgentPhone}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <DisabledTextField
                id='tf-send-in-to-on'
                label={'Дата передачи в ТО'}
                // keyString={`${replacementResponse.id}.replacementRequest.sendInToOn`}
                defaultValue={toFormateDate(replacementResponse?.sendInToOn)}
              />
            </Grid>
            <Grid
              item
              xs={9}
            />

            <Grid
              item
              xs={6}
              sx={{ mt: 3 }}
            >
              <Typography
                mb={2}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <span style={{ fontWeight: 'bold' }}>Существующий ПУ:</span>{' '}
                <div style={{ marginLeft: '16px', flex: ' 1 1 auto' }}>
                  {replacementResponse?.unmounted?.type}
                </div>
              </Typography>
              {replacementResponse &&
                replacementResponse?.unmounted &&
                replacementResponse?.stampInstallResponse && (
                  <MeterItem
                    stampInstall={replacementResponse.stampInstallResponse}
                    measurement={replacementResponse.existingMeasurementResponse}
                    meter={replacementResponse.unmounted}
                    mode={mode}
                    keyString={`${replacementResponse.id}.replacementRequest`}
                    type={Types.OLD}
                    isSignedTransferredToMesCalculation={
                      replacementResponse?.isSignedTransferredToMesCalculation
                    }
                    isPickUpOldMeter={replacementResponse?.isPickUpOldMeter}
                    analyze={analyzeLog?.unmounted}
                    factoryStamp={replacementResponse?.factoryStamp}
                    breaker={replacementResponse.automaticBreakerId}
                  />
                )}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ mt: 3 }}
            >
              {replacementResponse &&
                replacementResponse?.mounted &&
                replacementResponse?.stampInstallResponse && (
                  <MeterNewItem
                    stampInstall={replacementResponse.stampInstallResponse}
                    measurement={replacementResponse.newMeasurementResponse}
                    meter={replacementResponse.mounted}
                    replacement={replacementResponse}
                    mode={mode}
                    keyString={`${replacementResponse.id}.replacementRequest`}
                    analyze={analyzeLog?.mounted}
                    factoryStamp={replacementResponse?.factoryStamp}
                    breaker={replacementResponse?.automaticBreakerId}
                    locker={replacementResponse?.smrLockerId}
                    onChangeMainFields={setIsChangeMainFields}
                  />
                )}
            </Grid>
          </Grid>
          <Grid item>{mode === ModeViewerResult.LARGE && <>{children}</>}</Grid>
          <Stack
            direction={'row'}
            sx={{ alignItems: 'center', flexWrap: 'wrap' }}
          >
            <Button
              variant='text'
              size='small'
              onClick={() => setOpenReplacementReasonDialog(true)}
              disabled={disabledAll || onlyShow || replacementResponse?.status === 'CANCELED'}
            >
              Отменить замену
            </Button>

            <Button
              variant='text'
              size='small'
              onClick={(e) => {
                isChangeMainFields
                  ? methods?.handleSubmit(onSave)(e)
                  : setOpenRepeatInstallationDialog(true);
              }}
              disabled={disabledAll || onlyShow}
            >
              {isChangeMainFields ? 'Сохранить и повторить установку' : 'Повторить установку'}
            </Button>
            <ConfirmationDialog
              title=''
              open={openRepeatInstallationDialog}
              handleOk={toRepeatInstallation}
              handleCancel={() => setOpenReplacementReasonDialog(false)}
            >
              Повторить установку ПУ?
            </ConfirmationDialog>

            <ReplacementPuDialog
              id={replacementResponse.id}
              replacement={replacementResponse}
              update={updateReplacementResponse}
            />

            <Button
              variant='text'
              size='small'
              onClick={() => setOpenReplacementViewDialog(true)}
              disabled={disabledAll || onlyShow}
            >
              Поменять замены местами
            </Button>
            <ReplacementViewDialog
              openDialog={openReplacementViewDialog}
              handleOk={toSwap}
              swapByTaskId={swapByTaskId}
              closeDialog={() => setOpenReplacementViewDialog(false)}
            />

            {replacementResponse?.status === 'IN_USED' && (
              <>
                <Button
                  variant='text'
                  size='small'
                  onClick={() => setOpenReplacementIncorrect(true)}
                  disabled={disabledAll || onlyShow}
                >
                  Смонтирован у меня
                </Button>
                <ReplacementIncorrect
                  openDialog={openReplacementIncorrect}
                  closeDialog={() => setOpenReplacementIncorrect(false)}
                  replacementId={replacementResponse?.id}
                  handleChangeReplacement={setReplacementResponse}
                />
              </>
            )}

            <Button
              variant='text'
              size='small'
              onClick={() => setOpenConfirm(true)}
              disabled={disabledAll || onlyShow || replacementResponse?.status !== 'IS_USED'}
            >
              ПУ смонтирован у меня
            </Button>
            <ConfirmationDialog
              open={openConfirm}
              title='Вы уверены что ПУ смонтирован у вас?'
              handleCancel={() => setOpenConfirm(false)}
              handleOk={() => {
                handleRemoveByGuarantee();
                setOpenConfirm(false);
              }}
              textForOk='да'
            />

            <Button
              variant='text'
              size='small'
              onClick={() => setOpenOldPUIndicationIncorrect(true)}
              disabled={disabledAll || onlyShow}
            >
              Показания старого ПУ некорректны
            </Button>
            <ConfirmationDialog
              open={openOldPUIndicationIncorrect}
              title='Предупреждение'
              handleCancel={() => setOpenOldPUIndicationIncorrect(false)}
              handleOk={() => {
                handleOldPUIndicationsIncorrect();
                setOpenOldPUIndicationIncorrect(false);
              }}
              textForOk='да'
            >
              <span>
                Данные в показаниях старого ПУ будут безвозвратно заменены на &ldquo;некорректная
                индикация&rdquo;.
                <br /> Подтвердить?
              </span>
            </ConfirmationDialog>

            <ChangeActStatus
              actStatus={actStatus}
              taskType={taskType}
              onSuccess={handleSendAct}
            />
            <Grid sx={{ ml: 'auto' }}>
              {
                <SaveIcon
                  onClick={methods?.handleSubmit(onSave)}
                  sx={{
                    color: 'gray',
                    fontSize: 22,
                    cursor: disabledAll || onlyShow ? '' : 'pointer',
                    ml: 2,
                  }}
                />
              }
            </Grid>
            <Grid>
              <ExpandButton
                expand={mode === ModeViewerResult.LARGE}
                onAction={modeCallback()}
              />
            </Grid>
          </Stack>
        </Stack>
      )}
    </BoxShadow>
  );
};
