import { YMaps, Map, Clusterer, Polyline, Placemark, FullscreenControl } from 'react-yandex-maps';
import { usePpoMap } from '../store';
import React, { useEffect, useMemo, useState } from 'react';
import { mapBoundsToGeoBounds } from '@/utils/heplers';
import { PpoPlacemark } from '../placemark';
import { GeoTask, GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { DeliveryPointPlacemark } from '../placemark/delivery-point';
import { useCoords } from '@/components/maps/ppoMap/coordsStore';
import { usePillar } from '../resCard/pillarStore';
import { useDeliveryPoints } from '../placemark/delivery-point/store';
import { createTask, saveDeliveryPointById, saveTask } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { findAllDeliveryPoint } from '../service';
import { MapAlertComponent } from './MapAlertComponent';
import { useRouterStore } from '../resCard/routerStore';
import { RouterPointPlacemark } from '../placemark/router-point';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { PillarsPlacemark } from '../placemark/pillars';

const color_arr = [
  '#006600',
  '#0000CC',
  '#990066',
  '#990000',
  '#FF6600',
  '#009900',
  '#333333',
  '#0099CC',
];

interface ILine {
  feederId: string;
  line: number[][];
}

export const PpoMapPane = () => {
  const { setGeoBounds, geoTransStation, geoTaskInBounds, tmpTaskId, setGeoTransStation } =
    usePpoMap();
  const [mapRef, setMapRef] = useState<any>();
  const { tmpDeliveryPoint, setTmpDeliveryPoint } = useDeliveryPoints();
  const { catchError, addActionLog } = useActionLog();
  const { setIsInProgress } = useScreenHoldHook();

  const geoTask: GeoTask = useMemo(() => {
    return geoTaskInBounds
      ?.flatMap((elem) => elem.geoTaskList) // распределяет все списки задач в один массив
      .find((task) => task.taskId === tmpTaskId);
  }, [tmpTaskId, geoTaskInBounds, geoTransStation?.deliveryPoints]) as GeoTask;

  useEffect(() => {
    setGeoBounds(mapBoundsToGeoBounds(mapRef?.getBounds()));
  }, [mapRef]);
  const getLines = (): ILine[] => {
    return (
      (geoTransStation &&
        geoTransStation.deliveryPoints?.map((elem) => {
          const tmpPillar = geoTransStation.pillars?.find((el) => el.id === elem.pillarId);
          return {
            feederId: elem.feederId ?? '',
            line: tmpPillar
              ? [
                  [tmpPillar.longitude, tmpPillar.latitude],
                  [elem.longitude, elem.latitude],
                ]
              : [[elem.longitude, elem.latitude]],
          };
        })) ||
      []
    );
  };
  const groupLinesByFeeder = (): ILine[][] => {
    const tmpArr = getLines();
    const feeders = tmpArr.reduce((res, elem: ILine) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res[elem.feederId] = res[elem.feederId] || [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res[elem.feederId].push(elem);
      return res;
    }, {});
    const result = [];
    for (const key in feeders) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result.push(feeders[key]);
    }
    return result;
  };

  const { isEditMode, setLatitude, setLongitude, changeMode } = useCoords();
  const { isEditPillarMode, changeModePillar, setPillar, pillar } = usePillar();
  const {
    changeRouterMode,
    isEditRouterMode,
    tempDataRouter,
    setLatitudeRouter,
    setLongitudeRouter,
    latitudeRouter,
    longitudeRouter,
    showRouters,
  } = useRouterStore();

  const changePillar = () => {
    if (tmpDeliveryPoint && pillar) {
      saveDeliveryPointById(
        {
          ...tmpDeliveryPoint,
          pillar: pillar,
        },
        tmpDeliveryPoint.id
      )
        .then((res) => {
          setTmpDeliveryPoint(tmpDeliveryPoint.id, res.data);
          addActionLog(ActionLogType.SUCCESS, 'Сохранено');
          tmpTaskId &&
            findAllDeliveryPoint(tmpTaskId)
              .then(({ data }) => setGeoTransStation(data))
              .catch((err) => catchError('Ошибка при загрузке точек поставки', err));
        })
        .catch((err) => catchError('Не сохранено', err))
        .finally(() => changeModePillar());
    }
  };
  const createOrEditRouter = () => {
    if (geoTransStation && !tempDataRouter) {
      setIsInProgress(true);
      createTask(geoTransStation.id, {
        contractId: geoTransStation?.contractId ?? '',
        contractorId: geoTransStation?.contractorId ?? '',
        regionalElectricNetworkId: geoTransStation?.resId ?? '',
        transStationName: geoTask.tpName,
        latitude: latitudeRouter,
        longitude: longitudeRouter,
        networkEquipmentTypeID: '2592166e-96de-6b80-57c6-172c5fb63068',
      })
        .then(() => {
          addActionLog(ActionLogType.SUCCESS, 'Успешно');
          findAllDeliveryPoint(geoTask.taskId)
            .then(({ data }) => setGeoTransStation(data))
            .catch((err) => catchError('Ошибка при загрузук точек поставки', err));
          changeRouterMode(false);
        })
        .catch((res) => {
          catchError('Ошибка при загрузук точек поставки', res);
        })
        .finally(() => setIsInProgress(false));
    } else {
      if (geoTransStation?.id) {
        setIsInProgress(true);
        const updateData = {
          contractorId: geoTransStation?.contractorId ?? '',
          executorId: tempDataRouter?.executorId,
          priority: tempDataRouter?.priority,
          type: tempDataRouter.type,
          taskInfoRequest: {
            ...tempDataRouter.tempDataRouter,
            latitude: latitudeRouter,
            longitude: longitudeRouter,
          },
        };
        saveTask(tempDataRouter?.id, updateData).then((data) => {
          findAllDeliveryPoint(geoTask.taskId)
            .then(({ data }) => setGeoTransStation(data))
            .catch((err) => catchError('Ошибка при загрузук точек поставки', err))
            .finally(() => {
              setIsInProgress(false);
              changeRouterMode(false);
            });
        });
      }
    }
  };

  const newRouter = !!latitudeRouter && !!longitudeRouter;

  return (
    <div style={{ position: 'relative' }}>
      <MapAlertComponent
        isEditMode={isEditMode}
        isEditPillarMode={isEditPillarMode}
        isEditRouterMode={isEditRouterMode}
        changeMode={changeMode}
        changeModePillar={changeModePillar}
        changeRouterMode={changeRouterMode}
        changePillar={changePillar}
        pillar={pillar}
        newRouter={newRouter}
        createOrEditRouter={createOrEditRouter}
      />

      <YMaps
        query={{
          ns: 'use-load-option',
          load: 'Map,Placemark,control.ZoomControl,geoObject.addon.balloon,geoObject.addon.hint',
          coordorder: 'longlat',
        }}
      >
        <Map
          instanceRef={(ref) => ref && setMapRef(ref)}
          onClick={(e: any) => {
            const coords = e.get('coords');
            if (isEditMode) {
              setLongitude(coords[0]);
              setLatitude(coords[1]);
              changeMode();
            }
            if (isEditRouterMode) {
              setLongitudeRouter(coords[0]);
              setLatitudeRouter(coords[1]);
            }
          }}
          modules={['geoQuery']}
          defaultState={{
            center: [37.57, 55.75],
            zoom: 5,
            controls: ['zoomControl'],
          }}
          width={'100%'}
          height={'75vh'}
          options={{ autoFitToViewport: 'always', minZoom: 2, delay: 2000 }}
          defaultOptions={{ minZoom: 2 }}
          // onBoundsChange={onMapBoundsChange}
        >
          <Clusterer options={{ minClusterSize: 10 }}>
            {geoTaskInBounds
              .filter((t) => t?.geoTaskList && t.geoTaskList?.length < 1000)
              .map((t: GeoTaskInBounds, i) => (
                <PpoPlacemark
                  key={`ppo-placemark-${i}`}
                  geoTaskInBounds={t}
                />
              ))}
          </Clusterer>
          {geoTransStation &&
            geoTransStation.pillars?.map((t, i) => (
              <PillarsPlacemark
                key={`placemark-${t.id}`}
                geoObject={t}
              />
            ))}
          {geoTaskInBounds
            .filter((t) => t?.geoTaskList && t.geoTaskList?.length >= 1000)
            .map((t: GeoTaskInBounds, i) => (
              <PpoPlacemark
                key={`pp-placemark-cluster-${i}`}
                geoTaskInBounds={t}
              />
            ))}
          {geoTransStation &&
            geoTransStation.deliveryPoints?.map((t, i) => (
              <DeliveryPointPlacemark
                key={`delivery-point-placemark-${t.id}`}
                geoObject={t}
              />
            ))}
          {groupLinesByFeeder()?.map((t, i) =>
            t.map((el, j) => (
              <Polyline
                key={`line-${i}-${j}`}
                geometry={el.line}
                options={{
                  balloonCloseButton: false,
                  strokeColor: color_arr[i % color_arr.length],
                  strokeWidth: 2,
                }}
              />
            ))
          )}
          {newRouter && (
            <Placemark
              key={`placemark-router-new`}
              geometry={[longitudeRouter, latitudeRouter]}
              options={{
                balloonPanelMaxMapArea: 0,
                preset: 'islands#redIcon',
              }}
            />
          )}
          {showRouters &&
            geoTransStation?.routers?.map((router, index) => (
              <RouterPointPlacemark
                geoObject={router}
                key={index}
              />
            ))}
          <FullscreenControl />
        </Map>
      </YMaps>
    </div>
  );
};
