import { NetworkElementResponse } from '@/dto/NetworkDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useMemo } from 'react';
import { HouseLevelFloorType } from '../blocks/PnrElementsInfo/utils';
import { NetworkStatus } from './NetworkStateIcon';

export const mapNetworkDataHouseLevel = (data: any, networkData: NetworkElementResponse) => {
  const result = { ...data };

  delete result?.houseLevel?.id;
  result.houseLevel.entranceNumber = data?.houseLevel?.entranceNumber
    ? +data?.houseLevel?.entranceNumber
    : null;

  if (data?.houseLevel?.floorType === HouseLevelFloorType.FLOOR) {
    result.houseLevel.floorNumber = data?.houseLevel?.floorNumber
      ? +data?.houseLevel?.floorNumber
      : null;
  }

  if (data?.houseLevel?.floorType === HouseLevelFloorType.ATTIC) {
    result.houseLevel.floorNumber = -1;
  }

  if (data?.houseLevel?.floorType === HouseLevelFloorType.BASEMENT) {
    result.houseLevel.floorNumber = 0;
  }

  if (!data?.houseLevel?.floorType) {
    result.houseLevel.floorNumber = null;
  }
  return result;
};

export const useNetworkElementMontagePlaces = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const montagePlace = getCatalogMapWithErr('networkElementMontagePlace');

  const montagePlaceOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('networkElementMontagePlace')),
    [montagePlace]
  );

  return { montagePlaceOptions, montagePlace };
};

export const sortNetworkData = (networkData: { [key: string]: NetworkElementResponse }) => {
  return Object.keys(networkData).sort((a, b) => {
    const statusA = networkData[a].installStatus;
    const statusB = networkData[b].installStatus;
    if (statusA === NetworkStatus.NOT_RELEVANT && statusB !== NetworkStatus.NOT_RELEVANT) return 1;
    if (statusA !== NetworkStatus.NOT_RELEVANT && statusB === NetworkStatus.NOT_RELEVANT) return -1;
    return 0;
  });
};
