import React, { FC, useMemo, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { Button, Grid, Stack, Typography } from '@mui/material';
import ResRow from '@/components/maps/ppoMap/resCard/resRow';
import { Link } from 'react-router-dom';

import Point from '@/components/Point';
import { MoreInfo } from '@/components/maps/ppoMap/resCard/index.styled';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { GeoTask } from '@/dto/taskmap/Dto';
import { DeliveryPointGeo, ResultType, Subscriber } from '@/components/maps/ppoMap/dto';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { findAllDeliveryPoint } from '@/components/maps/ppoMap/service';
import { useCatalog } from '@/hooks/CatalogHook';
import { createTask, findTaskById } from '@/services/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useRouterStore } from './routerStore';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { SMRBox } from '@/components/features/taskEditor/deliveryPoint/index.styled';

interface IResCard extends GeoTask {
  deliveryPoints?: DeliveryPointGeo[];
  t: GeoTask;
}

const ResCard: FC<IResCard> = ({
  electricNetworksEnterpriseName,
  resName,
  tpName,
  deliveryPoints,
  t,
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showInfoRouters, setShowInfoRouters] = useState<boolean>(false);
  const { setIsInProgress } = useScreenHoldHook();

  const toggleShow = () => setShowInfo(!showInfo);
  const toggleShowRouters = () => setShowInfoRouters(!showInfoRouters);
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const { fetchCatch, addActionLog } = useActionLog();

  const { setActive, tmpTaskId, geoTransStation, setGeoTransStation } = usePpoMap();
  const { changeRouterMode, setShowRouters, setTempDataRouter } = useRouterStore();

  const noTaskCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.result === ResultType.NO_TASK).length ?? 0,
    [deliveryPoints]
  );
  const smrDoneWithActCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.result === ResultType.SMR_DONE_WITH_ACT).length ?? 0,
    [deliveryPoints]
  );
  const smrDoneWithoutActCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.result === ResultType.SMR_DONE_WITHOUT_ACT).length ?? 0,
    [deliveryPoints]
  );
  const smrCompleteCount = useMemo(
    () => smrDoneWithActCount + smrDoneWithoutActCount,
    [deliveryPoints]
  );
  const smrNotCompleteCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.result === ResultType.SMR_NOT_COMPLETE).length ?? 0,
    [deliveryPoints]
  );
  const tkoCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.result === ResultType.TKO).length ?? 0,
    [deliveryPoints]
  );
  const smrCount = useMemo(
    () => smrNotCompleteCount + smrCompleteCount + tkoCount,
    [deliveryPoints]
  );

  const allCount = useMemo(() => deliveryPoints?.length ?? 0, [deliveryPoints]);
  const routersCount = useMemo(() => geoTransStation?.routers?.length ?? 0, [geoTransStation]);
  const fromDBCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.subscriberSource === Subscriber.FROM_DB).length ?? 0,
    [deliveryPoints]
  );
  const createdByUserCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.subscriberSource === Subscriber.CREATED_BY_USER)
        .length ?? 0,
    [deliveryPoints]
  );
  const noCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.subscriberSource === Subscriber.NO).length ?? 0,
    [deliveryPoints]
  );

  const uspdStatus = useMemo(
    () => getCatalogMapWithErr('taskStatus').get(geoTransStation?.uspd?.taskStatus ?? ''),
    [geoTransStation]
  );

  const createUspd = () => {
    if (geoTransStation) {
      setIsInProgress(true);
      createTask(geoTransStation.id, {
        contractId: geoTransStation?.contractId ?? '',
        contractorId: geoTransStation?.contractorId ?? '',
        regionalElectricNetworkId: geoTransStation?.resId ?? '',
        transStationName: tpName,
        networkEquipmentTypeID: '3dc4f179-870c-24b6-9af4-50837bb31459',
      })
        .then(() => {
          addActionLog(ActionLogType.SUCCESS, 'Заявка успешно создана');
          findAllDeliveryPoint(t.taskId)
            .then(({ data }) => setGeoTransStation(data))
            .catch((err) => fetchCatch(err));
          changeRouterMode(false);
        })
        .catch((res) => {
          fetchCatch(res);
        })
        .finally(() => setIsInProgress(false));
    }
  };

  const getTempDataFromTask = (id: string) => {
    setIsInProgress(true);
    findTaskById(id)
      .then(({ data }) => {
        setTempDataRouter(data);
        changeRouterMode(true);
      })
      .catch((res) => {
        fetchCatch(res);
        setTempDataRouter(null);
      })
      .finally(() => setIsInProgress(false));
  };

  return (
    <BoxShadow
      background={color('lightWhite')}
      sx={{ mb: 2 }}
    >
      <Stack>
        <Typography
          variant={'h5'}
          fontWeight={'500'}
        >
          {electricNetworksEnterpriseName} - {resName} - {tpName}
        </Typography>
        <ResRow
          title={'Точек Поставки'}
          count={allCount}
          onCountClick={() =>
            setActive({
              activeSub: [Subscriber.FROM_DB, Subscriber.CREATED_BY_USER, Subscriber.NO],
            })
          }
        >
          <Stack
            direction='row'
            spacing={2}
          >
            <Point
              count={fromDBCount}
              type={Subscriber.FROM_DB}
              onClick={() => setActive({ activeSub: [Subscriber.FROM_DB] })}
            />
            <Point
              count={createdByUserCount}
              type={Subscriber.CREATED_BY_USER}
              onClick={() => setActive({ activeSub: [Subscriber.CREATED_BY_USER] })}
            />
            <Point
              count={noCount}
              type={Subscriber.NO}
              onClick={() => setActive({ activeSub: [Subscriber.NO] })}
            />
          </Stack>
        </ResRow>
        <ResRow
          title={'Точки без заявки'}
          count={noTaskCount}
          onCountClick={() => setActive({ activeRes: [ResultType.NO_TASK] })}
        />
        <ResRow
          title={'Заявки СМР'}
          count={smrCount}
          onCountClick={() =>
            setActive({
              activeRes: [
                ResultType.SMR_DONE_WITHOUT_ACT,
                ResultType.SMR_NOT_COMPLETE,
                ResultType.SMR_DONE_WITH_ACT,
                ResultType.TKO,
              ],
            })
          }
        >
          {showInfo ? (
            <VisibilityOutlinedIcon
              sx={{ cursor: 'pointer' }}
              onClick={toggleShow}
            />
          ) : (
            <VisibilityOffOutlinedIcon
              sx={{ cursor: 'pointer' }}
              onClick={toggleShow}
            />
          )}
        </ResRow>
        <ResRow
          title={'Монтаж роутеров'}
          count={routersCount}
          onCountClick={setShowRouters}
        >
          <>
            {showInfoRouters ? (
              <VisibilityOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={toggleShowRouters}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={toggleShowRouters}
              />
            )}
            <Button onClick={() => changeRouterMode(true)}>{'Создать заявку'}</Button>
          </>
        </ResRow>

        {showInfo && (
          <MoreInfo>
            <ResRow
              title={'СМР проведено'}
              count={smrCompleteCount}
              onCountClick={() =>
                setActive({
                  activeRes: [ResultType.SMR_DONE_WITHOUT_ACT, ResultType.SMR_DONE_WITH_ACT],
                })
              }
            />
            <ResRow
              title={'АДПУ+'}
              count={smrDoneWithActCount}
              onCountClick={() => setActive({ activeRes: [ResultType.SMR_DONE_WITH_ACT] })}
            />
            <ResRow
              title={'АДПУ-'}
              count={smrDoneWithoutActCount}
              onCountClick={() => setActive({ activeRes: [ResultType.SMR_DONE_WITHOUT_ACT] })}
            />
            <ResRow
              title={'СМР не проведено'}
              count={smrNotCompleteCount}
              onCountClick={() => setActive({ activeRes: [ResultType.SMR_NOT_COMPLETE] })}
            />
            <ResRow
              title={'ТКО'}
              count={tkoCount}
              onCountClick={() => setActive({ activeRes: [ResultType.TKO] })}
            />
          </MoreInfo>
        )}

        {showInfoRouters && !!routersCount && (
          <MoreInfo>
            {geoTransStation?.routers?.length &&
              geoTransStation?.routers?.map((router, index) => {
                return (
                  <Stack
                    flexDirection={'row'}
                    gap={5}
                    justifyContent={'space-between'}
                    key={index}
                  >
                    <Button onClick={() => window.open(`/task/edit/${router.taskId}`)}>
                      {router.taskNumber}
                    </Button>

                    <Typography>
                      {getCatalogMapWithErr('taskStatus').get(router.taskStatus)}
                    </Typography>
                    <Button
                      onClick={() => getTempDataFromTask(router.taskId)}
                      startIcon={<BorderColorRoundedIcon />}
                    >
                      координаты
                    </Button>
                  </Stack>
                );
              })}
          </MoreInfo>
        )}
        <Grid
          item
          xs={12}
          mt={3}
        >
          <SMRBox>
            <div>УСПД: {uspdStatus ?? 'отсутсвует'}</div>
            {geoTransStation?.uspd ? (
              <Button>
                <Link
                  to={`/task/edit/${geoTransStation?.uspd.taskId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  target={'_blank'}
                >
                  {'Открыть заявку'}
                </Link>
              </Button>
            ) : (
              <Button onClick={createUspd}>{'Создать заявку на монтаж успд'}</Button>
            )}
          </SMRBox>
        </Grid>
      </Stack>
      <Stack
        direction='row-reverse'
        spacing={2}
      >
        <Button
          startIcon={<FileDownloadRoundedIcon />}
          // onClick={() => tmpTaskId && getReportEnergyservice(tmpTaskId)} /// пока это в разработке
          disabled
        >
          Отчет энергосервис
        </Button>
        <Button
          startIcon={<BorderColorRoundedIcon />}
          onClick={() => window.open(`/task/edit/${tmpTaskId}`)}
        >
          ППО тп
        </Button>
      </Stack>
    </BoxShadow>
  );
};

export default ResCard;
