import React, { FC } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { style, Wrapper } from './Alert.styled';

interface Props {
  handleClose: () => void;
  handleSuccess: () => void;
  open: boolean;
}

const AlertRemove: FC<Props> = ({ open, handleClose, handleSuccess }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
        >
          {'Вы действительно хотите удалить?'}
        </Typography>
        <Wrapper>
          <Button
            variant='contained'
            onClick={() => {
              handleSuccess();
            }}
          >
            OK
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            variant='contained'
            onClick={() => {
              handleClose();
            }}
          >
            Oтмена
          </Button>
        </Wrapper>
      </Box>
    </Modal>
  );
};

export default AlertRemove;
