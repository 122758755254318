import React, { Children, FC } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { IGroupButtons } from './types';

export const GroupButtons: FC<IGroupButtons> = (props) => {
  const { groupName, children } = props;
  const arrayChildren = Children.toArray(children);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={props.disabled}
        startIcon={props.startIcon}
        className={props.className}
      >
        {groupName}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      >
        {!props.disabled && arrayChildren.map((child, index) => <div key={index}>{child}</div>)}
      </Menu>
    </div>
  );
};
